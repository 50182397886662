import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import { Button, ConfirmModal, Table } from '@/components'
import Store from '../../store'
import { toast } from 'react-toastify'
import { IOfferTermDto, ICustomerOfferDutyPostDto, ICustomerOfferSolutionDto } from '../../../customer-offer-grid/types'
import { Combobox } from 'react-widgets/cjs'
import { IOfferDto } from '../../../conventional-offer/types'
import EditDutyPostPopup from './edit-duty-post-popup'
import { IOfferProfitPercentageDto } from './types'

interface IEditSolutionPopupProps {
  CustomerOfferStore?: Store
  toggleModal: any
  isReadOnly: boolean
}

const EditSolutionPopup: FC<IEditSolutionPopupProps> = inject('CustomerOfferStore')(
  observer((props: IEditSolutionPopupProps) => {
    const { CustomerOfferStore: store, toggleModal, isReadOnly } = props
    const { t } = useTranslation()

    const { customerOffer, conventionalOffers, egcOffers } = store!

    const [solution, setSolution] = useState<ICustomerOfferSolutionDto>()
    const [isEdit, setIsEdit] = useState(false)

    const [tmpConventionalOffer, setTmpConventionalOffer] = useState<IOfferDto>() // to prevent from pointer variable changes
    const [tmpEgcOffer, setTmpEgcOffer] = useState<IOfferDto>() // to prevent from pointer variable changes
    const [tmpDutyPostList, setTmpDutyPostList] = useState<ICustomerOfferDutyPostDto[]>([]) // to prevent from pointer variable changes
    const [tmpConventionalOfferTerms, setTmpConventionalOfferTerms] = useState<IOfferTermDto[]>()
    const [tmpTechnologyOfferTerms, setTmpTechnologyOfferTerms] = useState<IOfferTermDto[]>()
    const [conventionalOfferProfitPercentage, setConventionalOfferProfitPercentage] = useState<IOfferProfitPercentageDto>()
    const [technologyOfferProfitPercentage, setTechnologyOfferProfitPercentage] = useState<IOfferProfitPercentageDto>()

    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isEditDutyPostComplete, setIsEditDutyPostComplete] = useState(false)

    const [showIsRequestedTooltip, setShowIsRequestedTooltip] = useState(false)
    const [showIsThereChangeInOfferTermsTooltip, setShowIsThereChangeInOfferTermsTooltip] = useState(false)
    const [showIsTransportationCostIncludedTooltip, setShowIsTransportationCostIncludedTooltip] = useState(false)
    const [showIsFoodCostIncludedTooltip, setShowIsFoodCostIncludedTooltip] = useState(false)

    const [preventConventionalOfferTermsOverrideFlag, setPreventConventionalOfferTermsOverrideFlag] = useState(false)
    const [preventTechnologyOfferTermsOverrideFlag, setPreventTechnologyOfferTermsOverrideFlag] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      const selected = customerOffer?.solutionList?.find((x: any) => x.isSelected)

      if (selected) {
        setSolution({ ...selected })
        setTmpConventionalOffer(selected.conventionalOffer == undefined ? undefined : { ...(selected.conventionalOffer as IOfferDto) })
        setTmpEgcOffer(selected.egcOffer == undefined ? undefined : { ...(selected.egcOffer as IOfferDto) })
        setTmpDutyPostList([...((selected.dutyPostList || []) as ICustomerOfferDutyPostDto[])])
        setIsEdit(true)
      } else {
        setSolution({
          requested: false,
          isThereChangeInOfferTerms: true,
          isTransportationCostIncluded: false,
          isFoodCostIncluded: false,
        } as ICustomerOfferSolutionDto)
      }
    }, [])

    const didConventionalOfferGetSelected = (tmpConventionalOffer?.id || 0) > 0
    const didTechnologyOfferGetSelected = (tmpEgcOffer?.id || 0) > 0
    const shouldOfferTermsBeShown = customerOffer?.isItCandidateCustomer === true || solution?.isThereChangeInOfferTerms === true

    useEffect(() => {
      if (didConventionalOfferGetSelected) {
        setIsLoading(true)

        const selected = customerOffer?.solutionList?.find((x: any) => x.isSelected)

        if (!preventConventionalOfferTermsOverrideFlag && selected && (selected.conventionalOffer?.id || 0) > 0) {
          setPreventConventionalOfferTermsOverrideFlag(true)
          if ((selected.conventionalOfferTerms || []).length == 0) {
            store!
              .getConventionalOfferInitialTerms(tmpConventionalOffer!.id)
              .then((x) => setTmpConventionalOfferTerms([...(x || [])] as IOfferTermDto[]))
          } else {
            setTmpConventionalOfferTerms([...((selected.conventionalOfferTerms || []) as IOfferTermDto[])])
          }
        } else {
          if (!preventConventionalOfferTermsOverrideFlag) {
            setPreventConventionalOfferTermsOverrideFlag(true)
          }
          store!
            .getConventionalOfferInitialTerms(tmpConventionalOffer!.id)
            .then((x) => setTmpConventionalOfferTerms([...(x || [])] as IOfferTermDto[]))
        }

        store!.getConventionalOfferProfitPercentage(tmpConventionalOffer!.id).then((x) => {
          setConventionalOfferProfitPercentage(x)
          setIsLoading(false)
        })
      }
    }, [tmpConventionalOffer])

    useEffect(() => {
      if (didTechnologyOfferGetSelected) {
        setIsLoading(true)

        const selected = customerOffer?.solutionList?.find((x: any) => x.isSelected)

        if (!preventTechnologyOfferTermsOverrideFlag && selected && (selected.egcOffer?.id || 0) > 0) {
          setPreventTechnologyOfferTermsOverrideFlag(true)
          if ((selected.technologyOfferTerms || []).length == 0) {
            store!.getTechnologyOfferInitialTerms(tmpEgcOffer!.id).then((x) => setTmpTechnologyOfferTerms([...(x || [])] as IOfferTermDto[]))
          } else {
            setTmpTechnologyOfferTerms([...((selected.technologyOfferTerms || []) as IOfferTermDto[])])
          }
        } else {
          if (!preventTechnologyOfferTermsOverrideFlag) {
            setPreventTechnologyOfferTermsOverrideFlag(true)
          }
          store!.getTechnologyOfferInitialTerms(tmpEgcOffer!.id).then((x) => setTmpTechnologyOfferTerms([...(x || [])] as IOfferTermDto[]))
        }

        store!.getTechnologyOfferProfitPercentage(tmpEgcOffer!.id).then((x) => {
          setTechnologyOfferProfitPercentage(x)
          setIsLoading(false)
        })
      }
    }, [tmpEgcOffer])

    useEffect(() => {
      if (isEditDutyPostComplete) {
        const copyTmpDutyPostList = [...tmpDutyPostList]

        const index = copyTmpDutyPostList.findIndex((x: any) => x.isSelected === true)

        if (index != -1) {
          const copySelected = {
            ...copyTmpDutyPostList[index],
            isSelected: false,
          } as ICustomerOfferDutyPostDto

          copyTmpDutyPostList.splice(index, 1, copySelected)
          setTmpDutyPostList([...copyTmpDutyPostList])
        }

        setIsEditDutyPostComplete(false)
      }
    }, [isEditDutyPostComplete])

    const save = async () => {
      if (!solution || !solution.name) {
        toast.error(t('You must enter a solution name'))
        return
      }

      if (!didConventionalOfferGetSelected && !didTechnologyOfferGetSelected) {
        toast.error(t('You must choose an offer'))
        return
      }

      if (shouldOfferTermsBeShown) {
        if ((tmpConventionalOfferTerms || []).length > 0) {
          if (tmpConventionalOfferTerms!.some((x) => x.isNeededToBeEdited && !x.isEdited)) {
            toast.error(t('There are terms that need to be edited'))
            return
          }
        }

        if ((tmpTechnologyOfferTerms || []).length > 0) {
          if (tmpTechnologyOfferTerms!.some((x) => x.isNeededToBeEdited && !x.isEdited)) {
            toast.error(t('There are terms that need to be edited'))
            return
          }
        }
      }

      solution.conventionalOffer = { ...(tmpConventionalOffer as IOfferDto) }
      solution.conventionalOfferListName = solution.conventionalOffer?.listName

      solution.egcOffer = { ...(tmpEgcOffer as IOfferDto) }
      solution.egcOfferListName = solution.egcOffer?.listName

      solution.dutyPostList = [...tmpDutyPostList]

      if (shouldOfferTermsBeShown) {
        solution.conventionalOfferTerms = [...(tmpConventionalOfferTerms || [])]
        solution.technologyOfferTerms = [...(tmpTechnologyOfferTerms || [])]
      } else {
        solution.isThereChangeInOfferTerms = false
      }

      if (isEdit) {
        const selected = customerOffer!.solutionList?.find((x: any) => x.isSelected)

        if (selected) {
          selected.name = solution.name
          selected.requested = solution.requested
          selected.isThereChangeInOfferTerms = solution.isThereChangeInOfferTerms
          selected.isTransportationCostIncluded = solution.isTransportationCostIncluded
          selected.isFoodCostIncluded = solution.isFoodCostIncluded
          selected.conventionalOffer = solution.conventionalOffer
          selected.conventionalOfferListName = solution.conventionalOfferListName
          selected.egcOffer = solution.egcOffer
          selected.egcOfferListName = solution.egcOfferListName
          selected.dutyPostList = solution.dutyPostList
          if (shouldOfferTermsBeShown) {
            selected.conventionalOfferTerms = solution.conventionalOfferTerms
            selected.technologyOfferTerms = solution.technologyOfferTerms
          }

          customerOffer!.solutionList = [...customerOffer!.solutionList!] // to refresh grid
        }
      } else {
        let newId = 1

        if (customerOffer!.solutionList!.length > 0) {
          const maxId = Math.max(...customerOffer!.solutionList!.map((x: any) => x.id))
          newId = maxId + 1
        }

        solution.id = newId

        customerOffer!.solutionList = [...customerOffer!.solutionList!, solution]
      }

      toggleModal()
    }

    const handleSeeOfferButtonClick = (isEgc: boolean) => {
      if (isEgc) {
        if (!didTechnologyOfferGetSelected) {
          toast.error(t('You must choose a technology offer'))
          return
        }
        var win = window.open(window.location.origin + `/digital-offer/technology-offer/${tmpEgcOffer!.id}`, '_blank')
        if (win != null) {
          win.focus()
        }
      } else {
        if (!didConventionalOfferGetSelected) {
          toast.error(t('You must choose a manned services offer'))
          return
        }
        var win = window.open(window.location.origin + `/digital-offer/conventional-offer/${tmpConventionalOffer!.id}`, '_blank')
        if (win != null) {
          win.focus()
        }
      }
    }

    const handleNewOfferButtonClick = (isEgc: boolean) => {
      if (isEgc) {
        var win = window.open(window.location.origin + `/digital-offer/technology-offer`, '_blank')
        if (win != null) {
          win.focus()
        }
      } else {
        var win = window.open(window.location.origin + `/digital-offer/conventional-offer`, '_blank')
        if (win != null) {
          win.focus()
        }
      }
    }

    const handleRefreshOffersButtonClick = (isEgc: boolean) => {
      setIsLoading(true)
      if (isEgc) {
        store!.getEgcOffersAsList().then(() => setIsLoading(false))
      } else {
        store!.getConventionalOffersAsList().then(() => setIsLoading(false))
      }
    }

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                {!isReadOnly && (
                  <Button
                    icon={<i className="fas fa-pen"></i>}
                    tooltipText={t('Edit')}
                    onClick={async () => {
                      const copyTmpDutyPostList = [...tmpDutyPostList]

                      const index = copyTmpDutyPostList.findIndex((x: any) => x.id == row.id)

                      if (index != -1) {
                        const copySelected = {
                          ...copyTmpDutyPostList[index],
                          isSelected: true,
                        } as ICustomerOfferDutyPostDto

                        copyTmpDutyPostList.splice(index, 1, copySelected)
                        setTmpDutyPostList([...copyTmpDutyPostList])
                        setShowEditModal(true)
                      }
                    }}
                    className="btn btn-link text-warning"
                    disabled={isLoading}
                  />
                )}
                {isReadOnly && (
                  <Button
                    icon={<i className="fas fa-eye"></i>}
                    tooltipText={t('Details')}
                    onClick={async () => {
                      const copyTmpDutyPostList = [...tmpDutyPostList]

                      const index = copyTmpDutyPostList.findIndex((x: any) => x.id == row.id)

                      if (index != -1) {
                        const copySelected = {
                          ...copyTmpDutyPostList[index],
                          isSelected: true,
                        } as ICustomerOfferDutyPostDto

                        copyTmpDutyPostList.splice(index, 1, copySelected)
                        setTmpDutyPostList([...copyTmpDutyPostList])
                        setShowEditModal(true)
                      }
                    }}
                    className="btn btn-link"
                    disabled={isLoading}
                  />
                )}
                <Button
                  icon={<i className="fas fa-trash"></i>}
                  tooltipText={t('Delete')}
                  onClick={async () => {
                    const copyTmpDutyPostList = [...tmpDutyPostList]

                    const index = copyTmpDutyPostList.findIndex((x: any) => x.id == row.id)

                    if (index != -1) {
                      const copySelected = {
                        ...copyTmpDutyPostList[index],
                        isSelected: true,
                      } as ICustomerOfferDutyPostDto

                      copyTmpDutyPostList.splice(index, 1, copySelected)
                      setTmpDutyPostList([...copyTmpDutyPostList])
                      setShowDeleteModal(true)
                    }
                  }}
                  className="btn btn-link text-danger"
                  disabled={isReadOnly || isLoading}
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Employee Count'),
          id: 'employeeCount',
          accessor: 'employeeCount',
          Cell: ({ row }: any) => {
            const { employeeCount } = row.values

            return <span dangerouslySetInnerHTML={{ __html: employeeCount }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t, tmpDutyPostList]
    )

    const deletionMessage = () => {
      const selected = tmpDutyPostList.find((x: any) => x.isSelected === true)

      if (selected) {
        return (
          <>
            <p style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
              {t('Name')}: {selected.name}
            </p>
            <p style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
              {t('Employee Count')}: {selected.employeeCount}
            </p>
          </>
        )
      }

      return <p>{t('Error')}</p>
    }

    const OfferTermsComp = (props: any) => {
      const isThisTechnologyOffer = props.isThisTechnologyOffer as boolean

      const tmpTermsArr = [...(props.offerTerms || [])] as IOfferTermDto[]

      const termsSubDivOnMouseEnter = (event: any) => {
        event.currentTarget.style.backgroundColor = '#f5f5f5'
        const buttonsDiv = event.currentTarget.querySelector('.buttonsDiv')
        buttonsDiv.style.visibility = 'visible'
      }

      const termsSubDivOnMouseLeave = (event: any) => {
        event.currentTarget.style.backgroundColor = null
        const buttonsDiv = event.currentTarget.querySelector('.buttonsDiv')
        buttonsDiv.style.visibility = 'hidden'
      }

      const handleDelete = (event: any, selected: IOfferTermDto) => {
        const termIdx = tmpTermsArr.findIndex((y) => y.id == selected.id)
        if (termIdx != -1) {
          tmpTermsArr.splice(termIdx, 1)
          if (isThisTechnologyOffer) {
            setTmpTechnologyOfferTerms(tmpTermsArr)
          } else {
            setTmpConventionalOfferTerms(tmpTermsArr)
          }
        }
      }

      const handleMakeTitle = (event: any, selected: IOfferTermDto) => {
        const termIdx = tmpTermsArr.findIndex((y) => y.id == selected.id)
        if (termIdx != -1) {
          tmpTermsArr.splice(termIdx, 1, { ...selected, title: !selected.title } as IOfferTermDto)
          if (isThisTechnologyOffer) {
            setTmpTechnologyOfferTerms(tmpTermsArr)
          } else {
            setTmpConventionalOfferTerms(tmpTermsArr)
          }
        }
      }

      const handleAdd = (event: any, selected: IOfferTermDto) => {
        const termIdx = tmpTermsArr.findIndex((y) => y.id == selected.id)

        if (termIdx != -1) {
          let newId = 1,
            newOrderNo = 1
          if (tmpTermsArr.length > 0) {
            const maxId = Math.max(...tmpTermsArr.map((x) => x.id))
            newId = maxId + 1
            newOrderNo = selected.orderNo + 1
            tmpTermsArr.forEach((x) => {
              if (x.orderNo > selected.orderNo) {
                x.orderNo = x.orderNo + 1
              }
            })
          }
          tmpTermsArr.splice(termIdx + 1, 0, {
            id: newId,
            text: '',
            title: false,
            orderNo: newOrderNo,
            isEditable: true,
            isEdited: false,
            isNeededToBeEdited: false,
          } as IOfferTermDto)
          if (isThisTechnologyOffer) {
            setTmpTechnologyOfferTerms(tmpTermsArr)
          } else {
            setTmpConventionalOfferTerms(tmpTermsArr)
          }
        }
      }

      const handleSelect = (event: any, selected: IOfferTermDto) => {
        const termIdx = tmpTermsArr.findIndex((y) => y.id == selected.id)
        if (termIdx != -1) {
          tmpTermsArr.splice(termIdx, 1, { ...selected, isSelected: !selected.isSelected } as IOfferTermDto)
          if (isThisTechnologyOffer) {
            setTmpTechnologyOfferTerms(tmpTermsArr)
          } else {
            setTmpConventionalOfferTerms(tmpTermsArr)
          }
        }
      }

      const areThereTermsThatNeedToBeEdited = tmpTermsArr.some((x) => x.isNeededToBeEdited && !x.isEdited)

      return (
        <>
          {areThereTermsThatNeedToBeEdited && (
            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4 font-weight-bold" style={{ color: 'red' }}>
                  {t('There are terms that need to be edited')}
                </div>
              </div>
            </div>
          )}
          {tmpTermsArr.map((x) => (
            <div key={x.id} className="row" style={{ marginBottom: '10px' }}>
              <div
                id={'termsSubDiv_' + x.id}
                className="col-md-12"
                style={{ display: 'flex' }}
                onMouseEnter={termsSubDivOnMouseEnter}
                onMouseLeave={termsSubDivOnMouseLeave}
              >
                <div className="col-md-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {!(isReadOnly || !x.isEditable) && (
                    <button title={t('Select')} className="btn btn-primary" onClick={(event) => handleSelect(event, x)}>
                      <i className={'fas fa' + (x.isSelected === true ? '-check' : '') + '-square'}></i>
                    </button>
                  )}
                </div>
                <div className="col-md-9" style={{ margin: 'auto' }}>
                  <textarea
                    className={'form-control' + (x.title ? ' font-weight-bold' : '')}
                    style={{ height: '2.35em', backgroundColor: x.isNeededToBeEdited && !x.isEdited ? 'yellow' : undefined }}
                    defaultValue={x.text}
                    onBlur={(event) => {
                      const termIdx = tmpTermsArr.findIndex((y) => y.id == x.id)
                      if (termIdx != -1) {
                        if (x.text != event.target.value) {
                          tmpTermsArr.splice(termIdx, 1, {
                            id: x.id,
                            text: event.target.value,
                            title: x.title,
                            orderNo: x.orderNo,
                            isEditable: x.isEditable,
                            isEdited: true,
                            isNeededToBeEdited: x.isNeededToBeEdited,
                          } as IOfferTermDto)
                          if (isThisTechnologyOffer) {
                            setTmpTechnologyOfferTerms(tmpTermsArr)
                          } else {
                            setTmpConventionalOfferTerms(tmpTermsArr)
                          }
                        }
                      }
                    }}
                    disabled={isReadOnly || !x.isEditable || isLoading}
                  />
                </div>
                <div
                  className="col-md-2 buttonsDiv"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', visibility: 'hidden' }}
                >
                  {!(isReadOnly || (!x.isEditable && x.text != 'Diğer Şartlar') || isLoading) && (
                    <button title={t('Add')} className="btn btn-success" onClick={(event) => handleAdd(event, x)}>
                      <i className="fas fa-plus"></i>
                    </button>
                  )}
                  {!(isReadOnly || !x.isEditable || isLoading) && <div style={{ width: '10px' }}></div>}
                  {!(isReadOnly || !x.isEditable || isLoading) && (
                    <button title={t('Make This Term A Title')} className="btn btn-primary" onClick={(event) => handleMakeTitle(event, x)}>
                      <i className="fas fa-bold"></i>
                    </button>
                  )}
                  {!(isReadOnly || !x.isEditable || isLoading) && <div style={{ width: '10px' }}></div>}
                  {!(isReadOnly || !x.isEditable || isLoading) && (
                    <button title={t('Delete')} className="btn btn-danger" onClick={(event) => handleDelete(event, x)}>
                      <i className="fas fa-trash"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )
    }

    const totalProfitPercentage =
      !conventionalOfferProfitPercentage ||
      conventionalOfferProfitPercentage.offerPrice == undefined ||
      conventionalOfferProfitPercentage.profitPercentage == undefined ||
      !technologyOfferProfitPercentage ||
      technologyOfferProfitPercentage.offerPrice == undefined ||
      technologyOfferProfitPercentage.profitPercentage == undefined
        ? '-'
        : (
            ((conventionalOfferProfitPercentage.offerPrice * conventionalOfferProfitPercentage.profitPercentage +
              technologyOfferProfitPercentage.offerPrice * technologyOfferProfitPercentage.profitPercentage) /
              (conventionalOfferProfitPercentage.offerPrice + technologyOfferProfitPercentage.offerPrice)) *
            100
          ).toFixed(2)

    return (
      <>
        <Modal isOpen={true} toggle={toggleModal} backdrop={'static'} keyboard={false} className="modal-xxl">
          <ModalHeader toggle={toggleModal}>{t(isEdit ? 'Edit Solution' : 'Add Solution')}</ModalHeader>
          <ModalBody>
            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Solution Name')}
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control py-4"
                    value={solution?.name}
                    onChange={(event) => {
                      setSolution({
                        ...(solution || {}),
                        name: event.target.value,
                      } as ICustomerOfferSolutionDto)
                    }}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Is Requested?')} <i id="idIsRequested" className="fas fa-circle-info mr-2"></i>
                  <Tooltip
                    placement={'top-start'}
                    isOpen={showIsRequestedTooltip}
                    target={'idIsRequested'}
                    toggle={() => setShowIsRequestedTooltip(!showIsRequestedTooltip)}
                  >
                    {t('Edit Solution Is Requested Tooltip')}
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <input
                    style={{ width: '15px', height: '15px' }}
                    type="checkbox"
                    defaultChecked={solution?.requested || false}
                    id="checkboxIsRequested"
                    onChange={(event) => {
                      setSolution({
                        ...(solution || {}),
                        requested: event.target.checked,
                      } as ICustomerOfferSolutionDto)
                    }}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Is Transportation Cost Included?')} <i id="idIsTransportationCostIncluded" className="fas fa-circle-info mr-2"></i>
                  <Tooltip
                    placement={'top-start'}
                    isOpen={showIsTransportationCostIncludedTooltip}
                    target={'idIsTransportationCostIncluded'}
                    toggle={() => setShowIsTransportationCostIncludedTooltip(!showIsTransportationCostIncludedTooltip)}
                  >
                    {t('Edit Solution Is Transportation Cost Included Tooltip')}
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <input
                    style={{ width: '15px', height: '15px' }}
                    type="checkbox"
                    defaultChecked={solution?.isTransportationCostIncluded || false}
                    id="checkboxIsTransportationCostIncluded"
                    onChange={(event) => {
                      setSolution({
                        ...(solution || {}),
                        isTransportationCostIncluded: event.target.checked,
                      } as ICustomerOfferSolutionDto)
                    }}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Is Food Cost Included?')} <i id="idIsFoodCostIncluded" className="fas fa-circle-info mr-2"></i>
                  <Tooltip
                    placement={'top-start'}
                    isOpen={showIsFoodCostIncludedTooltip}
                    target={'idIsFoodCostIncluded'}
                    toggle={() => setShowIsFoodCostIncludedTooltip(!showIsFoodCostIncludedTooltip)}
                  >
                    {t('Edit Solution Is Food Cost Included Tooltip')}
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <input
                    style={{ width: '15px', height: '15px' }}
                    type="checkbox"
                    defaultChecked={solution?.isFoodCostIncluded || false}
                    id="checkboxIsFoodCostIncluded"
                    onChange={(event) => {
                      setSolution({
                        ...(solution || {}),
                        isFoodCostIncluded: event.target.checked,
                      } as ICustomerOfferSolutionDto)
                    }}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Manned Services Offer')}
                </div>
                <div className="col-md-6">
                  <Combobox
                    id="comboboxMannedServicesOffer"
                    filter="contains"
                    data={conventionalOffers}
                    value={tmpConventionalOffer?.listName}
                    textField="listName"
                    onSelect={(val: any) => {
                      setTmpConventionalOffer({ ...val })
                    }}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
                <div className="col-md-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    title={t('Add New Manned Services Offer')}
                    className="btn btn-success"
                    onClick={() => handleNewOfferButtonClick(false)}
                    disabled={isLoading}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                  <button
                    title={t('Refresh The Manned Services Offers')}
                    className="btn btn-primary"
                    style={{ marginLeft: '10px' }}
                    onClick={() => handleRefreshOffersButtonClick(false)}
                    disabled={isLoading}
                  >
                    <i className="fas fa-check"></i>
                  </button>
                  <button
                    title={t('See the offer')}
                    className="btn btn-success"
                    style={{ marginLeft: '10px' }}
                    onClick={() => handleSeeOfferButtonClick(false)}
                    disabled={!didConventionalOfferGetSelected || isLoading}
                  >
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Technology Offer')}
                </div>
                <div className="col-md-6">
                  <Combobox
                    id="comboboxTechnologyOffer"
                    filter="contains"
                    data={egcOffers}
                    value={tmpEgcOffer?.listName}
                    textField="listName"
                    onSelect={(val: any) => {
                      setTmpEgcOffer({ ...val })
                    }}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
                <div className="col-md-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    title={t('Add New Technology Offer')}
                    className="btn btn-success"
                    onClick={() => handleNewOfferButtonClick(true)}
                    disabled={isLoading}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                  <button
                    title={t('Refresh The Technology Offers')}
                    className="btn btn-primary"
                    style={{ marginLeft: '10px' }}
                    onClick={() => handleRefreshOffersButtonClick(true)}
                    disabled={isLoading}
                  >
                    <i className="fas fa-check"></i>
                  </button>
                  <button
                    title={t('See the offer')}
                    className="btn btn-success"
                    style={{ marginLeft: '10px' }}
                    onClick={() => handleSeeOfferButtonClick(true)}
                    disabled={!didTechnologyOfferGetSelected || isLoading}
                  >
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div> */}

            {(didConventionalOfferGetSelected || didTechnologyOfferGetSelected) && (
              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('Profit Percentage')}
                  </div>
                  <div className="col-md-8">
                    {didConventionalOfferGetSelected && (
                      <span style={{ fontWeight: 'bold' }}>
                        {t('Manned Services Offer')}: %
                        {!conventionalOfferProfitPercentage || conventionalOfferProfitPercentage.profitPercentage == undefined
                          ? '-'
                          : (conventionalOfferProfitPercentage.profitPercentage * 100).toFixed(2)}
                      </span>
                    )}
                    {didTechnologyOfferGetSelected && (
                      <span style={{ fontWeight: 'bold' }}>
                        {didConventionalOfferGetSelected ? ', ' : ''}
                        {t('Technology Offer')}: %
                        {!technologyOfferProfitPercentage || technologyOfferProfitPercentage.profitPercentage == undefined
                          ? '-'
                          : (technologyOfferProfitPercentage.profitPercentage * 100).toFixed(2)}
                      </span>
                    )}
                    {didConventionalOfferGetSelected && didTechnologyOfferGetSelected && (
                      <span style={{ fontWeight: 'bold' }}>
                        , {t('Total')}: %{totalProfitPercentage}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* <hr /> */}

            {!customerOffer?.isItCandidateCustomer && (didConventionalOfferGetSelected || didTechnologyOfferGetSelected) && (
              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('Is There A Change In The Offer Terms?')} <i id="idIsThereChangeInOfferTerms" className="fas fa-circle-info mr-2"></i>
                    <Tooltip
                      placement={'top-start'}
                      isOpen={showIsThereChangeInOfferTermsTooltip}
                      target={'idIsThereChangeInOfferTerms'}
                      toggle={() => setShowIsThereChangeInOfferTermsTooltip(!showIsThereChangeInOfferTermsTooltip)}
                    >
                      {t('If there is a change in the offer terms, check this box. Then edit the offer terms below.')}
                    </Tooltip>
                  </div>
                  <div className="col-md-8">
                    <input
                      style={{ width: '15px', height: '15px' }}
                      type="checkbox"
                      defaultChecked={solution?.isThereChangeInOfferTerms || false}
                      id="checkboxIsThereChangeInOfferTerms"
                      onChange={(event) => {
                        setSolution({
                          ...(solution || {}),
                          isThereChangeInOfferTerms: event.target.checked,
                        } as ICustomerOfferSolutionDto)
                      }}
                      disabled={isReadOnly || isLoading}
                    />
                  </div>
                </div>
              </div>
            )}

            {shouldOfferTermsBeShown && didConventionalOfferGetSelected && (
              <div className="card-header" style={{ borderBottom: 0 }}>
                <div className="font-weight-bold" style={{ fontSize: '16px' }}>
                  {t('Manned Services Offer Terms')}
                </div>
                {(!tmpConventionalOfferTerms || tmpConventionalOfferTerms.length == 0 || !tmpConventionalOfferTerms[0].isEditable) && (
                  <div style={{ marginLeft: '10px' }}>
                    <Button
                      className="btn btn-success btn-shadow flex-direction"
                      icon={<i className="fas fa-plus"></i>}
                      onClick={async (e) => {
                        e.preventDefault()
                        const tmpArr = [...(tmpConventionalOfferTerms || [])]
                        tmpArr.unshift({
                          id: 1,
                          text: '',
                          title: false,
                          orderNo: 1,
                          isEditable: true,
                          isEdited: false,
                          isNeededToBeEdited: false,
                        } as IOfferTermDto)
                        setTmpConventionalOfferTerms(tmpArr)
                      }}
                      disabled={isReadOnly || isLoading}
                    />
                  </div>
                )}
                <div style={{ marginLeft: '10px' }}>
                  <Button
                    className="btn btn-danger btn-shadow flex-direction"
                    icon={<i className="fas fa-trash mr-2"></i>}
                    onClick={async (e) => {
                      e.preventDefault()
                      if (!tmpConventionalOfferTerms || tmpConventionalOfferTerms.filter((x) => x.isSelected === true).length == 0) {
                        toast.warning(t("You didn't select any terms"))
                        return
                      }

                      setTmpConventionalOfferTerms(tmpConventionalOfferTerms.filter((x) => !x.isSelected))
                    }}
                    text={t('Delete Selected')}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
              </div>
            )}

            {shouldOfferTermsBeShown && didConventionalOfferGetSelected && (
              <OfferTermsComp isThisTechnologyOffer={false} offerTerms={tmpConventionalOfferTerms} />
            )}

            {shouldOfferTermsBeShown && didConventionalOfferGetSelected && <hr />}

            {shouldOfferTermsBeShown && didTechnologyOfferGetSelected && (
              <div className="card-header" style={{ borderBottom: 0 }}>
                <div className="font-weight-bold" style={{ fontSize: '16px' }}>
                  {t('Technology Offer Terms')}
                </div>
                {(!tmpTechnologyOfferTerms || tmpTechnologyOfferTerms.length == 0 || !tmpTechnologyOfferTerms[0].isEditable) && (
                  <div style={{ marginLeft: '10px' }}>
                    <Button
                      className="btn btn-success btn-shadow flex-direction"
                      icon={<i className="fas fa-plus"></i>}
                      onClick={async (e) => {
                        e.preventDefault()
                        const tmpArr = [...(tmpTechnologyOfferTerms || [])]
                        tmpArr.unshift({
                          id: 1,
                          text: '',
                          title: false,
                          orderNo: 1,
                          isEditable: true,
                          isEdited: false,
                          isNeededToBeEdited: false,
                        } as IOfferTermDto)
                        setTmpTechnologyOfferTerms(tmpArr)
                      }}
                      disabled={isReadOnly || isLoading}
                    />
                  </div>
                )}
                <div style={{ marginLeft: '10px' }}>
                  <Button
                    className="btn btn-danger btn-shadow flex-direction"
                    icon={<i className="fas fa-trash mr-2"></i>}
                    onClick={async (e) => {
                      e.preventDefault()
                      if (!tmpTechnologyOfferTerms || tmpTechnologyOfferTerms.filter((x) => x.isSelected === true).length == 0) {
                        toast.warning(t("You didn't select any terms"))
                        return
                      }

                      setTmpTechnologyOfferTerms(tmpTechnologyOfferTerms.filter((x) => !x.isSelected))
                    }}
                    text={t('Delete Selected')}
                    disabled={isReadOnly || isLoading}
                  />
                </div>
              </div>
            )}

            {shouldOfferTermsBeShown && didTechnologyOfferGetSelected && (
              <OfferTermsComp isThisTechnologyOffer={true} offerTerms={tmpTechnologyOfferTerms} />
            )}

            {shouldOfferTermsBeShown && didTechnologyOfferGetSelected && <hr />}

            {/* <div className="card-header" style={{ borderBottom: 0 }}>
              <div className="btn-actions-pane-right actions-icon-btn">
                <button
                  className="btn btn-primary btn-shadow flex-direction float-right"
                  style={{ marginTop: '-5px' }}
                  onClick={async (e) => {
                    e.preventDefault()
                    setShowEditModal(true)
                  }}
                  disabled={isReadOnly || isLoading}
                >
                  <i className="fas fa-plus mr-2"></i>
                  {t('New Duty Post')}
                </button>
              </div>
            </div>
            <div className="card-body">
              <Table
                smallSize={true}
                showHeaderSearchInput={false}
                columns={columns}
                totalCount={tmpDutyPostList.length}
                data={tmpDutyPostList}
                fetchData={() => {}}
                isLoading={false}
                showNavigation={false}
              />
            </div> */}
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

            <Button
              className="btn btn-success float-right"
              onClick={save}
              icon={<i className={'far fa-save mr-2'}></i>}
              text={t('Save')}
              showConfirm={false}
              disabled={isReadOnly || isLoading}
            />
          </ModalFooter>
        </Modal>

        {showEditModal && (
          <EditDutyPostPopup
            toggleModal={() => {
              if (showEditModal) {
                setIsEditDutyPostComplete(true)
              }

              setShowEditModal(!showEditModal)
            }}
            tmpDutyPostList={tmpDutyPostList}
            setTmpDutyPostList={setTmpDutyPostList}
            isReadOnly={isReadOnly}
          />
        )}

        {showDeleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={deletionMessage()}
            title={t('Warning') + '!'}
            isShow={showDeleteModal}
            toggleModal={() => {
              if (showDeleteModal) {
                const copyTmpDutyPostList = [...tmpDutyPostList]

                const index = copyTmpDutyPostList.findIndex((x: any) => x.isSelected === true)

                if (index != -1) {
                  const copySelected = {
                    ...copyTmpDutyPostList[index],
                    isSelected: false,
                  } as ICustomerOfferDutyPostDto

                  copyTmpDutyPostList.splice(index, 1, copySelected)
                  setTmpDutyPostList([...copyTmpDutyPostList])
                }
              }

              setShowDeleteModal(!showDeleteModal)
            }}
            onConfirm={async () => {
              const copyTmpDutyPostList = [...tmpDutyPostList]

              const index = copyTmpDutyPostList.findIndex((x: any) => x.isSelected === true)

              if (index != -1) {
                copyTmpDutyPostList.splice(index, 1)
                setTmpDutyPostList([...copyTmpDutyPostList])
                setShowDeleteModal(false)
              }
            }}
          />
        )}
      </>
    )
  })
)

export default EditSolutionPopup
