import React, { useState, FC } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Button, Input } from '../../../../components';
import { useForm } from 'react-hook-form';
import Store from './store'
import { AuthDto } from './types';

interface ITwoStepAuthView {
    UserStore?: Store;
    isShow: boolean;
    toggleModal: any;
    redirect: any;
    redirectPermissions: any;
}

const Authorization: FC<ITwoStepAuthView> = inject('LoginStore')(
    observer((props: ITwoStepAuthView) => {

        const { UserStore: store, isShow, toggleModal, redirect, redirectPermissions } = props;
        const [code, setCode] = useState('');
        const { t } = useTranslation();
        const { handleSubmit } = useForm();

        const onConfirmClick = async (data: any) => {
            if (code) {
                const myCode = {
                    code: code
                } as AuthDto
                await store
                    ?.authentication(myCode)
                    .then(() => {
                        toggleModal()
                        redirect(redirectPermissions)
                    })
            }
        };

        const onCancelClick = () => {
            toggleModal();
        };

        return (
            <Modal isOpen={isShow} toggle={toggleModal}>
                <ModalHeader>{t('twoStepAuthorization')}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="form-group w-100">
                            <label htmlFor="code" className="">
                                {t('Code')}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                id="code"
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-light mr-auto" onClick={onCancelClick} icon={<i className="fas fa-times mr-2"></i>} text={t('Cancel')} />

                    <Button
                        className="btn btn-success float-right"
                        onClick={handleSubmit(async (data: any) => await onConfirmClick(data))}
                        icon={<i className="far fa-check mr-2"></i>}
                        text={t('Confirm')}
                        showConfirm={true}
                    />
                </ModalFooter>
            </Modal>
        );
    })
);

export default Authorization;
