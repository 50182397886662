import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'

import env from '../../../../../config/env'
import http from '../../../../../services/http'
import { passwords } from './types'
import IPagedResult from '@/models/dto/fetch/IPagedResult'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'UserStore'
  filter!: number
  records!: IPagedResult<passwords>

  get api() {
    http.defaults.baseURL = API_BASE_URL

    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.records = {
      items: [],
      isLoaded: false,
      totalCount: 0,
    }

    // this.getRecords = this.getRecords.bind(this)
  }

  @action.bound async changePassword(dto: passwords) {
    const result = await this.api.put(`/user/change-password`, dto)
  }
}

export default Store
