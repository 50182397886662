import React, { FC, useState, InputHTMLAttributes, ChangeEvent, ChangeEventHandler, KeyboardEvent, ClipboardEvent } from 'react'

interface ISearchInput extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const SearchInput: FC<ISearchInput> = (props: ISearchInput) => {
  const { onChange, ...rest } = props
  const [value, setValue] = useState<string>('')
  const [changeEvent, setChangeEvent] = useState<ChangeEvent<HTMLInputElement>>()

  let clockTimeout: any = 0

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist()

    if (clockTimeout !== 0) clearTimeout(clockTimeout)

    setValue(event.target.value)
    setChangeEvent(event)

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {}, 1000)
  }

  const keyboardHandler = (event: KeyboardEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>) => {
    if (clockTimeout !== 0) clearTimeout(clockTimeout)

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {
      changeEvent!.target.value = changeEvent!.target.value.trim()
      onChange && onChange(changeEvent!)
    }, 1000)
  }

  return (
    <input
      {...rest}
      type="text"
      value={value || ''}
      onChange={changeHandler}
      onKeyUp={keyboardHandler}
      onKeyPress={keyboardHandler}
      onPaste={keyboardHandler}
    />
  )
}

export default SearchInput
