import React, { FC, Fragment, useState, useEffect, useCallback, useMemo, MouseEvent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTable, useSortBy, useExpanded, usePagination, useFilters, useGlobalFilter } from 'react-table'
import { Table as Bs4Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap'
import cx from 'classnames'
import * as _ from 'lodash'
import Input from '../Input'
import Filter, { filterTypes } from './filter'
import IPagedRequest from '../../models/dto/fetch/IPagedRequest'

export interface ITableColumn {
  Header?: any
  id?: any
  accessor?: any
  canSearch?: boolean
  Cell?: any
  className?: any
  title?: any
  showState?: any
}
interface ITableProps {
  columns: ITableColumn[]
  totalCount: number
  data: any[]
  fetchData: undefined | ((input: IPagedRequest | any) => any)
  filterable?: boolean
  isLoading: boolean
  showNavigation?: boolean
  onExport?: any
  rowSelectedIndex?: number | null
  onRowSelected?: (row: any) => void
  changeRowColor?: boolean
  backgroundChange?: { column: number; status: any } // object has column and status , column is control column, status is control type
  minWidth?: boolean
  customHeaderClick?: any
  grayBackground?: boolean
  smallSize?: boolean
  horizontalScroll?: boolean
  smallHeight?: boolean
  rowFilter?: boolean
  showHeaderSearchInput?: boolean
  showShortableIcon?: boolean
  getRowClass?: (row: any) => string
  onDoubleClick?: (row: any) => void
  getCellClass?: (cell: any) => string
  isOpenRowInMobile?: boolean
}

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

const Table: FC<ITableProps> = (props: ITableProps) => {
  let {
    columns,
    totalCount,
    data,
    fetchData,
    isLoading = false,
    showNavigation = true,
    onExport,
    filterable = true,
    rowSelectedIndex = null,
    onRowSelected = (row: any) => {},
    backgroundChange = { column: 0, status: null },
    changeRowColor = false,
    minWidth = false,
    customHeaderClick = false,
    grayBackground = false,
    smallSize = false,
    showHeaderSearchInput = true,
    showShortableIcon = false,
    smallHeight = false,
    getRowClass = (row: any) => {},
    getCellClass,
    onDoubleClick = (row: any) => {},
    isOpenRowInMobile = false,
  } = props
  const { t } = useTranslation()

  let [selectedRowIndex, setSelectedRowIndex] = useState(rowSelectedIndex)

  let column = backgroundChange!.column
  let status = backgroundChange!.status

  //  if default active unitStructureQuestionHeader exists
  useEffect(() => {
    setSelectedRowIndex(rowSelectedIndex)
  }, [rowSelectedIndex])

  const [params, setParams] = useState<any>()
  const [searchText, setSearchText] = useState('')
  const [individualColumns, setIndividualColumns] = useState<
    {
      name: string
      search: string
    }[]
  >([])

  const defaultPageSize = 5

  columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }: any) => {
          return (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            isOpenRowInMobile ? (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <i className="fas fa-chevron-right"></i> : <i className="fas fa-chevron-down"></i>}
              </span>
            ) : (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
              </span>
            )
          )
        },
        className: 'd-md-none',
      },
      ...columns,
    ],
    [columns, isOpenRowInMobile]
  )
  /*
  columns = useMemo(
    () =>
      _.map(columns, (column) => {
        column.id = column.id ?? `col-${uuid.v4()}`
        return column
      }),
    [columns]
  )
  */

  data = useMemo(() => data, [data])
  totalCount = useMemo(() => totalCount, [totalCount])

  // @ts-disable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    setPageSize,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultCanSort: false,
      defaultCanFilter: false,
      initialState: { pageIndex: 0, pageSize: defaultPageSize }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination,
      pageCount: Math.ceil(totalCount / defaultPageSize),
      filterTypes: useMemo(filterTypes, []),
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!

    useSortBy,
    useExpanded,
    usePagination
  )

  const pageCount = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize])
  const loadData = () => {
    let queryParams: IPagedRequest = {
      skipCount: pageIndex * pageSize,
      maxResultCount: pageSize,
    }

    if (sortBy.length > 0) {
      const field = sortBy[0].id
      const type = sortBy[0].desc ? 'desc' : 'asc'

      queryParams = { ...queryParams, orderBy: field, orderType: type }
    }

    if (searchText.length > 0) queryParams = { ...queryParams, searchText }

    if (individualColumns.length > 0) queryParams = { ...queryParams, columns: individualColumns }

    setParams(queryParams)
    if (fetchData) fetchData(queryParams)
  }

  useEffect(() => {
    if (sortBy.length > 0 && pageIndex > 0) return gotoPage(0)
    else if (sortBy.length > 0 && pageIndex === 0) return loadData()
  }, [sortBy])

  useEffect(() => {
    loadData()
  }, [pageSize, pageIndex, searchText, individualColumns])

  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <>
        {_.map(row.cells, ({ column }, i: number) => {
          return (
            column.className &&
            (_.includes(_.split(column.className, ' '), 'd-none') || column.className === 'd-none') && (
              <div className="row py-2" key={i}>
                <div className="col-4">
                  <strong>{column.Header}</strong>
                </div>
                <div className="col-8">{column.Cell({ row })}</div>
              </div>
            )
          )
        })}
      </>
    )

    // row = Object.assign({}, row.original)
  }, [])

  const pages = useMemo(() => {
    if (!pageCount) return [1]

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = 3
    const totalBlocks = totalNumbers + 2

    if (pageCount > totalBlocks) {
      const startPage = Math.max(2, pageIndex)
      const endPage = Math.min(pageCount - 1, pageIndex + 2)

      let pages: any = _.range(startPage, endPage + 1)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = pageCount - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = _.range(startPage - 1 - spillOffset, startPage)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = _.range(endPage + 1, endPage + spillOffset + 1)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, pageCount]
    }

    return _.range(1, pageCount + 1)
  }, [pageCount, pageIndex])

  const onSelectRow = (row: any, i: number) => {
    setSelectedRowIndex(i)
    onRowSelected(row)
  }
  const onDoubleClickRow = (row: any, i: number) => {
    setSelectedRowIndex(i)
    onDoubleClick(row)
  }
  //  scale table close column by onClick header
  const closeColumn = (access: any) => {
    customHeaderClick(access)
  }

  return (
    <>
      {filterable && (
        <Filter
          columns={headerGroups[0].headers}
          onChangeSearchInput={
            showHeaderSearchInput
              ? (event) => {
                  setSearchText(event.target.value)
                }
              : undefined
          }
          pageSize={pageSize}
          onExport={onExport ? () => onExport(params) : undefined}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <div className={'shadow-sm overflow-auto mb-3'} style={smallHeight ? { maxHeight: '400px' } : {}}>
            <Bs4Table
              {...getTableProps()}
              className={cx('table-bordered shadow-sm table-hover mb-0', {
                'table-change-row-color': changeRowColor,
                'small-font': smallSize,
              })}
            >
              <thead>
                {_.map(headerGroups, (headerGroup, i) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                    {_.map(headerGroup.headers, (column: any, j: number) =>
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      customHeaderClick && column.access ? (
                        <th
                          style={{ zIndex: 0 }}
                          className={(column.className ? column.className : '') + (smallHeight ? ' sticky-top bg-white' : '')}
                          {...column.getHeaderProps()}
                          key={j}
                        >
                          <div
                            className={cx('d-flex justify-content-between align-items-center', {
                              'column-width': minWidth,
                            })}
                            onClick={() => {
                              customHeaderClick && closeColumn(column.access)
                            }}
                          >
                            <span>{column.render('Header')}</span>
                          </div>
                        </th>
                      ) : (
                        <th
                          style={{ zIndex: 0 }}
                          className={cx(column.className) + (smallHeight ? ' sticky-top bg-white' : '')}
                          key={j}
                          {...column.getHeaderProps()}
                          type={column.originalId === 'expander_placeholder' ? 'empty' : ''}
                        >
                          <div
                            className={cx('d-flex justify-content-between align-items-center', {
                              'column-width': minWidth,
                            })}
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            <span id={column.id} className="d-block text-truncate" title={column.render('Header')}>
                              {column.render('Header')}
                            </span>
                            <UncontrolledTooltip placement="top" target={column.id}>
                              {column?.toolTipText ? column?.toolTipText : column.render('Header')}
                            </UncontrolledTooltip>

                            {column.isSorted ? (
                              <i
                                className={cx('fas', {
                                  'fa-sort-amount-up': column.isSortedDesc,
                                  'fa-sort-amount-down-alt': !column.isSortedDesc,
                                })}
                              ></i>
                            ) : (
                              showShortableIcon && <i className={'fas fa-chevron-down mr-2'}></i>
                            )}
                          </div>
                          {column.canSearch &&
                            (column.filter === 'select' ? (
                              <div className="d-flex mt-2">
                                <select
                                  className="custom-select custom-select-sm"
                                  onChange={(event) => {
                                    const { value } = event.target
                                    let columns = _.filter(individualColumns, (item) => item.name !== column.id)
                                    columns = value.length > 0 ? [...columns, { name: column.id, search: value }] : columns
                                    setIndividualColumns(columns)
                                  }}
                                >
                                  <option value="">{t('All')}</option>
                                  {column.type === 'yes/no' ? (
                                    <>
                                      <option value="true">{t('Yes')}</option>
                                      <option value="false">{t('No')}</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="1">{t('Active')}</option>
                                      <option value="0">{t('Passive')}</option>
                                    </>
                                  )}
                                </select>
                              </div>
                            ) : (
                              <div className="d-flex mt-2">
                                <Input.Search
                                  className="form-control form-control-sm"
                                  placeholder={
                                    typeof column.render('Header') !== 'object'
                                      ? column.render('Header')
                                      : typeof column?.title !== 'undefined'
                                      ? column.render('title')
                                      : ''
                                  }
                                  onChange={(event) => {
                                    const { value } = event.target
                                    let columns = _.filter(individualColumns, (item) => item.name !== column.id)
                                    columns = value.length > 0 ? [...columns, { name: column.id, search: value }] : columns
                                    setIndividualColumns(columns)
                                  }}
                                />
                              </div>
                            ))}
                        </th>
                      )
                    )}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isLoading ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="d-flex align-items-center justify-content-center w-100 py-4">
                        <div className="spinner-grow text-primary"></div>
                        <div className="ml-2">{t('Loading')}...</div>
                      </div>
                    </td>
                  </tr>
                ) : !page.length ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="d-flex align-items-center justify-content-center w-100 py-4">
                        <span className="text-warning">{t('There are no records found.')}</span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  _.map(page, (row, i: number) => {
                    prepareRow(row)
                    return (
                      <Fragment key={i}>
                        <tr
                          {...row.getRowProps()}
                          className={cx(`${getRowClass && getRowClass(row)}`, {
                            'cursor-pointer': onRowSelected !== undefined,
                            active: selectedRowIndex === i,
                            gray: grayBackground && row.index % 2 && selectedRowIndex != i,
                            'active-row': changeRowColor && selectedRowIndex === i,
                          })}
                        >
                          {row.cells.map((cell: any, j: number) => (
                            <td
                              className={
                                cell.column.id !== 'expander' && getCellClass
                                  ? getCellClass(cell)
                                  : cell.column.className
                                  ? cell.column.className
                                  : null
                              }
                              {...cell.getCellProps()}
                              key={j}
                              onClick={() => {
                                if (cell.column.id !== 'expander') onSelectRow(row, i)
                              }}
                              onDoubleClick={() => {
                                if (cell.column.id !== 'expander') onDoubleClickRow(row, i)
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                        {/*
                          If the row is in an expanded state, render a row with a
                          column that fills the entire length of the table.
                        */}
                        {!isOpenRowInMobile && row.isExpanded ? (
                          <tr className="d-sm-table-row d-md-none">
                            <td colSpan={visibleColumns.length}>
                              {/*
                                Inside it, call our renderRowSubComponent function. In reality,
                                you could pass whatever you want as props to
                                a component like this, including the entire
                                table instance. But for this example, we'll just
                                pass the row
                              */}
                              {renderRowSubComponent({ row })}
                            </td>
                          </tr>
                        ) : isOpenRowInMobile ? (
                          row.isExpanded ?? (
                            <tr className="d-sm-table-row d-md-none">
                              <td colSpan={visibleColumns.length}>
                                {/*
                                Inside it, call our renderRowSubComponent function. In reality,
                                you could pass whatever you want as props to
                                a component like this, including the entire
                                table instance. But for this example, we'll just
                                pass the row
                              */}
                                {renderRowSubComponent({ row })}
                              </td>
                            </tr>
                          )
                        ) : null}
                      </Fragment>
                    )
                  })
                )}
              </tbody>
            </Bs4Table>
          </div>
        </div>
      </div>
      {showNavigation && (
        <nav className="d-flex align-items-center" aria-label="Table navigation">
          <ul className="pagination pagination-sm mb-0">
            <li className="page-item">
              <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </button>
            </li>
            {isLoading ? (
              <li className="page-item active">
                <span className="page-link">1</span>
              </li>
            ) : (
              _.map(pages, (page, index: number) => {
                return page === LEFT_PAGE || page === RIGHT_PAGE ? (
                  <li key={index} className="page-item">
                    <span className="page-link">...</span>
                  </li>
                ) : (
                  <li
                    key={index}
                    className={cx('page-item', {
                      active: page === pageIndex + 1,
                    })}
                  >
                    <button className="page-link" onClick={() => gotoPage(page - 1)}>
                      {page}
                    </button>
                  </li>
                )
              })
            )}
            <li className="page-item">
              <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </button>
            </li>
          </ul>
          <div className="d-none d-lg-block ml-2">
            <Trans
              t={t}
              defaults="Page <0>{{ page }}</0> of <0>{{ total }}</0> is displayed."
              values={{
                page: pageIndex + 1,
                total: !isLoading && pageCount ? pageCount : 1,
              }}
              components={[<strong>univers</strong>, <strong>univers</strong>]}
            />
            &nbsp;
            <Trans
              t={t}
              defaults="Total <0>{{ total }}</0> are records."
              values={{ total: totalCount }}
              components={[<strong>univers</strong>, <strong>univers</strong>]}
            />
          </div>

          <div className="d-flex align-self-end ml-auto">
            <div className="pagination-page-size ml-3">
              {/* !isLoading && pageCount ? pageCount : 1 */}

              <UncontrolledDropdown>
                <DropdownToggle className="btn-sm" caret>
                  {pageSize}
                </DropdownToggle>
                <DropdownMenu>
                  {_.map([5, 10, 20, 30, 40, 50], (size: number) => (
                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        setPageSize(size)
                      }}
                      key={size}
                    >
                      {size}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </nav>
      )}
    </>
  )
}

export default Table
