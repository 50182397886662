import * as React from 'react'
import { Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'

// import authenticate from '../../services/authentication'

import { Iframe } from '../Layout'

const PublicRoute = (props: any) => {
  const { path, component: Component, render, ...rest } = props

  if (typeof Component === 'undefined' && typeof render !== 'undefined') return render(props)

  return <Route path={path} component={() => <Iframe component={Component} {...rest} />} />
}

export default withTranslation()(PublicRoute)
