import { action, makeAutoObservable } from 'mobx'

class UIStore {
  backgroundColor: string
  headerBackgroundColor: string
  enableBackgroundImage: boolean
  enableClosedSidebar: boolean
  enableFixedHeader: boolean
  enableHeaderShadow: boolean
  enableSidebarShadow: boolean
  enableFixedFooter: boolean
  enableFixedSidebar: boolean
  colorScheme: string
  backgroundImage: string
  backgroundImageOpacity: string
  enablePageTitleIcon: boolean
  enablePageTitleSubheading: boolean
  enablePageTabsAlt: boolean
  constructor() {
    makeAutoObservable(this)

    this.backgroundColor = 'bg-white sidebar-text-default'
    this.headerBackgroundColor = 'bg-custom-dark header-text-light'
    this.enableBackgroundImage = false
    this.enableClosedSidebar = false
    this.enableFixedHeader = true
    this.enableHeaderShadow = true
    this.enableSidebarShadow = true
    this.enableFixedFooter = true
    this.enableFixedSidebar = true
    this.colorScheme = 'white'
    this.backgroundImage = ''
    this.backgroundImageOpacity = 'opacity-06'
    this.enablePageTitleIcon = true
    this.enablePageTitleSubheading = true
    this.enablePageTabsAlt = true

    this.fullScreen = this.fullScreen.bind(this)
  }

  @action.bound async fullScreen() {
    this.enableClosedSidebar = true
  }
}

export default UIStore
