import * as React from 'react'

const validImage = [/\.bmp$/, /\.gif$/, /\.jpe?g$/, /\.png$/]
const validPdf = [/\.pptx$/]

export default function PreviewCell() {
  return class extends React.Component<any> {
    render() {
      const { dataItem } = this.props

      const isItAValidImage = !!dataItem.documentUrl && validImage.some((curRegExp) => curRegExp.test(dataItem.extension))
      const isItAvalidPdf = !!dataItem.documentUrl && validPdf.some((curRegExp) => curRegExp.test(dataItem.extension))

      return (
        <td>
          {isItAValidImage ? (
            <a href={dataItem.documentUrl} target="_blank">
              <img src={dataItem.documentUrl} alt={dataItem.documentName} style={{ width: '100%' }} />
            </a>
          ) : isItAvalidPdf ? (
            <a href={dataItem.documentUrl} target="_blank">
              <img
                src="https://files.securitas.com.tr/appuploaded-4594d8f0-8e85-49cf-8aa2-46fadsf1479/AVM/24cbe4c2-f7dc-414f-94ab-065e7e75b2f8.jpg"
                style={{ width: '80%' }}
              ></img>
            </a>
          ) : (
            ''
          )}
        </td>
      )
    }
  }
}
