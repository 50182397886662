import React, { FC, useState, useMemo, ButtonHTMLAttributes, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Popper from 'popper.js'
import { Tooltip } from 'reactstrap'
import parse from 'html-react-parser'
import cx from 'classnames'

import uuid from '../../utils/uuid'
import Dialog from '../Dialog'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: any
  text?: string
  confirmText?: string
  showConfirm?: boolean
  tooltipText?: string
  tooltipPlacement?: Popper.Placement
  disabled?: boolean
}

const Button: FC<IButtonProps> = (props: IButtonProps) => {
  const { t } = useTranslation()
  const {
    icon,
    text,
    showConfirm = false,
    confirmText = t('Would you like to proceed?'),
    tooltipText,
    tooltipPlacement = 'top',
    onClick,
    className,
    disabled,
    ...rest
  } = props

  const [isPending, setPending] = useState<boolean>(false)
  const [isDisabled, setDisable] = useState<boolean>(disabled ? true : false)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setDisable(disabled ?? false)
  }, [disabled])

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const id = useMemo(() => `button-${uuid.v4()}`, [])

  const _onClick = (event: any) => {
    if (onClick)
      new Promise((resolve, reject) => {
        try {
          setDisable(true)
          setTimeout(() => {
            resolve(true)
          }, 100)
        } catch (e) {
          reject()
        }
      })
        .then(async () => {
          if (showConfirm) {
            var choice = await Dialog({
              content: t(confirmText ?? ''),
              showOKButton: true,
            })

            if (choice) {
              setPending(true)
              return onClick(event)
            }
          } else {
            setPending(true)
            return onClick(event)
          }

          return true
        })
        .finally(() => {
          if (isMounted.current) {
            setPending(false)
            setDisable(false)
          }
        })
  }

  return (
    <>
      <button id={id} className={className} onClick={_onClick} {...rest} disabled={isDisabled}>
        {isPending && <i className={cx('fas fa-spinner fa-spin', { 'mr-2': typeof text !== 'undefined' && text?.length > 0 })}></i>}
        {typeof icon !== 'undefined' && !isPending && icon}
        {typeof text !== 'undefined' && text?.length > 0 && parse(text)}
      </button>
      {typeof tooltipText !== 'undefined' && tooltipText?.length > 0 && (
        <Tooltip placement={tooltipPlacement} isOpen={tooltipOpen} target={id} toggle={toggleTooltip}>
          {tooltipText}
        </Tooltip>
      )}
    </>
  )
}

export default Button
