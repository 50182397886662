import React, { FC, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { Button } from '@/components'
import { toast } from 'react-toastify'
import { ICustomerOfferDto } from '../../../customer-offer-grid/types'
import { Tooltip } from 'reactstrap'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import cx from 'classnames'
import DecideIntersectingOffersPopup from '../../../customer-offer-grid/decide-intersecting-offers-popup'

interface IStepProps {
  CustomerOfferStore?: any
  nextStep: any
  returnToOfferGrid: any
}

const Step: FC<IStepProps> = inject('CustomerOfferStore')(
  observer((props: IStepProps) => {
    const { CustomerOfferStore: store, nextStep, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { customerOffer } = store!

    const isAccepted = !!customerOffer.startDate

    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [showStartDateTooltip, setShowStartDateTooltip] = useState(false)
    const [showEndDateTooltip, setShowEndDateTooltip] = useState(false)
    const [showDecideIntersectingOffersPopup, setShowDecideIntersectingOffersPopup] = useState(false)
    const [acceptedOfferAsPostParam, setAcceptedOfferAsPostParam] = useState<ICustomerOfferDto>()
    const [intersectingOffersParam, setIntersectingOffersParam] = useState<ICustomerOfferDto[]>()

    useEffect(() => {
      if (isAccepted) {
        setStartDate(customerOffer.startDate)
        setEndDate(customerOffer.endDate)
      }
    }, [])

    if (!isAccepted) return null

    const changeServiceDates = async () => {
      if (!isAccepted) {
        toast.error(t('Unknown Error'))
        return
      }

      if (!startDate || !endDate) {
        toast.error(t('Please fill in all fields'))
        return
      }

      if (moment(startDate).toDate() > moment(endDate).toDate()) {
        toast.error(t('Start date must be before the end date'))
        return
      }

      setIsLoading(true)

      try {
        const tmpAcceptedOfferAsPostParam = {
          ...customerOffer,
          startDateStr: moment(startDate).format('DD-MM-YYYY'),
          endDateStr: moment(endDate).format('DD-MM-YYYY')
        } as ICustomerOfferDto

        setAcceptedOfferAsPostParam(tmpAcceptedOfferAsPostParam)

        const checkRes = await store!.checkIntersectingOffers(tmpAcceptedOfferAsPostParam) as ICustomerOfferDto[]

        setIntersectingOffersParam(checkRes)

        if (checkRes == null || checkRes.length == 0) {
          const changeServiceDatesRes = await store!.changeServiceDates(tmpAcceptedOfferAsPostParam)
          toast.success(t('Successfully saved'))
          setIsLoading(false)
        }
        else {
          setShowDecideIntersectingOffersPopup(true)
          setIsLoading(false)
        }
      }
      catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }

    return (
      <>
        <div className="card-body">
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-12" style={{ display: 'flex' }}>
              <div className="col-md-3" style={{ margin: 'auto' }}>
                {t('Customer Offer') + ' Id: '}
              </div>
              <div className="col-md-3" style={{ margin: 'auto', fontWeight: 'bold' }}>
                <input
                  type="text"
                  className="form-control"
                  value={(customerOffer.id || "")}
                  disabled={true}
                />
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-12" style={{ display: 'flex' }}>
              <div className="col-md-3" style={{ margin: 'auto' }}>
                {t('Start Date') + " "}
                <i id="idStartDate" className="fas fa-circle-info mr-2"></i>
                <Tooltip
                  placement={'top-start'}
                  isOpen={showStartDateTooltip}
                  target={'idStartDate'}
                  toggle={() => setShowStartDateTooltip(!showStartDateTooltip)}
                >
                  {t('The date the security service starts')}
                </Tooltip>
              </div>
              <div className="col-md-3">
                <ReactDatePicker
                  dateFormat={moment().format('L')}
                  locale={moment.locale()}
                  className={cx('form-control')}
                  id="startDate"
                  name="startDate"
                  value={startDate ? moment(startDate).format('L') : ''}
                  selected={startDate && moment(startDate).toDate()}
                  onChange={(date: Date) => {
                    if (date) {
                      setStartDate(moment(date).toDate())
                      customerOffer.startDate = moment(date).toDate()
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-md-12" style={{ display: 'flex' }}>
              <div className="col-md-3" style={{ margin: 'auto' }}>
                {t('End Date') + " "}
                <i id="idEndDate" className="fas fa-circle-info mr-2"></i>
                <Tooltip
                  placement={'top-start'}
                  isOpen={showEndDateTooltip}
                  target={'idEndDate'}
                  toggle={() => setShowEndDateTooltip(!showEndDateTooltip)}
                >
                  {t('The date the security service ends')}
                </Tooltip>
              </div>
              <div className="col-md-3">
                <ReactDatePicker
                  dateFormat={moment().format('L')}
                  locale={moment.locale()}
                  className={cx('form-control')}
                  id="endDate"
                  name="endDate"
                  value={endDate ? moment(endDate).format('L') : ''}
                  selected={endDate && moment(endDate).toDate()}
                  onChange={(date: Date) => {
                    if (date) {
                      setEndDate(moment(date).toDate())
                      customerOffer.endDate = moment(date).toDate()
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-12" style={{ display: 'flex' }}>
            <div className="col-md-3"></div>
            <div className="col-md-3 text-right">
            <Button
              className="btn btn-danger mr-auto"
              onClick={changeServiceDates}
              icon={<i className="fas fa-calendar-xmark"></i>}
              text={' ' + t('Change The Dates Of Service')}
              showConfirm={false}
              disabled={isLoading}
            />
            </div>
            <div className="col-md-6"></div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isAccepted}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
              disabled={isLoading}
            />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-primary ml-auto" onClick={nextStep}>
              {t('Next')} &raquo;
            </button>
          </div>
        </div>
        {showDecideIntersectingOffersPopup && (
          <DecideIntersectingOffersPopup
            toggleModal={() => setShowDecideIntersectingOffersPopup(!showDecideIntersectingOffersPopup)}
            acceptedCustomerOffer={acceptedOfferAsPostParam!}
            intersectingOffersParam={intersectingOffersParam!}
            isThisForChangeServiceDates={true}
          />
        )}
      </>
    )
  })
)

export default Step
