import React, {Component } from 'react'
import { FormBuilder } from '@ginkgo-bioworks/react-json-schema-form-builder'
import './formBuilder.css'

class Card extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      schema: '{}' ? localStorage.getItem('newSchema') : '{}',
      uischema: '{}' ? localStorage.getItem('newUiSchema') : '{}',
      formData: {},
    }
  }

  render() {
    return (
      <div>
        <FormBuilder
          className="formBuilder"
          mods={{
            labels: {
              formNameLabel: 'Anket Başlığı',
              formDescriptionLabel: 'Anket Açıklaması',
              objectNameLabel: 'Nesne Adı',
              displayNameLabel: 'Nesne Görünen Adı',
              descriptionLabel: 'Açıklama',
              inputTypeLabel: 'Giriş Tipi',
            },
            tooltipDescriptions: {
              add: 'Ekle',
              cardObjectName: 'Nesnenin back-end adı', //The back-end name of the object
              cardDisplayName: 'Nesnenin kullanıcıya yönelik adı', //The user-facing name of this object
              cardDescription: 'Ankette açıklama metni olarak görünecektir', //This will appear as help text on the form
              cardInputType: 'Ankette görüntülenen form girişi türü', //The type of form input displayed on the form
              cardSectionObjectName: 'Form bölümünü temsil edecek nesnenin anahtarı.', //The key to the object that will represent this form section.
              cardSectionDisplayName: 'Anketin kullanıcılarına gösterilecek form bölümünün adı.', //The name of the form section that will be shown to users of the form.
              cardSectionDescription: 'Ankette görünecek bölümün açıklaması.', //A description of the section which will be visible on the form.
            },
            deactivatedFormInputs: ['time', 'checkbox', 'array', 'password', 'integer'],
          }}
          schema={this.state.schema}
          uischema={this.state.uischema}
          /*schema={this.props.defaultState.schema}
        uischema={this.props.defaultState.uischema}
        */
          onChange={(newSchema: string, newUiSchema: string) => {
            this.setState({
              schema: newSchema,
              uischema: newUiSchema,
            })
            //this.props.newState(newSchema,newUiSchema)
            localStorage.setItem('newSchema', newSchema ?? '')
            localStorage.setItem('newUiSchema', newUiSchema ?? '')
          }}
        />
      </div>
    )
  }
}
export default Card
