import React, { FC, useState, useRef, MutableRefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Input } from '../../../../../components'
import * as _ from 'lodash'
import Store from './store'
import { MessageResultState } from '@/types/general-types'
import { Combobox } from 'react-widgets/cjs'
import { useForm } from 'react-hook-form'
import cx from 'classnames'
import { ConventionalOfferCovid19ProtectiveEquipment } from '../types'
import { toast } from 'react-toastify'

interface IView {
  ConventionalOfferCovid19ProtectiveEquipmentStore?: Store
  data: ConventionalOfferCovid19ProtectiveEquipment
  isShow: boolean
  isEdit: boolean
  toggleModal: any
  versionId: number
}

const View: FC<IView> = inject('ConventionalOfferCovid19ProtectiveEquipmentStore')(
  observer((props: IView) => {
    const { ConventionalOfferCovid19ProtectiveEquipmentStore: store, data, isShow, isEdit, toggleModal, versionId } = props
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm()

    const [id, setId] = useState(-1)

    const [name, setName] = useState('')

    const [value, setValue] = useState(0)

    const [comment, setComment] = useState('')

    useEffect(() => {
      if (isEdit) {
        setId(data.id)
        setName(data.name)
        setValue(data.value)
        setComment(data.comment)
      }
    }, [])

    const submit = async () => {
      if (name === '') {
        toast.error(t('Please fill name'))
        return
      }

      const dto: ConventionalOfferCovid19ProtectiveEquipment = {
        id: id,
        versionId: versionId,
        name: name,
        value: value,
        comment: comment,
      }
      await store!.save(dto, versionId)
      toggleModal()
    }

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('Covid19 Protective Equipment')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form className="needs-validation" noValidate>
                <div className="form-row py-3">
                  <div className="form-group w-100">
                    <label htmlFor="position" className="">
                      {t('Name')}
                    </label>

                    <input
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={(event) => {
                        setName(event.target.value)
                      }}
                      value={name}
                      disabled={true}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="percentage" className="">
                      {t('Value')} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="value"
                      id="value"
                      type="number"
                      value={value}
                      onChange={(event) => {
                        const { value } = event.target
                        setValue(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="position" className="">
                      {t('Comment')}
                    </label>

                    <input
                      className="form-control"
                      name="comment"
                      id="comment"
                      onChange={(event) => {
                        setComment(event.target.value)
                      }}
                      value={comment}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
