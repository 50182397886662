const loadStores = () => {
  let stores: any = {}
  const req = require.context('../modules/', true, /store.(ts|js)$/i)

  for (const key of req.keys()) {
    const store = require('../modules/' + key.replace('./', ''))
    const { id } = store.default

    stores[id] = new store.default()
  }

  return stores
}

export { loadStores }
