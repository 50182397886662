import React, { FC, useState, useEffect, forwardRef, useRef, useMemo, MouseEvent, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Dropzone, { useDropzone } from 'react-dropzone'
import mime from 'mime-types'
import moment from 'moment'
import * as tus from 'tus-js-client'
import cx from 'classnames'
import * as _ from 'lodash'

import Button from '../Button'
import * as fileIcon from '../../utils/fileIcon'
import uuid from '../../utils/uuid'
import { bytesToSize } from '../../utils/mixed'
import { getToken } from '../../utils/token'
import { getUser } from '../../utils/user'

interface ICustomFile {
  id: string
  iconClassName: string
  sizeForHuman: string
  fileName: string
  mimeType: string
  extension: string
  uploadStatus: 'Not Started' | 'In Progress' | 'Aborted' | 'Done'
  progressStatus: string
  uploadEvent?: tus.Upload
  nativeFile: File
  expirationDate?: Date
}

type EventHandler = { bivarianceHack(files: ICustomFile[]): void }['bivarianceHack']

interface IFileUpload {
  ref?: any
  endpoint: string
  onUpload?: EventHandler
  [key: string]: any
}

declare global {
  interface Number {
    round(places: number): number
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.round = (places) => {
  return +(Math.round(+(this + 'e+' + places)) + 'e-' + places)
}

const FileUpload: FC<IFileUpload> = forwardRef((props: IFileUpload, ref: any) => {
  const { endpoint, onUpload } = props
  const { t } = useTranslation()
  const inputRef = useRef(ref)

  const [isWait, setWait] = useState(false)
  const [files, setFiles] = useState<ICustomFile[]>([])

  const [errors, setErrors] = useState<{
    [key: string]: {
      message: string
    }
  }>({})

  const addFile = (file: ICustomFile) => {
    file.id = uuid.v4()
    setFiles([...files, file])
  }

  const removeFile = (file: ICustomFile) => {
    setFiles(files.filter((item) => item.id !== file.id))
  }

  const updateFile = (file: ICustomFile) => {
    const index = files.findIndex((item) => item.id === file.id)

    files[index] = file
    setFiles([...files])
  }

  const token = getToken()
  const user = getUser()

  const uploadFile = async (file: ICustomFile) => {
    return new Promise((resolve, reject) => {
      const uploadEvent = new tus.Upload(file.nativeFile, {
        endpoint,
        retryDelays: [0, 1000, 3000],
        headers: {
          Authorization: `${token}`,
        },
        metadata: {
          Filename: file.nativeFile.name,
          'Content-Type': file.nativeFile.type || 'application/octet-stream',
          'Expiration-Date': file.expirationDate?.toDateString() || '',
          User: JSON.stringify(user),
        },
        //onShouldRetry: () => true,
        onError: (error) => reject(error),
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          const progress = `${bytesUploaded}/${bytesTotal} (${percentage}%)`

          file = { ...file, uploadStatus: 'In Progress', progressStatus: progress }
          updateFile(file)
        },
        onSuccess: () => {
          file = { ...file, uploadStatus: 'Done', uploadEvent }
          updateFile(file)

          resolve(true)
        },
      })

      file.uploadEvent = uploadEvent
      updateFile(file)

      file.uploadEvent?.start()
    })
  }

  const uploadFiles = async (files: ICustomFile[]) => {
    await Promise.all(files.map(async (file) => await uploadFile(file)))
  }

  const doUpload = async () => {
    _.forEach(files, (file) => {
      if (!file.expirationDate) {
        errors[`fileDate_${file.id}`] = { message: t('Please fill out this field.') }
      }
    })
    setErrors({ ...errors })

    if (Object.keys(errors).length === 0) {
      setWait(true)
      await uploadFiles(files).then(() => {
        setWait(false)
        onUpload && onUpload(files)
      })
    }
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles: File[]) => {
      const customFiles: ICustomFile[] = acceptedFiles.map((file): ICustomFile => {
        const mimeType = mime.lookup(file.name) || ''
        const extension = mimeType ? mime.extension(mimeType) || '' : ''
        const fileName = file.name.replace(new RegExp(`.${extension}$`), '')
        const sizeForHuman = bytesToSize(+file.size)
        const iconClassName = fileIcon.getClassName(extension)

        return {
          nativeFile: file,
          id: uuid.v4(),
          extension,
          fileName,
          mimeType,
          sizeForHuman,
          iconClassName,
          uploadStatus: 'Not Started',
          progressStatus: `0/${file.size} (0%)`,
          expirationDate: undefined,
        }
      })

      setFiles([...files, ...customFiles])
    },
  })

  const sort = (event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
    // TODO
  }

  const emptyWrapper = useMemo(
    () => (
      <div
        className={cx('dropzone-wrapper dropzone-wrapper-sm', { 'd-none': files.length > 0 })}
        onClick={() => {
          inputRef.current.click()
        }}
      >
        <Dropzone>
          {() => (
            <div {...getRootProps()}>
              <input {...getInputProps()} ref={inputRef} />
              <div className="dropzone-content">
                <p>{t('Try dropping some files here, or click to select files to upload.')}</p>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    ),
    [files]
  )

  const fileListWrapper = useMemo(
    () => (
      <div
        className={cx('accepted-files-wrapper overflow-auto', {
          'd-none': files.length === 0,
        })}
      >
        <table className="file-list" id="file-table">
          <thead>
            <tr className="file-list__header">
              <th onClick={sort}>#</th>
              <th onClick={sort}>{t('Name')}</th>
              <th onClick={sort}>{t('Expiration Date')}</th>
              <th onClick={sort}>{t('Type')}</th>
              <th onClick={sort}>{t('Size')}</th>
              <th onClick={sort}>{t('Status')}</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {React.Children.toArray(
              files.map((file, i) => (
                <tr className="file-list__file">
                  <td>{i + 1}</td>
                  <td style={{ maxWidth: '150px' }}>
                    <div className="d-flex align-items-center" title={file.fileName}>
                      <i className={cx(file.iconClassName, 'mr-2')}></i> <span className="d-block text-truncate">{file.fileName}</span>
                    </div>
                  </td>
                  <td>
                    <input
                      className={cx('form-control form-control-sm', {
                        'is-invalid': errors[`fileDate_${file.id}`] !== undefined,
                      })}
                      name={`fileDate_${file.id}`}
                      type="date"
                      onChange={(event) => {
                        delete errors[`fileDate_${file.id}`]
                        setErrors({ ...errors })

                        file.expirationDate = moment(event.target.value).toDate()
                        updateFile(file)
                      }}
                      min={moment(new Date()).format('YYYY-MM-DD')}
                      max={moment(new Date()).add(+30, 'days').format('YYYY-MM-DD')}
                      required
                    />
                    {errors[`fileDate_${file.id}`] && <div className="d-block invalid-feedback">{errors[`fileDate_${file.id}`]?.message}</div>}
                  </td>
                  <td style={{ maxWidth: '150px' }}>
                    <span className="d-block text-truncate" title={file.mimeType}>
                      {file.mimeType}
                    </span>
                  </td>
                  <td>{file.sizeForHuman}</td>
                  <td>
                    <small>{file.progressStatus}</small>
                  </td>
                  <td>
                    {file.uploadStatus === 'In Progress' && (
                      <button
                        className="file-list__file-action pause"
                        onClick={() => {
                          file.uploadStatus = 'Aborted'
                          file.uploadEvent?.abort()

                          updateFile(file)
                        }}
                      >
                        <i className="fas fa-pause"></i>
                      </button>
                    )}
                    {file.uploadStatus === 'Aborted' && (
                      <Button
                        className="file-list__file-action play"
                        icon={<i className="fas fa-play"></i>}
                        tooltipText={t('Upload')}
                        onClick={() => file.uploadEvent?.start()}
                      />
                    )}
                    {file.uploadStatus === 'Not Started' && (
                      <Button
                        className="file-list__file-action remove"
                        icon={<i className="fas fa-trash-alt"></i>}
                        tooltipText={t('Remove')}
                        onClick={() => removeFile(file)}
                        showConfirm={true}
                      />
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-end mt-3">
          <Button
            className="btn btn-primary"
            icon={<i className="fas fa-cloud-upload-alt mr-2"></i>}
            text={t('Upload')}
            onClick={async () => {
              await doUpload()
            }}
            showConfirm={true}
            disabled={isWait}
          />
        </div>
      </div>
    ),
    [errors, files, isWait]
  )

  return useMemo(
    () => (
      <>
        <div className="container">
          {emptyWrapper}
          {fileListWrapper}
        </div>
      </>
    ),
    [errors, files, isWait]
  )
})

export default FileUpload
