import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from '../languages/en.json'
import translationTR from '../languages/tr.json'

const languages: { [key: string]: string } = {
  tr: 'tr-TR',
  en: 'en-US',
}

export function getLanguage(language: string) {
  return languages[language]
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    //fallbackLng: 'tr',
    lng: localStorage.getItem('lng') ?? 'en',
    resources: {
      tr: { translation: translationTR },
      en: { translation: translationEN },
    },

    detection: {
      lookupCookie: 'lng',
      lookupLocalStorage: 'lng',
    },

    whitelist: ['tr', 'en'],

    keySeparator: false, // we do not use keys in form messages.welcome

    saveMissing: false, // send not translated keys to endpoint

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
      useSuspense: true,
    },

    debug: false,
  })

export default i18n
