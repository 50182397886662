import React, { FC, useState, useRef, MutableRefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { Button } from '@/components'
import { IConventionalOfferDocument } from './types'

interface IView {
  ConventionalOfferDocumentStore?: Store
  selectedDocument: IConventionalOfferDocument
  isShow: boolean
  toggleModal: any
  setDidDocsGetUpdated: any
}

const View: FC<IView> = inject('ConventionalOfferDocumentStore')(
  observer((props: IView) => {
    const { ConventionalOfferDocumentStore: store, selectedDocument, isShow, toggleModal, setDidDocsGetUpdated } = props
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const [isLoading, setIsLoading] = useState(true)
    const [fileName, setFileName] = useState<string>()

    useEffect(() => {
      setFileName(selectedDocument.fileDisplayName ?? selectedDocument.name)
      setIsLoading(false)
    }, [])

    const submit = async () => {
      await store!.updateFileName(selectedDocument.id, fileName)
      setDidDocsGetUpdated(true)
      toggleModal()
    }

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('File Display Name') + ' ' + t('Edit')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12" style={{ display: 'flex' }}>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control py-4"
                  value={fileName ?? selectedDocument.name ?? ''}
                  onChange={(event) => {
                    setFileName(event.target.value)
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
