import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '../../../../../components'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { ConventionalOfferWorkHealthAndSafety } from '../types'

interface IView {
  ConventionalOfferWorkHealthAndSafetyStore?: Store
  data: ConventionalOfferWorkHealthAndSafety
  isShow: boolean
  isEdit: boolean
  toggleModal: any
  versionId: number
}

const View: FC<IView> = inject('ConventionalOfferWorkHealthAndSafetyStore')(
  observer((props: IView) => {
    const { ConventionalOfferWorkHealthAndSafetyStore: store, data, isShow, isEdit, toggleModal, versionId } = props
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm()

    const [id, setId] = useState(-1)

    const [cost, setCost] = useState(0)
    const [minimum, setMinimum] = useState(0)
    const [recommended, setRecommended] = useState(0)

    const [explanation, setExplanation] = useState('')

    useEffect(() => {
      if (isEdit) {
        setId(data.id)
        setCost(data.cost)
        setMinimum(data.minimum)
        setRecommended(data.recommended)
        setExplanation(data.explanation)
      }
    }, [])

    const submit = async () => {
      const dto: any = {
        id: id,
        versionId: versionId,
        cost: cost,
        minimum: minimum,
        recommended: recommended,
        explanation: explanation,
      }
      await store!.save(dto, versionId)
      toggleModal()
    }

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('Work Health And Safety')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form className="needs-validation" noValidate>
                <div className="form-row py-3">
                  <div className="form-group w-100">
                    <label htmlFor="percentage" className="">
                      {t('Cost')} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="cost"
                      id="cost"
                      type="number"
                      defaultValue={cost}
                      onChange={(event) => {
                        const { value } = event.target
                        setCost(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="percentage" className="">
                      {'Minimum'} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="minimum"
                      id="minimum"
                      type="number"
                      defaultValue={minimum}
                      onChange={(event) => {
                        const { value } = event.target
                        setMinimum(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="percentage" className="">
                      {t('Recommended')} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="recommended"
                      id="recommended"
                      type="number"
                      defaultValue={recommended}
                      onChange={(event) => {
                        const { value } = event.target
                        setRecommended(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="position" className="">
                      {t('Explanation')}
                    </label>

                    <input
                      className="form-control"
                      name="explanation"
                      id="explanation"
                      onChange={(event) => {
                        setExplanation(event.target.value)
                      }}
                      value={explanation}
                      disabled={true}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
