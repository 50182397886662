import React, { useState, useEffect, FC, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import Store from './store'

import { Button, ConfirmModal, Table } from '@/components'
import '@progress/kendo-ui'
import $ from 'jquery'
import moment from 'moment'
import { ICustomerOfferDto } from './types'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import AcceptOfferPopup from './accept-offer-popup'
import ChangeServiceDatesPopup from './change-service-dates-popup'
import ProjectUnitsPopup from './project-units-popup'
import PdfTypePopup from './pdf-type-popup'

interface ICustomerOfferGridProps {
  CustomerOfferGridStore?: Store
  t: any
}

const CustomerOfferGrid: FC<ICustomerOfferGridProps> = inject('CustomerOfferGridStore')(
  observer((props: ICustomerOfferGridProps) => {
    const { CustomerOfferGridStore: store, t } = props

    const { customerOffers } = store!

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)

    const [selectedCustomerOfferRow, setSelectedCustomerOfferRow] = useState<ICustomerOfferDto>({} as ICustomerOfferDto)

    const [deleteModal, setDeleteModal] = useState(false)
    const toggleDeleteModal = () => setDeleteModal(!deleteModal)

    const [showAcceptOfferPopup, setShowAcceptOfferPopup] = useState(false)
    const [showChangeServiceDatesPopup, setShowChangeServiceDatesPopup] = useState(false)
    const [doesGridNeedARefresh, setDoesGridNeedARefresh] = useState(true)

    const [showProjectUnitsPopup, setShowProjectUnitsPopup] = useState(false)
    const [showPdfTypePopup, setShowPdfTypePopup] = useState(false)
    const [showEmailConfirmPopup, setShowEmailConfirmPopup] = useState(false)
    const [isAdmin, setIsAdmin] = useState(true)

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

    useEffect(() => {
      if (doesGridNeedARefresh) {
        setDoesGridNeedARefresh(false)
        store!.getCustomerOffers({ skipCount: 0, maxResultCount: 5 }).then(() => setIsLoading(false))
      }
    }, [doesGridNeedARefresh])

    useEffect(() => {
      const customerOfferGridElement = $('table')
      if (customerOfferGridElement) {
        kendo.ui.progress(customerOfferGridElement, isLoading)
      }
    }, [isLoading])

    const detailChooseDeletionMessage = () => {
      return (
        <>
          <p>
            {t('Customer Name')}: {selectedCustomerOfferRow.customerName}
          </p>
          <p>
            {t('Creator Name')}: {selectedCustomerOfferRow.creatorUserName}
          </p>
          <p>
            {t('Creation Time')}: {moment(selectedCustomerOfferRow.creationTime).format('DD.MM.YYYY - HH:mm')}
          </p>
        </>
      )
    }

    const customerOfferColumns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                <div className="d-flex justify-content-center">
                  {!row.startDate && !row.isReadOnly && (
                    <Button
                      icon={<i className="fas fa-check"></i>}
                      tooltipText={t('Accept')}
                      onClick={async () => {
                        setSelectedCustomerOfferRow(row)
                        setShowAcceptOfferPopup(true)
                      }}
                      className="btn btn-link text-success"
                    />
                  )}
                  <Button
                    icon={<i className={'fas fa-file-pdf'}></i>}
                    tooltipText={t('Download As Pdf')}
                    onClick={async () => {
                      setSelectedCustomerOfferRow(row)
                      setShowPdfTypePopup(true)
                    }}
                    className="btn btn-link text-danger"
                  />
                  <Button
                    icon={row.isReadOnly ? <i className="fas fa-eye"></i> : <i className="fas fa-pen"></i>}
                    tooltipText={row.isReadOnly ? t('Details') : t('Edit')}
                    onClick={async () => {
                      setSelectedCustomerOfferRow(row)
                      history.push(`/digital-offer/customer-offer/${row.id}`)
                    }}
                    className={row.isReadOnly ? 'btn btn-link text' : 'btn btn-link text-warning'}
                  />
                  {!!row.startDate && !row.isReadOnly && (
                    <Button
                      icon={<i className="fas fa-calendar-xmark"></i>}
                      tooltipText={t('Change The Dates Of Service')}
                      onClick={async () => {
                        setSelectedCustomerOfferRow(row)
                        setShowChangeServiceDatesPopup(true)
                      }}
                      className="btn btn-link text-danger"
                    />
                  )}
                </div>
                {((!row.startDate && !row.isReadOnly) || isAdmin) && (
                  <div className="d-flex justify-content-center">
                    {!row.startDate && !row.isReadOnly && (
                      <Button
                        icon={<i className="fas fa-paper-plane"></i>}
                        tooltipText={t('Send The Offer To The Customer')}
                        onClick={async () => {
                          setSelectedCustomerOfferRow(row)

                          const isThereCc = (row.emailAddressesCc || '').length > 0

                          const toAndCCMailAddresses = row.emailAddressesTo + (isThereCc ? ',' + row.emailAddressesCc : '')

                          const emailAddressArray = toAndCCMailAddresses.split(',').map((mail: string) => mail.trim())

                          if (!emailAddressArray.every((x: string) => emailRegex.test(x))) {
                            toast.error(t('EmailFormatError'))
                          } else {
                            setShowEmailConfirmPopup(true)
                          }
                        }}
                        className="btn btn-link"
                      />
                    )}
                    <Button
                      icon={<i className="fas fa-trash"></i>}
                      tooltipText={t('Delete')}
                      onClick={async () => {
                        setSelectedCustomerOfferRow(row)
                        setDeleteModal(true)
                      }}
                      className="btn btn-link text-danger"
                    />
                  </div>
                )}
              </>
            )
          },
        },
        {
          Header: 'Id',
          id: 'id',
          accessor: 'id',
          Cell: ({ row }: any) => {
            const { id } = row.values

            return <span dangerouslySetInnerHTML={{ __html: id }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Customer Name'),
          id: 'customerName',
          accessor: 'customerName',
          Cell: ({ row }: any) => {
            const { customerName } = row.values

            return <span dangerouslySetInnerHTML={{ __html: customerName }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Unit'),
          id: 'unitNameArr',
          accessor: 'unitNameArr',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            const isThereAButton = row.unitNameArr != undefined && row.unitNameArr.length > 1
            let cellText = ''
            if (isThereAButton) {
              cellText = '' + row.unitNameArr.length
            } else {
              if (row.unitNameArr == undefined || row.unitNameArr.length == 0) {
                cellText = '-'
              } else {
                cellText = row.unitNameArr[0]
              }
            }

            if (isThereAButton) {
              return (
                <Button
                  text={cellText}
                  onClick={async () => {
                    setSelectedCustomerOfferRow(row)
                    setShowProjectUnitsPopup(true)
                  }}
                  className="btn btn-link text-warning"
                />
              )
            }

            return <span dangerouslySetInnerHTML={{ __html: cellText }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Accepted Date Range'),
          id: 'acceptedDateRange',
          accessor: 'acceptedDateRange',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            const cellText = !row.startDate ? '-' : moment(row.startDate).format('DD.MM.YYYY') + ' - ' + moment(row.endDate).format('DD.MM.YYYY')

            return <span dangerouslySetInnerHTML={{ __html: cellText }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Sent Time To Customer'),
          id: 'customerSentTime',
          accessor: 'customerSentTime',
          Cell: ({ row }: any) => {
            const { customerSentTime } = row.values

            return <span dangerouslySetInnerHTML={{ __html: customerSentTime ? moment(customerSentTime).format('DD.MM.YYYY - HH:mm') : null }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Email Addresses'),
          id: 'emailAddresses',
          accessor: 'emailAddresses',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            const isThereCc = (row.emailAddressesCc || '').length > 0

            return <span dangerouslySetInnerHTML={{ __html: row.emailAddressesTo + (isThereCc ? ',' + row.emailAddressesCc : '') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Last Editor Name'),
          id: 'lastEditorUserName',
          accessor: 'lastEditorUserName',
          Cell: ({ row }: any) => {
            const { lastEditorUserName } = row.values

            return <span dangerouslySetInnerHTML={{ __html: lastEditorUserName }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Last Edit Time'),
          id: 'lastEditDateTime',
          accessor: 'lastEditDateTime',
          Cell: ({ row }: any) => {
            const { lastEditDateTime } = row.values

            return <span dangerouslySetInnerHTML={{ __html: lastEditDateTime ? moment(lastEditDateTime).format('DD.MM.YYYY - HH:mm') : null }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t, isAdmin]
    )

    return (
      <>
        <div className="card-header">
          <div className="btn-actions-pane-right actions-icon-btn">
            <Link to="/digital-offer/customer-offer" className="btn btn-primary btn-shadow">
              <i className="fas fa-plus mr-2"></i>
              {t('New Customer Offer')}
            </Link>
          </div>
        </div>

        <br />

        <div className="row" style={{ marginBottom: '-50px', marginLeft: '22px' }}>
          {t('Accepted offers cannot be changed')}
        </div>

        <div className="card-body">
          <Table
            smallSize={true}
            columns={customerOfferColumns}
            totalCount={customerOffers.totalCount}
            data={customerOffers.items}
            fetchData={(x) => store?.getCustomerOffers(x)}
            isLoading={!customerOffers.isLoaded}
          />
        </div>

        {deleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={deleteModal}
            toggleModal={toggleDeleteModal}
            onConfirm={async () => {
              await store!.deleteCustomerOffer(selectedCustomerOfferRow.id!)
              toggleDeleteModal()
            }}
          />
        )}

        {showAcceptOfferPopup && (
          <AcceptOfferPopup
            toggleModal={() => setShowAcceptOfferPopup(!showAcceptOfferPopup)}
            selectedCustomerOffer={selectedCustomerOfferRow}
            setDoesGridNeedARefresh={setDoesGridNeedARefresh}
          />
        )}

        {showChangeServiceDatesPopup && (
          <ChangeServiceDatesPopup
            toggleModal={() => setShowChangeServiceDatesPopup(!showChangeServiceDatesPopup)}
            selectedCustomerOffer={selectedCustomerOfferRow}
            setDoesGridNeedARefresh={setDoesGridNeedARefresh}
          />
        )}

        {showProjectUnitsPopup && (
          <ProjectUnitsPopup toggleModal={() => setShowProjectUnitsPopup(!showProjectUnitsPopup)} selectedCustomerOffer={selectedCustomerOfferRow} />
        )}

        {showPdfTypePopup && (
          <PdfTypePopup
            toggleModal={() => setShowPdfTypePopup(!showPdfTypePopup)}
            selectedCustomerOffer={selectedCustomerOfferRow}
            isEmailConfirmation={false}
            isPdfPreview={false}
          />
        )}

        {showEmailConfirmPopup && (
          <PdfTypePopup
            toggleModal={() => setShowEmailConfirmPopup(!showEmailConfirmPopup)}
            selectedCustomerOffer={selectedCustomerOfferRow}
            isEmailConfirmation={true}
            isPdfPreview={false}
            setDoesGridNeedARefresh={setDoesGridNeedARefresh}
          />
        )}
      </>
    )
  })
)

export default CustomerOfferGrid
