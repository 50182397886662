import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'

import env from '../../../../config/env'
import http from '../../../../services/http'
import qs from 'qs'
import { IConventionalOfferIsLoadedDTO, ISpreadsheetDTO, IOfferDto, IOfferParameterDto } from './types'
import IPagedRequest from '../../../../models/dto/fetch/IPagedRequest'
import IPagedResult from '../../../../models/dto/fetch/IPagedResult'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'ConventionalOfferStore'
  spreadsheet!: IConventionalOfferIsLoadedDTO
  offers!: IPagedResult<IOfferDto>

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.spreadsheet = {
      isLoaded: true,
    }

    this.offers = {
      totalCount: 0,
      items: [],
      isLoaded: false,
    }

    this.getSpreadsheet = this.getSpreadsheet.bind(this)

    this.getOffers = this.getOffers.bind(this)
  }

  @action.bound async getSpreadsheet(offerParamDto: IOfferParameterDto) {
    this.spreadsheet.isLoaded = false

    let params = `?versionId=${offerParamDto.versionDto?.id}&isItForExistingCustomer=${offerParamDto.isItForExistingCustomer}`

    if (offerParamDto.filterOffer?.id != undefined) {
      params += `&offerId=${offerParamDto.filterOffer.id}`
    }

    if (offerParamDto.templateOffer?.id != undefined) {
      params += `&templateOfferId=${offerParamDto.templateOffer.id}`
    }

    const result: ISpreadsheetDTO = await (await this.api.get(`/api/app/conventional-offer/spreadsheet${params}`)).data
    this.spreadsheet = { spreadsheet: result, isLoaded: true }
  }

  @action.bound async postSpreadsheet(dto: ISpreadsheetDTO, versionId: number, isItForExistingCustomer: boolean, offerId?: number) {
    const params = `?versionId=${versionId}&isItForExistingCustomer=${isItForExistingCustomer}` + (offerId == null ? '' : `&offerId=${offerId}`)

    const result = await this.api.post(`/api/app/conventional-offer${params}`, dto)

    return result
  }

  @action.bound unloadSpreadsheet() {
    this.spreadsheet.isLoaded = false
  }

  @action.bound async calculateCircularReferences(dto: ISpreadsheetDTO, offerParamDto: IOfferParameterDto) {
    const params = `?versionId=${offerParamDto.versionDto!.id}&isItForExistingCustomer=${offerParamDto.isItForExistingCustomer}`

    const result = await this.api.post(`/api/app/conventional-offer/calculate-circular-references${params}`, dto)

    return result
  }

  @action.bound async getOffers(params: IPagedRequest) {
    this.offers.isLoaded = false
    const q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })

    const result: IPagedResult<IOfferDto> = await (await this.api.get(`/api/app/conventional-offer/offers${q}`)).data
    this.offers = { ...result, isLoaded: true }
  }

  @action.bound async getOffersAsList() {
    const result = await this.api.get(`/api/app/conventional-offer/offers-as-list`)

    return result.data
  }

  @action.bound async deleteOffer(id: number) {
    await this.api.delete(`/api/app/conventional-offer/${id}`)
    this.getOffers({ skipCount: 0, maxResultCount: 5 })
  }

  @action.bound async getSingleOffer(id: number) {
    const result = await this.api.get(`/api/app/conventional-offer/single-offer?offerId=${id}`)

    return result.data
  }

  @action.bound async getVersionsAsList() {
    const result = await this.api.get(`/api/app/conventional-offer-version/versions-as-list`)

    return result.data
  }
}

export default Store
