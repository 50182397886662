const mobilePhoneRegex = /^5\d{9}$/

export function checkIfMobilePhoneValid(phoneNumber: string) {
  if (typeof phoneNumber === 'string' && phoneNumber) {
    phoneNumber = phoneNumber.trim()
  }

  return mobilePhoneRegex.test(phoneNumber)
}

const phoneRegex = /^\d{10}$/

export function checkIfPhoneValid(phoneNumber: string) {
  if (typeof phoneNumber === 'string' && phoneNumber) {
    phoneNumber = phoneNumber.trim()
  }

  return phoneRegex.test(phoneNumber)
}

const tcRegex = /^\d{10}[02468]$/

export function checkIfTcValid(tcNumber: string) {
  return tcRegex.test(tcNumber)
}

const languageRegex = /^(TR|EN|tr|en)$/

export function checkIfLanguageValid(language: string) {
  return languageRegex.test(language)
}

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

export function checkIfEmailValid(email: string) {
  return emailRegex.test(email)
}
