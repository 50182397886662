import React, { useState, useEffect, FC, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import Store from './store'

import { Button, Loading, Table } from '@/components'
import '@progress/kendo-ui'
import { IIdNameDTO, IProjectDto, IProjectUnitDetailedDto, IUnitOfferDetailUnitFilterDto } from './types'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Collapse } from 'antd'
import { Combobox } from 'react-widgets/cjs'
import ReactDatePicker from 'react-datepicker'
import cx from 'classnames'
import numeral from 'numeral'
import MonthOfferDetailPopup from './month-offer-detail-popup'

interface IUnitOfferDetailGridProps {
  CustomerOfferGridStore?: Store
  t: any
}

const { Panel } = Collapse

const gridDateFormat = 'YYYY.MM'

const UnitOfferDetailGrid: FC<IUnitOfferDetailGridProps> = inject('CustomerOfferGridStore')(
  observer((props: IUnitOfferDetailGridProps) => {
    const { CustomerOfferGridStore: store, t } = props

    const { unitOfferDetails, totalCountDetails, regions, branches, projects, units } = store!

    const [isLoading, setIsLoading] = useState(true)
    const [region, setRegion] = useState<IIdNameDTO>()
    const [branch, setBranch] = useState<IIdNameDTO>()
    const [project, setProject] = useState<IProjectDto>()
    const [unit, setUnit] = useState<IProjectUnitDetailedDto>()
    const [endDate, setEndDate] = useState<Date>()
    const [collapseActiveKey, setCollapseActiveKey] = useState(['1'])
    const [selectedUnitId, setSelectedUnitId] = useState<string>()
    const [mannedAndTechServiceOffers, setMannedAndTechServiceOffers] = useState<string>()
    const [showMonthOfferDetailPopup, setShowMonthOfferDetailPopup] = useState(false)
    const [unitOfferDetailFilter, setUnitOfferDetailFilter] = useState<IUnitOfferDetailUnitFilterDto>({
      showActiveUnits: false,
      showUnitsWithoutAcceptedOffers: false,
      showActiveUnitsWithoutAcceptedOffers: false,
      showUnitsWithAcceptedOffers: false,
      showUnitsWithIntersectingOffers: false
    } as IUnitOfferDetailUnitFilterDto)

    useEffect(() => {
      Promise.all([store!.getRegions(), store!.getBranches(), store!.getProjects(), store!.getUnits()]).then(() => {
        const curDate = new Date()
        const curDateFirstDay = new Date(curDate.getFullYear(), curDate.getMonth(), 1)
        setEndDate(curDateFirstDay)
        setIsLoading(false)
      })
    }, [])

    useEffect(() => {
      setUnit(undefined)
      store!.getBranches(region?.id)
      if (branch) {
        setBranch(undefined)
      }
      else {
        store!.getUnits(project?.id, region?.id)
      }
    }, [region])

    useEffect(() => {
      setUnit(undefined)
      store!.getUnits(project?.id, region?.id, branch?.id)
    }, [branch])

    useEffect(() => {
      setUnit(undefined)
      store!.getUnits(project?.id, region?.id, branch?.id)
    }, [project])

    useEffect(() => {
      applyFilter()
    }, [unitOfferDetailFilter])

    const applyFilter = async () => {
      if (!!endDate) {
        setIsLoading(true)

        store!.getUnitOfferDetails({ skipCount: 0, maxResultCount: 5 }, endDate,
          unitOfferDetailFilter.showActiveUnits,
          unitOfferDetailFilter.showUnitsWithoutAcceptedOffers,
          unitOfferDetailFilter.showActiveUnitsWithoutAcceptedOffers,
          unitOfferDetailFilter.showUnitsWithAcceptedOffers,
          unitOfferDetailFilter.showUnitsWithIntersectingOffers,
          region, branch, project, unit)
          .then((result) => {
            setIsLoading(false)
          })
          .catch((err: any) => {
            setIsLoading(false)
            toast.error(t('Unknown Error'))
            console.log(err)
          })
      }
    }

    const clearFilter = () => {
      if (region) {
        setRegion(undefined)
      }
      else {
        setBranch(undefined)
      }
      if (project) {
        setProject(undefined)
      }
      else {
        setUnit(undefined)
      }
    }

    const gridColumns = useMemo(
      () => [
        {
          Header: t('Region'),
          id: 'region',
          accessor: 'region',
          Cell: ({ row }: any) => {
            const { region } = row.values

            return <span dangerouslySetInnerHTML={{ __html: region }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Branch'),
          id: 'branch',
          accessor: 'branch',
          Cell: ({ row }: any) => {
            const { branch } = row.values

            return <span dangerouslySetInnerHTML={{ __html: branch }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Project'),
          id: 'project',
          accessor: 'project',
          Cell: ({ row }: any) => {
            const { project } = row.values

            return <span dangerouslySetInnerHTML={{ __html: project }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Unit'),
          id: 'unit',
          accessor: 'unit',
          Cell: ({ row }: any) => {
            const { unit } = row.values

            return <span dangerouslySetInnerHTML={{ __html: unit }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Is Active?'),
          id: 'isActive',
          accessor: 'isActive',
          Cell: ({ row }: any) => {
            const { isActive } = row.values

            const badgeType = isActive === true ? 'success' : 'danger'

            return <span className={`badge badge-${badgeType}`} dangerouslySetInnerHTML={{ __html: isActive === true ? t('Active') : t('Inactive') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Personnel Count'),
          id: 'personnelCount',
          accessor: 'personnelCount',
          Cell: ({ row }: any) => {
            const { personnelCount } = row.values

            return <span dangerouslySetInnerHTML={{ __html: personnelCount }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).format(gridDateFormat),
          id: 'month1',
          accessor: 'month1',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month1.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month1.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month1.areThereIntersectingOffers && <hr />}
              {row.month1.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-1, 'months').format(gridDateFormat),
          id: 'month2',
          accessor: 'month2',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month2.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month2.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month2.areThereIntersectingOffers && <hr />}
              {row.month2.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-2, 'months').format(gridDateFormat),
          id: 'month3',
          accessor: 'month3',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month3.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month3.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month3.areThereIntersectingOffers && <hr />}
              {row.month3.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-3, 'months').format(gridDateFormat),
          id: 'month4',
          accessor: 'month4',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month4.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month4.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month4.areThereIntersectingOffers && <hr />}
              {row.month4.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-4, 'months').format(gridDateFormat),
          id: 'month5',
          accessor: 'month5',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month5.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month5.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month5.areThereIntersectingOffers && <hr />}
              {row.month5.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-5, 'months').format(gridDateFormat),
          id: 'month6',
          accessor: 'month6',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month6.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month6.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month6.areThereIntersectingOffers && <hr />}
              {row.month6.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-6, 'months').format(gridDateFormat),
          id: 'month7',
          accessor: 'month7',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month7.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month7.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month7.areThereIntersectingOffers && <hr />}
              {row.month7.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-7, 'months').format(gridDateFormat),
          id: 'month8',
          accessor: 'month8',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month8.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month8.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month8.areThereIntersectingOffers && <hr />}
              {row.month8.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-8, 'months').format(gridDateFormat),
          id: 'month9',
          accessor: 'month9',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month9.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month9.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month9.areThereIntersectingOffers && <hr />}
              {row.month9.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-9, 'months').format(gridDateFormat),
          id: 'month10',
          accessor: 'month10',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month10.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month10.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month10.areThereIntersectingOffers && <hr />}
              {row.month10.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-10, 'months').format(gridDateFormat),
          id: 'month11',
          accessor: 'month11',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month11.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month11.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month11.areThereIntersectingOffers && <hr />}
              {row.month11.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-11, 'months').format(gridDateFormat),
          id: 'month12',
          accessor: 'month12',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month12.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month12.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month12.areThereIntersectingOffers && <hr />}
              {row.month12.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: moment(endDate!).add(-12, 'months').format(gridDateFormat),
          id: 'month13',
          accessor: 'month13',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (<>
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`₺${numeral(row.month13.totalCost).format('0.0[,]00')}`}
                  onClick={async () => {
                    setSelectedUnitId(row.axUnitId)
                    setMannedAndTechServiceOffers(row.month13.mannedAndTechServiceOffers)
                    setShowMonthOfferDetailPopup(true)
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
              {row.month13.areThereIntersectingOffers && <hr />}
              {row.month13.areThereIntersectingOffers && <div style={{ textAlign: 'center', color: 'red' }}>
                <span dangerouslySetInnerHTML={{ __html: `${t('There are intersecting offers')}` }}></span>
              </div>}
            </>)
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t, endDate]
    )

    return (
      <>
        <Collapse
          activeKey={collapseActiveKey}
          onChange={() =>
            setCollapseActiveKey((prev) => {
              if (prev.length === 1) {
                return []
              }
              return ['1']
            })
          }
        >
          <Panel key="1" header={t('Filters')}>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group w-100">
                    <label htmlFor="region" className="font-weight-bold">
                      {t('Region')}
                    </label>
                    <Combobox
                      id="region"
                      filter="contains"
                      data={regions}
                      value={region?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setRegion(val)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group w-100">
                    <label htmlFor="branch" className="font-weight-bold">
                      {t('Branch')}
                    </label>
                    <Combobox
                      id="branch"
                      filter="contains"
                      data={branches}
                      value={branch?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setBranch(val)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group w-100">
                    <label htmlFor="project" className="font-weight-bold">
                      {t('Project')}
                    </label>
                    <Combobox
                      id="project"
                      filter="contains"
                      data={projects}
                      value={project?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setProject(val)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group w-100">
                    <label htmlFor="unit" className="font-weight-bold">
                      {t('Unit')}
                    </label>
                    <Combobox
                      id="unit"
                      filter="contains"
                      data={units}
                      value={unit?.unitName}
                      textField="unitName"
                      onSelect={(val: any) => {
                        setUnit(val)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group w-100">
                    <label htmlFor="endDate" className="font-weight-bold">
                      {t('End Date')}
                    </label>
                    <ReactDatePicker
                      dateFormat={moment().format('MM-YYYY')}
                      locale={moment.locale()}
                      showMonthYearPicker={true}
                      className={cx('form-control')}
                      id="endDate"
                      name="endDate"
                      value={endDate ? moment(endDate).format('MM-YYYY') : ''}
                      selected={endDate && moment(endDate).toDate()}
                      onChange={(date: Date) => {
                        if (date) {
                          setEndDate(moment(date).toDate())
                        }
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-row py-2">
                    <div className="form-group w-100 d-flex flex-column align-items-center">
                      <div className="">
                        <button
                          className="btn btn-secondary btn-sm btn-shadow ml-3"
                          disabled={isLoading}
                          onClick={(e) => {
                            e.preventDefault()
                            applyFilter()
                          }}
                        >
                          <i className={'fas fa-' + (isLoading ? 'spinner fa-spin' : 'arrow-down') + ' mr-2'}></i>
                          {isLoading ? t('Loading') + '...' : t('Apply Filter')}
                        </button>
                        <button
                          className="btn btn-warning btn-sm btn-shadow ml-3"
                          disabled={isLoading}
                          onClick={(e) => {
                            e.preventDefault()
                            clearFilter()
                          }}
                        >
                          <i className={'fas fa-' + (isLoading ? 'spinner fa-spin' : 'trash-alt') + ' mr-2'}></i>
                          {isLoading ? t('Loading') + '...' : t('Clear Filter')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
        <div className="card-body">
          {!!endDate && (totalCountDetails?.isLoaded
            ? (<div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-md-6 col-12">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{t('Count Of Total Units')}</td>
                      <td style={{ textAlign: 'center' }}>{totalCountDetails.totalUnitCount}</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{t('Count Of Active Units')}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          icon={unitOfferDetailFilter.showActiveUnits ? <i className="fas fa-filter"></i> : undefined}
                          text={` ${totalCountDetails.activeUnitCount}`}
                          onClick={async () => {
                            setUnitOfferDetailFilter({
                              showActiveUnits: !unitOfferDetailFilter.showActiveUnits,
                              showUnitsWithoutAcceptedOffers: false,
                              showActiveUnitsWithoutAcceptedOffers: false,
                              showUnitsWithAcceptedOffers: false,
                              showUnitsWithIntersectingOffers: false,
                            } as IUnitOfferDetailUnitFilterDto)
                          }}
                          className="btn btn-link text-warning"
                          style={{ fontSize: '12px' }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{t('Count Of Units Without Accepted Offers')}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          icon={unitOfferDetailFilter.showUnitsWithoutAcceptedOffers ? <i className="fas fa-filter"></i> : undefined}
                          text={` ${totalCountDetails.unitWithoutOfferCount}`}
                          onClick={async () => {
                            setUnitOfferDetailFilter({
                              showActiveUnits: false,
                              showUnitsWithoutAcceptedOffers: !unitOfferDetailFilter.showUnitsWithoutAcceptedOffers,
                              showActiveUnitsWithoutAcceptedOffers: false,
                              showUnitsWithAcceptedOffers: false,
                              showUnitsWithIntersectingOffers: false,
                            } as IUnitOfferDetailUnitFilterDto)
                          }}
                          className="btn btn-link text-warning"
                          style={{ fontSize: '12px' }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{t('Count Of Active Units Without Accepted Offers')}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          icon={unitOfferDetailFilter.showActiveUnitsWithoutAcceptedOffers ? <i className="fas fa-filter"></i> : undefined}
                          text={` ${totalCountDetails.activeUnitWithoutOfferCount}`}
                          onClick={async () => {
                            setUnitOfferDetailFilter({
                              showActiveUnits: false,
                              showUnitsWithoutAcceptedOffers: false,
                              showActiveUnitsWithoutAcceptedOffers: !unitOfferDetailFilter.showActiveUnitsWithoutAcceptedOffers,
                              showUnitsWithAcceptedOffers: false,
                              showUnitsWithIntersectingOffers: false,
                            } as IUnitOfferDetailUnitFilterDto)
                          }}
                          className="btn btn-link text-warning"
                          style={{ fontSize: '12px' }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{t('Count Of Units With Accepted Offers')}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          icon={unitOfferDetailFilter.showUnitsWithAcceptedOffers ? <i className="fas fa-filter"></i> : undefined}
                          text={` ${totalCountDetails.unitWithOfferCount}`}
                          onClick={async () => {
                            setUnitOfferDetailFilter({
                              showActiveUnits: false,
                              showUnitsWithoutAcceptedOffers: false,
                              showActiveUnitsWithoutAcceptedOffers: false,
                              showUnitsWithAcceptedOffers: !unitOfferDetailFilter.showUnitsWithAcceptedOffers,
                              showUnitsWithIntersectingOffers: false,
                            } as IUnitOfferDetailUnitFilterDto)
                          }}
                          className="btn btn-link text-warning"
                          style={{ fontSize: '12px' }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{t('Count Of Units With Intersecting Offers')}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          icon={unitOfferDetailFilter.showUnitsWithIntersectingOffers ? <i className="fas fa-filter"></i> : undefined}
                          text={` ${totalCountDetails.unitWithIntersectingOffersCount}`}
                          onClick={async () => {
                            setUnitOfferDetailFilter({
                              showActiveUnits: false,
                              showUnitsWithoutAcceptedOffers: false,
                              showActiveUnitsWithoutAcceptedOffers: false,
                              showUnitsWithAcceptedOffers: false,
                              showUnitsWithIntersectingOffers: !unitOfferDetailFilter.showUnitsWithIntersectingOffers,
                            } as IUnitOfferDetailUnitFilterDto)
                          }}
                          className="btn btn-link text-warning"
                          style={{ fontSize: '12px' }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>)
            : <Loading />)}
          {!!endDate && <Table
            smallSize={true}
            columns={gridColumns}
            totalCount={unitOfferDetails.totalCount}
            data={unitOfferDetails.items}
            fetchData={(x) => store!.getUnitOfferDetails(x, endDate,
              unitOfferDetailFilter.showActiveUnits,
              unitOfferDetailFilter.showUnitsWithoutAcceptedOffers,
              unitOfferDetailFilter.showActiveUnitsWithoutAcceptedOffers,
              unitOfferDetailFilter.showUnitsWithAcceptedOffers,
              unitOfferDetailFilter.showUnitsWithIntersectingOffers,
              region, branch, project, unit)}
            isLoading={!unitOfferDetails.isLoaded}
            onExport={async (x: any) => {
              await store!.exportToExcel(t('Unit Offer Details'), x, endDate,
                unitOfferDetailFilter.showActiveUnits,
                unitOfferDetailFilter.showUnitsWithoutAcceptedOffers,
                unitOfferDetailFilter.showActiveUnitsWithoutAcceptedOffers,
                unitOfferDetailFilter.showUnitsWithAcceptedOffers,
                unitOfferDetailFilter.showUnitsWithIntersectingOffers,
                region, branch, project, unit)
            }}
          />}
        </div>
        {showMonthOfferDetailPopup && (
          <MonthOfferDetailPopup
            toggleModal={() => setShowMonthOfferDetailPopup(!showMonthOfferDetailPopup)}
            axUnitId={selectedUnitId!}
            mannedAndTechServiceOffers={mannedAndTechServiceOffers}
          />
        )}
      </>
    )
  })
)

export default UnitOfferDetailGrid
