import React, { useState, useEffect, FC, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import Store from '../../store'
import * as _ from 'lodash'
import {
  TreeList,
  mapTree,
  filterBy,
  extendDataItem,
  TreeListColumnProps,
  TreeListTextEditor,
  TreeListTextFilter,
  TreeListItemChangeEvent,
  TreeListExpandChangeEvent,
  TreeListFilterChangeEvent,
} from '@progress/kendo-react-treelist'
import { Button, Loading } from '@/components'
import { useTranslation } from 'react-i18next'
import PreviewCell from './preview-cell'
import { IConventionalOfferDocument } from '../../../conventional-offer-document/types'

const subItemsField: string = 'documents'
const expandField: string = 'expanded'

interface IPage {
  CustomerOfferStore?: Store
  prevStep: any
  nextStep: any
  returnToOfferGrid: any
}

interface AppState {
  documents: IConventionalOfferDocument[] | undefined
  expanded: string[]
  filter: string[]
  inEdit: Array<{ id: string; isNew: boolean }>
}

const ConventionalOfferDocument: FC<IPage> = inject('CustomerOfferStore')(
  observer((props: IPage) => {
    const { CustomerOfferStore: store, prevStep, nextStep, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { customerOffer, serviceDocuments } = store!

    const isReadOnly = !!customerOffer?.isReadOnly

    const [didDocsGetUpdated, setDidDocsGetUpdated] = useState(false)

    const [state, setState] = React.useState<AppState>({
      documents: [],
      expanded: ['ServiceHead'],
      filter: [],
      inEdit: [],
    })

    useEffect(() => {
      setState({
        ...state,
        documents:
          customerOffer?.offerServiceDocuments && customerOffer?.offerServiceDocuments?.length > 0
            ? (customerOffer?.offerServiceDocuments as [])
            : (serviceDocuments as []),
      })
    }, [])

    useEffect(() => {
      if (didDocsGetUpdated) {
        setState({
          ...state,
          documents:
            customerOffer?.offerServiceDocuments && customerOffer?.offerServiceDocuments?.length > 0
              ? (customerOffer?.offerServiceDocuments as [])
              : (serviceDocuments as []),
        })
        setDidDocsGetUpdated(false)
      }
    }, [didDocsGetUpdated])

    const onExpandChange = (e: TreeListExpandChangeEvent) => {
      setState({
        ...state,
        expanded: e.value ? state.expanded.filter((id) => id !== e.dataItem.id) : [...state.expanded, e.dataItem.id],
      })
    }

    const processData = () => {
      const { documents, expanded } = state
      const filteredData = filterBy(documents!, state.filter as [], subItemsField)
      const positions = new Set()
      const resultTree = mapTree(filteredData, subItemsField, (item) => {
        positions.add(item.position)
        return extendDataItem(item, subItemsField, {
          [expandField]: customerOffer?.offerServiceDocumentsFlat,
        })
      })
      return resultTree
    }

    const parseTree = (tree: IConventionalOfferDocument[], id: string, isSelected: boolean) => {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id === id) {
          tree[i].isSelected = isSelected

          if (isSelected) {
            customerOffer?.offerServiceDocumentsFlat?.push(id)
          } else {
            let index = customerOffer?.offerServiceDocumentsFlat?.indexOf(id) ?? -1

            if (index !== -1) {
              customerOffer?.offerServiceDocumentsFlat?.splice(index, 1)
            }
          }

          return
        }

        if (tree[i].documents !== null) {
          parseTree(tree[i].documents as [], id, isSelected)
        }
      }
    }

    const onItemChange = (event: TreeListItemChangeEvent) => {
      const field: any = event.field
      setState({
        ...state,
        documents: mapTree(
          customerOffer?.offerServiceDocuments && customerOffer?.offerServiceDocuments?.length > 0
            ? (customerOffer?.offerServiceDocuments as [])
            : (serviceDocuments as []),
          subItemsField,
          (item) => (item.id === event.dataItem.id ? extendDataItem(item, subItemsField, { [field]: event.value }) : item)
        ),
      })
    }

    const css = `
    .k-svg-icon {
        cursor: pointer;
    }
    table {
      table-layout: fixed;
      width: 100%;   
    }
    td {
      white-space: normal !important;
    }
    td.k-table-td.k-text-nowrap {
      white-space: normal !important;
    }
    .k-treelist-scrollable > table, .k-treelist-scrollable .k-grid-header tr, .k-treelist-scrollable .k-grid-header th {
      background-color: inherit!important;
    }
    .k-filter-row {
      line-height: 1.4285714286!important;
    }
    .k-grid tr {
      border-color: inherit!important;
    }
    .k-treelist-scrollable .k-grid-header th {
      position: sticky!important;
      z-index: 1!important;
    }
    .k-input {
      background-color: white;
      border: 1px solid #e0dddd;
    }
    `
    const handleFilterChange = (event: TreeListFilterChangeEvent) => {
      setState({
        ...state,
        filter: event.filter as [],
      })
    }

    const CheckedCell = () => {
      return class extends React.Component<any> {
        render() {
          const { dataItem } = this.props

          return dataItem.documentSize > 0 ? (
            <td>
              <input
                style={{ marginLeft: '10px' }}
                className="form-check-input"
                type="checkbox"
                checked={dataItem.isSelected}
                id="checkbox"
                onChange={(event) => {
                  parseTree(
                    customerOffer?.offerServiceDocuments && customerOffer?.offerServiceDocuments?.length > 0
                      ? (customerOffer?.offerServiceDocuments as [])
                      : (serviceDocuments as []),
                    dataItem.id,
                    event.target.checked
                  )
                }}
                disabled={isReadOnly}
              />
            </td>
          ) : (
            <td></td>
          )
        }
      }
    }

    const columns: Array<TreeListColumnProps | {}> = [
      {
        cell: CheckedCell(),
        title: t(''),
        width: '8%',
      },
      { field: 'name', title: t('Name'), editCell: TreeListTextEditor, filterCell: TreeListTextFilter, expandable: true, width: '20%' },
      { field: 'fileDisplayName', title: t('File Display Name'), filterCell: TreeListTextFilter, editCell: TreeListTextEditor, width: '15%' },
      { field: 'documentName', title: t('Document Name'), filterCell: TreeListTextFilter, editCell: TreeListTextEditor, width: '15%' },
      { field: 'documentUrl', title: t('URL'), editCell: TreeListTextEditor, width: '21%' },
      { cell: PreviewCell(), title: t('Preview'), width: '21%' },
    ]

    return (
      <>
        <style>{css}</style>

        {customerOffer?.offerServiceDocuments?.length === 0 && serviceDocuments?.length === 0 ? (
          <Loading />
        ) : (
          <TreeList
            expandField={expandField}
            subItemsField={subItemsField}
            onItemChange={onItemChange}
            onExpandChange={onExpandChange}
            filter={state.filter as []}
            data={processData()}
            onFilterChange={handleFilterChange}
            columns={columns}
          />
        )}

        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isReadOnly}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
            />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-light ml-auto mr-2" onClick={prevStep}>
              &laquo; {t('Prev')}
            </button>
            <button className="btn btn-primary ml-auto" onClick={nextStep}>
              {t('Next')} &raquo;
            </button>
          </div>
        </div>
      </>
    )
  })
)
export default ConventionalOfferDocument
