import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Table } from '@/components'
import * as _ from 'lodash'
import Store from './store'
import moment from 'moment'
import { IOfferDto } from '../conventional-offer/types'

interface IMonthOfferDetailPopupProps {
  CustomerOfferGridStore?: Store
  toggleModal: any
  axUnitId: string
  mannedAndTechServiceOffers?: string
}

const MonthOfferDetailPopup: FC<IMonthOfferDetailPopupProps> = inject('CustomerOfferGridStore')(
  observer((props: IMonthOfferDetailPopupProps) => {
    const { CustomerOfferGridStore: store, toggleModal, axUnitId, mannedAndTechServiceOffers } = props
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(true)
    const [offerList, setOfferList] = useState<IOfferDto[]>()

    useEffect(() => {
      store!.getMannedAndTechOffersAsList(axUnitId, mannedAndTechServiceOffers).then((tmpOfferList) => {
        setOfferList(tmpOfferList)
        setIsLoading(false)
      })
    }, [])

    const columns = useMemo(
      () => [
        {
          Header: () => {
            return (
              <div style={{ textAlign: 'center' }}>
                {t('Customer Offer')}
                <br />
                {'Id'}
              </div>
            )
          },
          id: 'customerOfferId',
          accessor: 'customerOfferId',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            return (
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`${row.customerOfferId}`}
                  onClick={async () => {
                    var win = window.open(window.location.origin + `/digital-offer/customer-offer/${row.customerOfferId}`, '_blank')
                    if (win != null) {
                      win.focus()
                    }
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
            )
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Accepted Date Range'),
          id: 'acceptedDateRange',
          accessor: 'acceptedDateRange',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            const cellText = !row.startDate ? '-' : moment(row.startDate).format('DD.MM.YYYY') + ' - ' + moment(row.endDate).format('DD.MM.YYYY')

            return <span dangerouslySetInnerHTML={{ __html: cellText }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Creator Name'),
          id: 'creatorUserName',
          accessor: 'creatorUserName',
          Cell: ({ row }: any) => {
            const { creatorUserName } = row.values

            return <span dangerouslySetInnerHTML={{ __html: creatorUserName }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Creation Time'),
          id: 'createDateTime',
          accessor: 'createDateTime',
          Cell: ({ row }: any) => {
            const { createDateTime } = row.values

            return <span dangerouslySetInnerHTML={{ __html: moment(createDateTime).format('DD.MM.YYYY - HH:mm') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Offer Type'),
          id: 'isEgc',
          accessor: 'isEgc',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)

            const offerType = row.isEgc ? t('Technology Offer') : t('Manned Services Offer')

            return (
              <div style={{ textAlign: 'center' }}>
                <Button
                  text={`${offerType}`}
                  onClick={async () => {
                    if (row.isEgc) {
                      var win = window.open(window.location.origin + `/digital-offer/technology-offer/${row.id}`, '_blank')
                      if (win != null) {
                        win.focus()
                      }
                    } else {
                      var win = window.open(window.location.origin + `/digital-offer/conventional-offer/${row.id}`, '_blank')
                      if (win != null) {
                        win.focus()
                      }
                    }
                  }}
                  className="btn btn-link text-warning"
                  style={{ fontSize: '12px' }}
                />
              </div>
            )
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Pricing'),
          id: 'versionDto',
          accessor: 'versionDto',
          Cell: ({ row }: any) => {
            const { versionDto } = row.values

            return <span dangerouslySetInnerHTML={{ __html: versionDto.name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    return (
      <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('Offers')}</ModalHeader>
        <ModalBody>
          <Table
            smallSize={true}
            showHeaderSearchInput={false}
            columns={columns}
            totalCount={(offerList || []).length}
            data={offerList || []}
            fetchData={() => {}}
            isLoading={isLoading}
            showNavigation={false}
          />
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />
        </ModalFooter>
      </Modal>
    )
  })
)

export default MonthOfferDetailPopup
