import storage from './clientStorage'

// export interface Role {
//   id: number
//   name: string
// }

export interface User {
  id: number
  username: string
  name: string
  email: string
  avatar: string
}

const getUser = (): User => {
  const _u = storage.getItem('_u')
  return _u ? JSON.parse(_u) : null
}

const setUser = (user: User) => {
  storage.removeItem('_u')
  storage.setItem('_u', JSON.stringify(user))
}

const purgeUser = () => storage.removeItem('_u')

export { getUser, setUser, purgeUser }
