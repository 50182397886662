import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@/components'
import * as _ from 'lodash'
import { Combobox } from 'react-widgets/cjs'
import { toast } from 'react-toastify'
import { ICustomerOfferRiskAnalysisDto, IIdNameDTO } from '../../../customer-offer-grid/types'
import Store from '../../store'

interface IView {
  CustomerOfferStore?: Store
  isEdit: boolean
  toggleModal: any
  isReadOnly: boolean
}

const View: FC<IView> = inject('CustomerOfferStore')(
  observer((props: IView) => {
    const { CustomerOfferStore: store, isEdit, toggleModal, isReadOnly } = props
    const { t } = useTranslation()

    const { degrees, categories, customerOffer } = store!

    const [newRisk, setNewRisk] = useState<ICustomerOfferRiskAnalysisDto | undefined>(undefined)

    const [id, setId] = useState(-1)

    const [degree, setDegree] = useState<IIdNameDTO>()
    const [category, setCategory] = useState<IIdNameDTO>()

    const [explanation, setExplanation] = useState<string | null>(null)
    const [isMainFocus, setIsMainFocus] = useState<boolean>()

    const selectedRisk = customerOffer?.riskAnalyzes?.find((x) => x.isSelected)

    useEffect(() => {
      if (selectedRisk) {
        setDegree(selectedRisk.degree)
        setCategory(selectedRisk.category)
        setExplanation(selectedRisk.explanation)
        setIsMainFocus(selectedRisk.isMainFocus)
      }
    }, [selectedRisk])

    const saveRisk = () => {
      if (degree?.name === '' || degree === undefined) {
        toast.error(t('Please select a degree'))
        return
      }

      if (category?.name === '' || category === undefined) {
        toast.error(t('Please select a category'))
        return
      }

      if (explanation === '' || explanation === undefined) {
        toast.error(t('Please enter an explanation'))
        return
      }

      if (isEdit) {
        if (selectedRisk) {
          selectedRisk.degree = degree
          selectedRisk.category = category
          selectedRisk.explanation = explanation
          selectedRisk.isMainFocus = isMainFocus!
        }
      } else {
        const dto: ICustomerOfferRiskAnalysisDto = {
          id: id,
          degree: degree!,
          category: category!,
          explanation: explanation!,
          isMainFocus: isMainFocus!,
          isSelected: false,
        }

        customerOffer?.riskAnalyzes?.push(dto)
      }
      customerOffer!.riskAnalyzes = [...customerOffer!.riskAnalyzes!] // to refresh grid
      toggleModal()
    }

    return (
      <>
        <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
          <ModalHeader toggle={toggleModal}>{isEdit ? t('Edit') : t('New Risk')}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <form className="needs-validation" noValidate>
                  <div className="form-row py-3">
                    <div className="form-group w-100">
                      <label htmlFor="degree" className="">
                        {t('Stage')}
                      </label>

                      <Combobox
                        id="id"
                        filter="contains"
                        data={degrees}
                        value={degree?.name}
                        textField="name"
                        dataKey="name"
                        name="degree"
                        onSelect={(val: any) => {
                          setDegree(val)
                        }}
                        disabled={isReadOnly}
                      />
                    </div>
                  </div>

                  <div className="form-row py-3">
                    <div className="form-group w-100">
                      <label htmlFor="category" className="">
                        {t('Category')}
                      </label>

                      <Combobox
                        id="id"
                        filter="contains"
                        data={categories}
                        value={category?.name}
                        textField="name"
                        dataKey="name"
                        name="category"
                        onSelect={(val: any) => {
                          setCategory(val)
                        }}
                        disabled={isReadOnly}
                      />
                    </div>
                  </div>

                  <div className="form-row py-3">
                    <div className="form-group w-100">
                      <label htmlFor="explanation" className="">
                        {t('Explanation')}
                      </label>

                      <textarea
                        name="explanation"
                        id="explanation"
                        className="form-control"
                        rows={2}
                        onChange={(event) => {
                          setExplanation(event.target.value)
                        }}
                        value={explanation!}
                        disabled={isReadOnly}
                      ></textarea>
                    </div>
                  </div>

                  <div className="d-none d-md-flex align-items-center">
                    <div className="custom-control custom-control-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isMainFocus}
                        id="isMainFocus"
                        onChange={(event) => {
                          setIsMainFocus(event.target.checked)
                        }}
                        disabled={isReadOnly}
                      />
                      <label className="cursor-pointer" htmlFor="isMainFocus">
                        <span style={{ textTransform: 'none', whiteSpace: 'nowrap' }}>{t('Is Main Focus')}</span>
                      </label>
                    </div>
                  </div>
                  <br />
                </form>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

            <Button
              className="btn btn-success float-right"
              onClick={saveRisk}
              icon={<i className={'far fa-save mr-2'}></i>}
              text={t('Save')}
              showConfirm={false}
              disabled={isReadOnly}
            />
          </ModalFooter>
        </Modal>
      </>
    )
  })
)

export default View
