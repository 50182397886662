import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Loading } from '@/components'
import { ICustomerOfferDto } from './types'
import Store from './store'
import { toast } from 'react-toastify'

interface IPdfTypePopupProps {
    CustomerOfferGridStore?: Store
    toggleModal: any
    selectedCustomerOffer: ICustomerOfferDto
    isEmailConfirmation: boolean
    isPdfPreview: boolean
    setDoesGridNeedARefresh?: any
}

const PdfTypePopup: FC<IPdfTypePopupProps> = inject('CustomerOfferGridStore')(
    observer((props: IPdfTypePopupProps) => {
        const {
            CustomerOfferGridStore: store,
            toggleModal,
            selectedCustomerOffer,
            isEmailConfirmation,
            isPdfPreview,
            setDoesGridNeedARefresh
        } = props
        const { t } = useTranslation()

        const [isLoading, setIsLoading] = useState(false)

        const [isFinancialPdf, setIsFinancialPdf] = useState(false)

        const save = async () => {
            setIsLoading(true)

            try {
                if (isEmailConfirmation) {
                    const result = await store!.sendMail(selectedCustomerOffer.id!, isFinancialPdf)

                    if (result) {
                        setDoesGridNeedARefresh!(true)
                        toast.success(t('Mail Send Successfully'))
                        toggleModal()
                    } else {
                        toast.error(t('Mail Send Failed'))
                        setIsLoading(false)
                    }
                }
                else {
                    if (isPdfPreview) {
                        const result = await store!.showPdf(selectedCustomerOffer.id!, isFinancialPdf)
                    }
                    else {
                        const result = await store!.getPdf(selectedCustomerOffer.id!, isFinancialPdf, selectedCustomerOffer.customerName)
                    }
                    toggleModal()
                }
            }
            catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }

        return (
            <>
                <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
                    <ModalHeader toggle={toggleModal}>{t('Select The PDF Type')}</ModalHeader>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <ModalBody>
                            <div className="d-flex" style={{ marginLeft: '20px' }}>
                                <div className="form-row py-3">
                                    {t('Please select the PDF type')}
                                </div>
                            </div>

                            <div className="d-flex" style={{ marginBottom: '10px', marginLeft: '0px!important' }}>
                                <div className="custom-control custom-radio px-5">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="typeRadio1"
                                        name="type"
                                        value="1"
                                        defaultChecked={isFinancialPdf}
                                        checked={isFinancialPdf}
                                        onChange={(value) => {
                                            if (value.target.checked) {
                                                setIsFinancialPdf(true)
                                            }
                                        }}
                                    />
                                    <label className="custom-control-label" htmlFor="typeRadio1">
                                        {t('Financial PDF')}
                                    </label>
                                </div>
                                <div className="custom-control custom-radio px-5">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="typeRadio0"
                                        name="type"
                                        value="0"
                                        checked={!isFinancialPdf}
                                        onChange={(value) => {
                                            if (value.target.checked) {
                                                setIsFinancialPdf(false)
                                            }
                                        }}
                                    />
                                    <label className="custom-control-label" htmlFor="typeRadio0">
                                        {t('Full PDF')}
                                    </label>
                                </div>
                            </div>

                            {isEmailConfirmation && <div className="d-flex" style={{ marginLeft: '20px' }}>
                                <div className="form-row py-3">
                                    {t('The email will be sent, do you confirm?')}
                                </div>
                            </div>}
                        </ModalBody>
                    )}
                    <ModalFooter>
                        <Button
                            className="btn btn-light mr-auto"
                            onClick={toggleModal}
                            icon={<i className="fas fa-times mr-2"></i>}
                            text={t('Close')}
                            disabled={isLoading}
                        />

                        <Button
                            className="btn btn-success float-right"
                            onClick={save}
                            icon={<i className={'far fa-save mr-2'}></i>}
                            text={t('Confirm')}
                            showConfirm={false}
                            disabled={isLoading}
                        />
                    </ModalFooter>
                </Modal>
            </>
        )

    })
)

export default PdfTypePopup
