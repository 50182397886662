import { action, makeAutoObservable } from 'mobx'

import env from '../../../../config/env'
import http from '../../../../services/http'
import IPagedRequest from '../../../../models/dto/fetch/IPagedRequest'
import IPagedResult from '../../../../models/dto/fetch/IPagedResult'
import fileDownload from 'js-file-download'

import qs from 'qs'
import { ICustomerOfferDto, IIdNameDTO, IProjectDto, IProjectUnitDetailedDto, IUnitOfferDetailDto, IUnitOfferDetailTotalDto } from './types'
import moment from 'moment'
import mime from 'mime-types'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'CustomerOfferGridStore'
  customerOffers!: IPagedResult<ICustomerOfferDto>
  unitOfferDetails!: IPagedResult<IUnitOfferDetailDto>
  totalCountDetails!: IUnitOfferDetailTotalDto
  regions: []
  branches: []
  projects: []
  units: []

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.customerOffers = {
      totalCount: 0,
      items: [],
      isLoaded: false,
    }

    this.unitOfferDetails = {
      totalCount: 0,
      items: [],
      isLoaded: false,
    }

    this.totalCountDetails = {
      isLoaded: false,
      totalUnitCount: 0,
      activeUnitCount: 0,
      unitWithoutOfferCount: 0,
      activeUnitWithoutOfferCount: 0,
      unitWithOfferCount: 0,
      unitWithIntersectingOffersCount: 0,
    }

    this.regions = []
    this.branches = []
    this.projects = []
    this.units = []

    this.getCustomerOffers = this.getCustomerOffers.bind(this)
    this.getUnitOfferDetails = this.getUnitOfferDetails.bind(this)

    this.getRegions = this.getRegions.bind(this)
    this.getBranches = this.getBranches.bind(this)
    this.getProjects = this.getProjects.bind(this)
    this.getUnits = this.getUnits.bind(this)
  }

  @action.bound async getCustomerOffers(params: IPagedRequest) {
    this.customerOffers.isLoaded = false
    const q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })

    const result: IPagedResult<ICustomerOfferDto> = await (await this.api.get(`/api/app/customer-offer/offers${q}`)).data

    this.customerOffers = { ...result, isLoaded: true }
  }

  @action.bound async sendMail(offerId: number, isFinancialPdf: boolean) {
    const result = await this.api.post(`/api/app/customer-offer/send?offerId=${offerId}&isFinancialPdf=${isFinancialPdf}`)

    return result.data
  }

  @action.bound async getPdf(offerId: number, isFinancialPdf: boolean, pdfName?: string) {
    await this.api
      .get(`/api/app/customer-offer/pdf-offer?offerId=${offerId}&isFinancialPdf=${isFinancialPdf}`, { responseType: 'blob' })
      .then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type })

        pdfName = pdfName == null || pdfName == '' ? 'adsiz' : pdfName.trim().replaceAll('\n', '').replaceAll('\r', '')

        fileDownload(blob, pdfName + '.pdf')
      })
  }

  @action.bound async showPdf(offerId: number, isFinancialPdf: boolean) {
    const result = await this.api
      .get(`/api/app/customer-offer/pdf-offer?offerId=${offerId}&isFinancialPdf=${isFinancialPdf}`, { responseType: 'blob' })
      .then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type })

        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      })

    // return result
  }

  @action.bound async deleteCustomerOffer(id: number) {
    await this.api.delete(`/api/app/customer-offer/${id}`)
    this.getCustomerOffers({ skipCount: 0, maxResultCount: 5 })
  }

  @action.bound async checkIntersectingOffers(dto: ICustomerOfferDto) {
    const result = await this.api.post(`/api/app/customer-offer/check-intersecting-offers`, dto)

    return result.data
  }

  @action.bound async accept(dto: ICustomerOfferDto) {
    const result = await this.api.post(`/api/app/customer-offer/accept`, dto)

    return result
  }

  @action.bound async changeServiceDates(dto: ICustomerOfferDto) {
    const result = await this.api.post(`/api/app/customer-offer/change-service-dates`, dto)

    return result
  }

  @action.bound async getCustomerOffer(offerId: number) {
    const result = await this.api.get(`/api/app/customer-offer/offer?offerId=${offerId}`)

    return result.data
  }

  @action.bound async getIsAdmin() {
    const result = await this.api.get(`/api/app/egc-offer/is-admin`)

    return result.data
  }

  @action.bound async getRegions() {
    this.regions = await (await this.api.get(`/api/app/region/all`)).data
  }

  @action.bound async getBranches(regionId?: number) {
    let params = '?'

    if (regionId != null && regionId > 0) {
      params += `regionId=${regionId}`
    }

    this.branches = await (await this.api.get(`/api/app/branch/all${params}`)).data
  }

  @action.bound async getProjects() {
    this.projects = await (await this.api.get(`/api/app/project/all`)).data
  }

  @action.bound async getUnits(axProjectId?: string, regionId?: number, branchId?: number) {
    let params = ''
    let isItFirstParam = true

    if (axProjectId != null && axProjectId != '') {
      params += isItFirstParam ? '?' : '&'
      params += `axProjectId=${axProjectId}`
      isItFirstParam = false
    }
    if (regionId != null && regionId > 0) {
      params += isItFirstParam ? '?' : '&'
      params += `regionId=${regionId}`
      isItFirstParam = false
    }
    if (branchId != null && branchId > 0) {
      params += isItFirstParam ? '?' : '&'
      params += `branchId=${branchId}`
      isItFirstParam = false
    }
    params += isItFirstParam ? '?' : '&'
    params += `checkPersonelExistence=false`
    params += `&checkNationalAuthorization=true`

    this.units = await (await this.api.get(`/api/app/unit/all${params}`)).data
  }

  @action.bound async getUnitOfferDetails(
    params: IPagedRequest,
    endDate: Date,
    showActiveUnits: boolean,
    showUnitsWithoutAcceptedOffers: boolean,
    showActiveUnitsWithoutAcceptedOffers: boolean,
    showUnitsWithAcceptedOffers: boolean,
    showUnitsWithIntersectingOffers: boolean,
    region?: IIdNameDTO,
    branch?: IIdNameDTO,
    project?: IProjectDto,
    unit?: IProjectUnitDetailedDto
  ) {
    this.unitOfferDetails.isLoaded = false
    this.totalCountDetails.isLoaded = false

    let q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })
    q += `&endDateStr=${moment(endDate).format('MM-YYYY')}`
    q += `&showActiveUnits=${showActiveUnits}`
    q += `&showUnitsWithoutAcceptedOffers=${showUnitsWithoutAcceptedOffers}`
    q += `&showActiveUnitsWithoutAcceptedOffers=${showActiveUnitsWithoutAcceptedOffers}`
    q += `&showUnitsWithAcceptedOffers=${showUnitsWithAcceptedOffers}`
    q += `&showUnitsWithIntersectingOffers=${showUnitsWithIntersectingOffers}`

    if (!!region && region.id != null && region.id > 0) {
      q += `&regionId=${region.id}`
    }
    if (!!branch && branch.id != null && branch.id > 0) {
      q += `&branchId=${branch.id}`
    }
    if (!!project && project.id != null && project.id != '') {
      q += `&axProjectId=${project.id}`
    }
    if (!!unit && unit.id != null && unit.id != '') {
      q += `&axUnitId=${unit.id}`
    }

    const result: IUnitOfferDetailTotalDto = await (await this.api.get(`/api/app/customer-offer/unit-offer-details${q}`)).data

    this.unitOfferDetails = { ...result.pagedGridData!, isLoaded: true }
    this.totalCountDetails = {
      isLoaded: true,
      totalUnitCount: result.totalUnitCount,
      activeUnitCount: result.activeUnitCount,
      unitWithoutOfferCount: result.unitWithoutOfferCount,
      activeUnitWithoutOfferCount: result.activeUnitWithoutOfferCount,
      unitWithOfferCount: result.unitWithOfferCount,
      unitWithIntersectingOffersCount: result.unitWithIntersectingOffersCount,
    }
  }

  @action.bound async getMannedAndTechOffersAsList(axUnitId: string, mannedAndTechServiceOffers?: string) {
    const params = `?axUnitId=${axUnitId}&mannedAndTechServiceOffers=${mannedAndTechServiceOffers || ''}`

    const result = await this.api.get(`/api/app/customer-offer/manned-and-tech-offers-as-list${params}`)

    return result.data
  }

  @action.bound async exportToExcel(
    filename: string,
    params: IPagedRequest,
    endDate: Date,
    showActiveUnits: boolean,
    showUnitsWithoutAcceptedOffers: boolean,
    showActiveUnitsWithoutAcceptedOffers: boolean,
    showUnitsWithAcceptedOffers: boolean,
    showUnitsWithIntersectingOffers: boolean,
    region?: IIdNameDTO,
    branch?: IIdNameDTO,
    project?: IProjectDto,
    unit?: IProjectUnitDetailedDto
  ) {
    let q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })
    q += `&endDateStr=${moment(endDate).format('MM-YYYY')}`
    q += `&showActiveUnits=${showActiveUnits}`
    q += `&showUnitsWithoutAcceptedOffers=${showUnitsWithoutAcceptedOffers}`
    q += `&showActiveUnitsWithoutAcceptedOffers=${showActiveUnitsWithoutAcceptedOffers}`
    q += `&showUnitsWithAcceptedOffers=${showUnitsWithAcceptedOffers}`
    q += `&showUnitsWithIntersectingOffers=${showUnitsWithIntersectingOffers}`

    if (!!region && region.id != null && region.id > 0) {
      q += `&regionId=${region.id}`
    }
    if (!!branch && branch.id != null && branch.id > 0) {
      q += `&branchId=${branch.id}`
    }
    if (!!project && project.id != null && project.id != '') {
      q += `&axProjectId=${project.id}`
    }
    if (!!unit && unit.id != null && unit.id != '') {
      q += `&axUnitId=${unit.id}`
    }

    await this.api.get(`/api/app/customer-offer/export-to-excel${q}`, { responseType: 'blob' }).then((response) => {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type })

      const suffix = moment().format('L').replace(/\./g, '_')
      const extension = mime.extension(type)

      filename = filename.replace(/\s/g, '_')
      filename = `${filename}_${suffix}.${extension}`

      fileDownload(blob, filename)
    })
  }
}

export default Store
