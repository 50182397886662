import SearchInput from './SearchInput'
import DefaultInput from './DefaultInput'
import CreditCardInput from './CreditCardInput'
import PhoneNumberInput from './PhoneNumberInput'

const Search = SearchInput
const Default = DefaultInput
const CreditCard = CreditCardInput
const PhoneNumber = PhoneNumberInput
// const DatePicker = DatePickerInput

export default { Search, Default, CreditCard, PhoneNumber }
