import React, { FC } from 'react'
import LoadingGif from './loading.gif'

interface IProps {
  width?: number | string
  height?: number | string
  className?: string
}

const Loading: FC<any> = (props: IProps) => (
  <div className={'d-block text-center py-4' + (props.className ?? '')}>
    <img src={LoadingGif} alt="Loading" width={props.height ?? 97} height={props.height ?? 52} />
  </div>
)

export default Loading
