import React, { FC, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'

import { IFileCreateDto } from '@/models/dto/file'
import { Button } from '@/components'

interface IStepProps {
  CustomerOfferStore?: any
  prevStep: any
  nextStep: any
  moduleName: string
  returnToOfferGrid: any
}

const Step: FC<IStepProps> = inject('CustomerOfferStore')(
  observer((props: IStepProps) => {
    const { CustomerOfferStore: store, prevStep, nextStep, moduleName, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { customerOffer } = store!

    const isReadOnly = !!customerOffer.isReadOnly

    const [isLoading, setIsLoading] = useState(false)

    return (
      <>
        <div className="row">
          <div className="form-group col-sm-6">
            <h6 className="w-100 font-weight-bold">
              {t('Documents')}
              <button
                type="button"
                className="btn btn-sm btn-primary shadow ml-3"
                disabled={isReadOnly || isLoading}
                onClick={(e) => {
                  e.preventDefault()

                  const input = document.createElement('input')
                  input.type = 'file'
                  input.multiple = true
                  input.onchange = async (event) => {
                    const { files } = event.target as any
                    const fileCreateDto: IFileCreateDto = {
                      files: files,
                      moduleName: moduleName,
                    }
                    setIsLoading(true)
                    store
                      ?.uploadFile(fileCreateDto)
                      .then((data: any) => {
                        setIsLoading(false)
                        customerOffer.fileList = _.concat(customerOffer.fileList, data.data)
                      })
                      .catch((error: any) => {
                        setIsLoading(false)
                      })
                  }
                  input.click()
                }}
              >
                <i className={'fas fa-' + (isLoading ? 'spinner fa-spin' : 'arrow-up') + ' mr-2'}></i>
                {isLoading ? '' : t('Upload')}
              </button>
            </h6>

            <div className="file-area">
              {customerOffer.fileList.length > 0 &&
                _.map(customerOffer.fileList, (file, i: number) => (
                  <span key={i}>
                    {file && (
                      <div className="input-group d-inline-flex w-auto mb-3">
                        <button
                          id="back-button"
                          className={'btn btn-light btn-shadow ' + (i > 0 ? 'ml-3' : '')}
                          onClick={(e) => {
                            e.preventDefault()
                            window.open(file.fileUrl, '_blank')
                          }}
                        >
                          {file.fileName}
                        </button>
                        <div className="input-group-append">
                          <button
                            className="btn btn-light btn-shadow"
                            onClick={(e) => {
                              e.preventDefault()
                              customerOffer.fileList = customerOffer.fileList.filter((file: any, index: number) => !_.isEqual(i, index))
                            }}
                            disabled={isReadOnly}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    )}
                  </span>
                ))}
              {!isReadOnly && customerOffer.fileList.length === 0 && <p>{t('Please attach document')}.</p>}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isReadOnly}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
              disabled={isLoading}
            />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-light ml-auto mr-2" onClick={prevStep}>
              &laquo; {t('Prev')}
            </button>
            <button className="btn btn-primary ml-auto" onClick={nextStep}>
              {t('Next')} &raquo;
            </button>
          </div>
        </div>
      </>
    )
  })
)

export default Step
