import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import * as _ from 'lodash'

const SearchBox: FC = (props: any) => {
  const { t } = useTranslation()

  const [activeSearch, setActiveSearch] = useState(false)

  const filterSidebarMenu = (value: string) => {
    value = _.trim(value)

    if (!value.length) {
      const _li = document.querySelectorAll('div.metismenu.vertical-nav-menu > ul.metismenu-container > li.metismenu-item')

      _.map(_li, (li) => {
        const chevron = li.querySelector('i.metismenu-state-icon')
        chevron?.classList.remove('rotate-minus-90')

        const subul = li.querySelector('ul.metismenu-container')
        subul?.classList.remove('visible')

        const _subli = subul?.querySelectorAll(':scope > li.metismenu-item') as NodeListOf<HTMLElement>

        _.map(_subli, (li) => {
          li.style.display = ''
        })
      })
    }

    const menus = document.querySelectorAll('div.metismenu.vertical-nav-menu')

    const filter = (ul: Element) => {
      let _result: boolean = false
      const list = ul.querySelectorAll(':scope > li.metismenu-item') as NodeListOf<HTMLElement>

      _.map(list, (li: HTMLElement) => {
        const a = li!.querySelector('a.metismenu-link')
        const textContent = a!.textContent

        const className = _.upperCase(textContent!).indexOf(_.upperCase(value)) > -1 ? '' : 'none'
        if (className !== 'none' && !_result) _result = true

        li!.style.display = className
      })

      return _result
    }

    _.map(menus, (menu) => {
      const ul = menu.querySelector(':scope > ul.metismenu-container')
      if (!ul) return

      const subul = ul.querySelector(':scope > li.metismenu-item > ul.metismenu-container')

      if (filter(ul)) {
        const list = subul?.querySelectorAll(':scope > li.metismenu-item') as NodeListOf<HTMLElement>

        _.map(list, (li: HTMLElement) => {
          li.style.display = ''
        })
      } else {
        if (!subul) return

        if (filter(subul)) {
          subul.classList.add('visible')

          const parentli = subul.closest('li.metismenu-item') as HTMLElement

          const chevron = parentli.querySelector('i.metismenu-state-icon')
          chevron?.classList.add('rotate-minus-90')

          parentli!.style.display = ''
        }
      }
    })
  }

  return (
    <div
      className={cx('search-wrapper', {
        active: activeSearch,
      })}
    >
      <div className="input-holder">
        <input
          type="text"
          className="search-input"
          placeholder={t('Type to search')}
          onChange={({ target: { value } }) => filterSidebarMenu(value)}
        />
        <button onClick={() => setActiveSearch(!activeSearch)} className="search-icon">
          <span />
        </button>
      </div>
      <button onClick={() => setActiveSearch(!activeSearch)} className="close" />
    </div>
  )
}

export default SearchBox
