import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '../../../../components'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { UserDto } from './types'
import { checkIfEmailValid } from '../format-check'

interface IView {
  UserStore?: Store
  data: UserDto
  isEdit: boolean
  toggleModal: any
}

const View: FC<IView> = inject('UserStore')(
  observer((props: IView) => {
    const { UserStore: store, data, isEdit, toggleModal } = props
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const [id, setId] = useState(-1)
    const [nameAlias, setNameAlias] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
      if (isEdit) {
        setId(data.id)
        setNameAlias(data.nameAlias ?? '')
        setUsername(data.username ?? '')
        setEmail(data.email ?? '')
      }
    }, [])

    const submit = async () => {
      if (nameAlias === undefined || nameAlias.toString().trim() === '') {
        toast.error(t('Please enter name'))
        return
      }

      if (username === undefined || username.toString().trim() === '') {
        toast.error(t('Please enter username'))
        return
      }

      if (email !== undefined && email !== '' && checkIfEmailValid(email) === false) {
        toast.error(t('EmailFormatError'))
        return
      }

      const dto = {
        id: id,
        nameAlias: nameAlias,
        username: username,
        email: email,
      } as UserDto

      await store!.saveUser(dto)
      toggleModal()
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="modal-md">
        <ModalHeader toggle={toggleModal}>{isEdit ? t('User Edit') : t('New User')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-row py-3">
                <div className="form-group w-100">
                  <label htmlFor="nameAlias" className="">
                    {t('Name')} <strong className="text-danger">* </strong>
                  </label>

                  <input
                    name="staffName"
                    id="staffName"
                    className="form-control"
                    onChange={(event) => {
                      setNameAlias(event.target.value)
                    }}
                    value={nameAlias}
                  />
                </div>
              </div>

              <div className="form-row py-3">
                <div className="form-group w-100">
                  <label htmlFor="nameAlias" className="">
                    {t('Username')} <strong className="text-danger">* </strong>
                  </label>

                  <input
                    name="username"
                    id="username"
                    className="form-control"
                    onChange={(event) => {
                      setUsername(event.target.value)
                    }}
                    value={username}
                  />
                </div>
              </div>

              <div className="form-row py-3">
                <div className="form-group w-100">
                  <label htmlFor="email" className="">
                    {t('Email')} <strong className="text-danger">* </strong>
                  </label>

                  <input
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={(event) => {
                      setEmail(event.target.value)
                    }}
                    value={email}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
