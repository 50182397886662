import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Loading } from '@/components'
import { ICustomerOfferDto } from './types'
import Store from './store'

interface IProjectUnitsPopupProps {
    CustomerOfferGridStore?: Store
    toggleModal: any
    selectedCustomerOffer: ICustomerOfferDto
}

const ProjectUnitsPopup: FC<IProjectUnitsPopupProps> = inject('CustomerOfferGridStore')(
    observer((props: IProjectUnitsPopupProps) => {
        const { CustomerOfferGridStore: store, toggleModal, selectedCustomerOffer } = props
        const { t } = useTranslation()

        const [isLoading, setIsLoading] = useState(false)

        const [customerOfferDetailed, setCustomerOfferDetailed] = useState<ICustomerOfferDto>()

        useEffect(() => {
            if (selectedCustomerOffer && selectedCustomerOffer.id != null && selectedCustomerOffer.id > 0) {
                setIsLoading(true)

                store!.getCustomerOffer(selectedCustomerOffer.id).then((customerOffer: ICustomerOfferDto) => {
                    setCustomerOfferDetailed({ ...customerOffer } as ICustomerOfferDto)

                    setIsLoading(false)
                })
            }
        }, [])

        return (
            <>
                <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
                    <ModalHeader toggle={toggleModal}>{t('Selected Units')}</ModalHeader>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <ModalBody>
                            <div className='row'>
                                <div className="col-md-12" style={{ display: 'flex' }}>
                                    <div className="col-md-4" style={{ margin: 'auto' }}>
                                        {t('Selected Project')}
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control py-4"
                                            value={customerOfferDetailed?.customerName}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {(customerOfferDetailed?.selectedUnits || []).length > 0 && <div className='row' style={{ marginTop: "20px" }}>
                                <div className="col-md-12" style={{ display: 'flex' }}>
                                    <div className="col-md-4" style={{ margin: 'auto' }}>
                                        {t('Selected Units')}
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <select multiple style={{ width: "100%", height: "193px" }} disabled={true}>
                                                {(customerOfferDetailed?.selectedUnits || []).map((tmpSelectedUnit) => {
                                                    return (
                                                        <option key={tmpSelectedUnit.id} value={tmpSelectedUnit.id}>
                                                            {tmpSelectedUnit.unitName}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </ModalBody>
                    )}
                    <ModalFooter>
                        <Button
                            className="btn btn-light mr-auto"
                            onClick={toggleModal}
                            icon={<i className="fas fa-times mr-2"></i>}
                            text={t('Close')}
                            disabled={isLoading}
                        />
                    </ModalFooter>
                </Modal>
            </>
        )

    })
)

export default ProjectUnitsPopup
