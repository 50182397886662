import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Flag from 'react-flagkit'
import * as _ from 'lodash'

const languages = [
  {
    code: 'TR',
    name: 'Türkçe',
    i18n: 'tr',
  },
  {
    code: 'US',
    name: 'English',
    i18n: 'en',
  },
  {
    code: 'DE',
    name: 'Deutsch',
    i18n: 'de',
  },
]

const LanguageSelection: FC = (props: any) => {
  const { i18n, t } = useTranslation()
  let initialLanguage = _.find(languages, (l: any) => l.i18n === i18n.language)
  initialLanguage = initialLanguage ? initialLanguage : languages[0]

  const [country, setCountry] = useState(initialLanguage.code)

  return (
    <UncontrolledDropdown>
      <DropdownToggle className="p-0 mr-2" color="link">
        <div className="icon-wrapper icon-wrapper-alt rounded-circle">
          <div className="icon-wrapper-bg bg-focus" />
          <div className="language-icon">
            <Flag className="mr-3 opacity-8" country={country} size="40" />
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right>
        <div className="dropdown-menu-header">
          <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
            <div className="menu-header-image opacity-05" />
            <div className="menu-header-content text-center text-white">
              <h6 className="menu-header-subtitle mt-0">{t('Choose Language')}</h6>
            </div>
          </div>
        </div>
        {_.map(languages, (lang) => (
          <DropdownItem
            onClick={() => {
              setCountry(lang.code)
              i18n.changeLanguage(_.lowerCase(lang.i18n))
              window.location.reload()
            }}
            active={lang.code === country}
            key={lang.code}
          >
            <Flag className="mr-3 opacity-8" country={lang.code} />
            {lang.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default LanguageSelection
