import React, { FC, useState } from 'react'
import { inject, observer } from 'mobx-react'
import Store from './store'
import '@progress/kendo-ui'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import CustomerOfferGrid from './customer-offer-grid'
import UnitOfferDetailGrid from './unit-offer-detail-grid'

interface IPage {
  CustomerOfferGridStore?: Store
  t: any
}

const Page: FC<IPage> = inject('CustomerOfferGridStore')(
  observer((props: IPage) => {
    const { CustomerOfferGridStore: store, t } = props

    const [activeTabIndex, setActiveTabIndex] = useState(0)

    return (
      <>
        <div className="card-tabs">
          <div className="card-header d-flex align-items-center justify-content-between">
            <Nav className="w-auto">
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTabIndex(0)
                  }}
                  active={activeTabIndex === 0}
                >
                  {t('Customer Offers')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTabIndex(1)
                  }}
                  active={activeTabIndex === 1}
                >
                  {t('Details')}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <TabContent activeTab={activeTabIndex}>
          <TabPane tabId={0}>
            <CustomerOfferGrid CustomerOfferGridStore={store} t={t} />
          </TabPane>
          <TabPane tabId={1}>
            <UnitOfferDetailGrid CustomerOfferGridStore={store} t={t} />
          </TabPane>
        </TabContent>
      </>
    )
  })
)

export default Page
