import * as React from 'react'
import { Button } from '@/components'

export default function MyCommandCell(add: any, edit: any, remove: any) {
  // eslint-disable-next-line react/display-name
  return class extends React.Component<any> {
    render() {
      const { dataItem } = this.props

      return (
        <td>
          {dataItem.canAdd && (
            <Button
              icon={<i className={'fas fa-plus'}></i>}
              onClick={async () => {
                add(dataItem)
              }}
              className="btn btn-link text-success"
            />
          )}
          {dataItem.canEdit && (
            <Button
              icon={<i className={'fas fa-pen'}></i>}
              onClick={async () => {
                edit(dataItem)
              }}
              className="btn btn-link text-warning"
            />
          )}
          {dataItem.canRemove && (
            <Button
              icon={<i className={'fas fa-trash'}></i>}
              onClick={async () => {
                remove(dataItem)
              }}
              className="btn btn-link text-danger"
            />
          )}
        </td>
      )
    }
  }
}
