import React, { FC, useState, ChangeEvent, KeyboardEvent, ClipboardEvent, ChangeEventHandler, InputHTMLAttributes } from 'react'
import Cleave from 'cleave.js/react'

const keyRegex = /^(Backspace)|((Numpad|Digit)[0-9]{1})/i

interface IPhoneNumber extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const PhoneNumber: FC<IPhoneNumber> = (props: IPhoneNumber) => {
  const { onChange, placeholder = '530 999 00 00', ...rest } = props
  const [changeEvent, setChangeEvent] = useState<ChangeEvent<HTMLInputElement>>()

  let clockTimeout: any = 0

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist()

    if (clockTimeout !== 0) clearTimeout(clockTimeout)
    setChangeEvent(event)

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {}, 1000)
  }

  const keyboardHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (clockTimeout !== 0) clearTimeout(clockTimeout)
    if (!keyRegex.test(event.code)) event.preventDefault()

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {
      keyRegex.test(event.code) && onChange && onChange(changeEvent!)
    }, 1000)
  }

  const clipboardHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    if (clockTimeout !== 0) clearTimeout(clockTimeout)

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {
      onChange && onChange(changeEvent!)
    }, 1000)
  }

  return (
    <Cleave
      {...rest}
      placeholder={placeholder}
      options={{ blocks: [3, 3, 2, 2] }}
      onChange={changeHandler}
      onKeyUp={keyboardHandler}
      onKeyPress={keyboardHandler}
      onPaste={clipboardHandler}
    />
  )
}

export default PhoneNumber
