import * as React from 'react'
import { Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import qs from 'qs'
import * as _ from 'lodash'

// import authenticate from '../../services/authentication'

import Layout from '../Layout'

const ProtectedRoute = (props: any) => {
  const { location, history, path, component: Component, permission, render, useLayout = true, ...rest } = props

  const query = _.replace(location!.search, '?', '')
  let { uid, pwd } = qs.parse(query)

  if (uid || pwd) history.push(location!.pathname)

  if (typeof Component === 'undefined' && typeof render !== 'undefined') return render(props)

  if (!useLayout) return <Route path={path} component={() => <Component {...rest} />} />

  return <Route path={path} component={() => <Layout component={Component} {...rest} />} />
}

export default withTranslation()(ProtectedRoute)
