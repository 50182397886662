import { lazy } from 'react'

import IRoute from '../../models/dto/route/IRoute'

const index = 1
export { index }

const routes: IRoute[] = [
  {
    path: '/user/user-management',
    title: 'User Management',
    icon: 'pe-7s-user',
    index: 1,
    showInMenu: true,
    permission: 'UserManagement',
    single: true,
    exact: false,
    component: lazy(() => import('./pages/user-management')),
  },
]

export default routes
