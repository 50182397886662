import React, { useState, FC, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import * as _ from 'lodash'
import { Button, ConfirmModal, Table } from '../../../../../components'
import Store from './store'
import View from './view'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'

import { ConventionalOfferWorkingRetiredEmployerShare } from '../types'

interface IPage {
  ConventionalOfferWorkingRetiredEmployerShareStore?: Store
  versionId: number
  disabled: boolean
}

const Page: FC<IPage> = inject('ConventionalOfferWorkingRetiredEmployerShareStore')(
  observer((props: IPage) => {
    const { ConventionalOfferWorkingRetiredEmployerShareStore: store, versionId, disabled } = props
    const { records } = store!
    const { t } = useTranslation()
    const [selectedRow, selectRow] = useState<ConventionalOfferWorkingRetiredEmployerShare>()

    const [edit, setEdit] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modal, setModal] = useState(false)

    const toggleModal = () => setModal(!modal)

    const detailChooseDeletionMessage = () => {
      if (!selectedRow) {
        return <p>{t('Unknown Error')}</p>
      }

      return (
        <>
          <p>
            {t('Name')}: {selectedRow.name}
          </p>
        </>
      )
    }

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                <Button
                  icon={<i className="fas fa-pen"></i>}
                  tooltipText={t('Edit')}
                  disabled={disabled}
                  onClick={async () => {
                    setEdit(true)
                    selectRow(row)
                    setModal(true)
                  }}
                  className="btn btn-link text-warning"
                />
                <Button
                  icon={<i className="fas fa-trash"></i>}
                  tooltipText={t('Delete')}
                  disabled={disabled}
                  onClick={async () => {
                    selectRow(row)
                    setShowDeleteModal(true)
                  }}
                  className="btn btn-link text-danger"
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Is Working?'),
          id: 'isWorking',
          accessor: 'isWorking',
          Cell: ({ row }: any) => {
            const { isWorking } = row.values

            return <span dangerouslySetInnerHTML={{ __html: isWorking === true ? t('Employee') : t('Retired') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Employer Share'),
          id: 'employerShare',
          accessor: 'employerShare',
          Cell: ({ row }: any) => {
            const { employerShare } = row.values

            return <span dangerouslySetInnerHTML={{ __html: `%${numeral(employerShare).format('0.0[,]00')}` }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Promotion Bonus Multiplier'),
          id: 'promotionBonusMultiplier',
          accessor: 'promotionBonusMultiplier',
          Cell: ({ row }: any) => {
            const { promotionBonusMultiplier } = row.values

            return <span dangerouslySetInnerHTML={{ __html: `${numeral(promotionBonusMultiplier).format('0.0[,]00')}` }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    return (
      <>
        <div className="card-body">
          <div className="card-header">
            <div className="row">{t('Working/Retired Employer Share')}</div>
            <div className="btn-actions-pane-right actions-icon-btn">
              <button
                className="btn btn-primary btn-shadow flex-direction float-right"
                disabled={disabled}
                onClick={() => {
                  setEdit(false)
                  selectRow(undefined)
                  setModal(true)
                }}
              >
                <i className="fas fa-plus mr-2"></i>
                {t('New Record')}
              </button>
            </div>
          </div>

          <br></br>

          <Table
            smallSize={true}
            columns={columns}
            totalCount={records.totalCount}
            data={records.items}
            fetchData={(x) => store?.getRecords(x, versionId)}
            isLoading={!records.isLoaded}
          />
        </div>

        {showDeleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={showDeleteModal}
            toggleModal={() => setShowDeleteModal(!showDeleteModal)}
            onConfirm={async () => {
              await store!.delete(selectedRow!.id, versionId)
              setShowDeleteModal(false)
            }}
          />
        )}

        {modal && <View data={selectedRow} isShow={modal} isEdit={edit} toggleModal={toggleModal} versionId={versionId} />}
      </>
    )
  })
)

export default Page
