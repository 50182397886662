const v4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      // eslint-disable-next-line
      v = c == 'x' ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })

const uuid = { v4 }

export default uuid
