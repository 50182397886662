import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@/components'
import { toast } from 'react-toastify'
import { ICustomerOfferDutyPostDto } from '../../../customer-offer-grid/types'
import { TimePicker } from 'antd'
import moment from 'moment'
import Store from '../../store'
import { Combobox } from 'react-widgets/cjs'
import { IShiftDto } from '../../../conventional-offer-admin/types'

interface IEditDutyPostPopupProps {
  CustomerOfferStore?: Store
  toggleModal: any
  tmpDutyPostList: ICustomerOfferDutyPostDto[]
  setTmpDutyPostList: any
  isReadOnly: boolean
}

const EditDutyPostPopup: FC<IEditDutyPostPopupProps> = inject('CustomerOfferStore')(
  observer((props: IEditDutyPostPopupProps) => {
    const { CustomerOfferStore: store, toggleModal, setTmpDutyPostList, tmpDutyPostList, isReadOnly } = props
    const { t } = useTranslation()

    const { shifts } = store!

    const [dutyPost, setDutyPost] = useState<ICustomerOfferDutyPostDto>({} as ICustomerOfferDutyPostDto)
    const [isEdit, setIsEdit] = useState(false)

    const [startHour, setStartHour] = useState<Date>()
    const [endHour, setEndHour] = useState<Date>()

    const [shiftDto, setShiftDto] = useState<IShiftDto>()

    useEffect(() => {
      store!.getShifts().then(() => {
        const selected = tmpDutyPostList.find((x: any) => x.isSelected === true)

        if (selected) {
          setDutyPost({ ...selected })
          setShiftDto({ ...selected.shiftDto })
          setStartHour(moment(typeof selected.startHour === 'string' ? new Date(selected.startHour) : selected.startHour))
          setEndHour(moment(typeof selected.endHour === 'string' ? new Date(selected.endHour) : selected.endHour))
          setIsEdit(true)
        }
      })
    }, [])

    const save = async () => {
      if (!dutyPost.name || !shiftDto || shiftDto.id == 0 || !startHour || !endHour || !dutyPost.employeeCount) {
        toast.error(t('Please fill in all fields'))
        return
      }

      if (dutyPost.name.length > 200) {
        toast.error(t('Name must be shorter than 200 characters'))
        return
      }

      dutyPost.shiftDto = shiftDto
      dutyPost.startHour = moment(startHour).toDate()
      dutyPost.endHour = moment(endHour).toDate()

      if (isEdit) {
        const copyTmpDutyPostList = [...tmpDutyPostList]

        const index = copyTmpDutyPostList.findIndex((x: any) => x.isSelected === true)

        if (index != -1) {
          const copySelected = {
            ...copyTmpDutyPostList[index],
            name: dutyPost.name,
            employeeCount: dutyPost.employeeCount,
            shiftDto: dutyPost.shiftDto,
            startHour: dutyPost.startHour,
            endHour: dutyPost.endHour,
          } as ICustomerOfferDutyPostDto

          copyTmpDutyPostList.splice(index, 1, copySelected)
          setTmpDutyPostList([...copyTmpDutyPostList])
        }
      } else {
        let newId = 1

        if (tmpDutyPostList.length > 0) {
          const maxId = Math.max(...tmpDutyPostList.map((x: any) => x.id))
          newId = maxId + 1
        }

        dutyPost.id = newId

        setTmpDutyPostList([...tmpDutyPostList, dutyPost])
      }

      toggleModal()
    }

    return (
      <>
        <Modal isOpen={true} toggle={toggleModal} backdrop={'static'} keyboard={false} className="modal-lg">
          <ModalHeader toggle={toggleModal}>{t('Edit Duty Post')}</ModalHeader>
          <ModalBody>
            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Name')}
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control py-4"
                    value={dutyPost.name}
                    onChange={(event) => {
                      setDutyPost({
                        ...dutyPost,
                        name: event.target.value,
                      } as ICustomerOfferDutyPostDto)
                    }}
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Shift')}
                </div>
                <div className="col-md-8">
                  <Combobox
                    filter="contains"
                    data={shifts}
                    value={shiftDto?.name}
                    textField="name"
                    onSelect={(val: any) => {
                      setShiftDto({ ...val })
                    }}
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Shift Start Hour')}
                </div>
                <div className="col-md-8">
                  <TimePicker
                    value={startHour}
                    format={'HH:mm'}
                    autoComplete="off"
                    showHour={true}
                    showMinute={true}
                    minuteStep={1}
                    onChange={(selected) => setStartHour(selected)}
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Shift End Hour')}
                </div>
                <div className="col-md-8">
                  <TimePicker
                    value={endHour}
                    format={'HH:mm'}
                    autoComplete="off"
                    showHour={true}
                    showMinute={true}
                    minuteStep={1}
                    onChange={(selected) => setEndHour(selected)}
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Employee Count')}
                </div>
                <div className="col-md-8">
                  <input
                    type="number"
                    className="form-control py-4"
                    value={dutyPost.employeeCount}
                    onChange={(event) => {
                      setDutyPost({
                        ...dutyPost,
                        employeeCount: Number(event.target.value),
                      } as ICustomerOfferDutyPostDto)
                    }}
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

            <Button
              className="btn btn-success float-right"
              onClick={save}
              icon={<i className={'far fa-save mr-2'}></i>}
              text={t('Save')}
              showConfirm={false}
              disabled={isReadOnly}
            />
          </ModalFooter>
        </Modal>
      </>
    )
  })
)

export default EditDutyPostPopup
