import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@/components'
import Store from '../../store'
import { ICustomerOfferDetectionAndSolutionDto } from '../../../customer-offer-grid/types'
import { toast } from 'react-toastify'

interface IDetectionSolutionPopupProps {
    CustomerOfferStore?: Store
    toggleModal: any
    isReadOnly: boolean
}

const DetectionSolutionPopup: FC<IDetectionSolutionPopupProps> = inject('CustomerOfferStore')(
    observer((props: IDetectionSolutionPopupProps) => {
        const { CustomerOfferStore: store, toggleModal, isReadOnly } = props
        const { t } = useTranslation()

        const { customerOffer } = store!

        const [detectionSolution, setDetectionSolution] = useState<ICustomerOfferDetectionAndSolutionDto>()
        const [isEdit, setIsEdit] = useState(false)

        useEffect(() => {
            const selected = customerOffer?.detectionsAndSolutions?.find((x: any) => x.isSelected)

            if (selected) {
                setDetectionSolution(selected)
                setIsEdit(true)
            }
            else {
                setDetectionSolution({ detectionOrSolution: false, customerDigitalOfferId: customerOffer?.id } as ICustomerOfferDetectionAndSolutionDto)
            }
        }, [])

        const save = async () => {
            if (!detectionSolution || !detectionSolution.explanation) {
                toast.error(t('You must enter an explanation'))
                return
            }

            if (detectionSolution?.detectionOrSolution == undefined) {
                toast.error(t('Please choose between detection and solution'))
                return
            }

            if (isEdit) {
                const selected = customerOffer!.detectionsAndSolutions?.find((x: any) => x.isSelected)

                if (selected) {
                    selected.detectionOrSolution = detectionSolution.detectionOrSolution
                    selected.explanation = detectionSolution.explanation

                    customerOffer!.detectionsAndSolutions = [...customerOffer!.detectionsAndSolutions!] // to refresh grid
                }
            }
            else {
                let newId = 1

                if (customerOffer!.detectionsAndSolutions!.length > 0) {
                    const maxId = Math.max(...customerOffer!.detectionsAndSolutions!.map((x: any) => x.id))
                    newId = maxId + 1
                }

                detectionSolution.id = newId

                customerOffer!.detectionsAndSolutions = [...customerOffer!.detectionsAndSolutions!, detectionSolution]
            }

            toggleModal()
        }

        return (
            <>
                <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
                    <ModalHeader toggle={toggleModal}>{t('Edit') + " " + t('Detection') + "/" + t('Solution')}</ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-center" style={{ marginBottom: '20px' }}>
                            <div className="custom-control custom-radio px-5">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="typeRadio0"
                                    name="type"
                                    value="0"
                                    defaultChecked={!detectionSolution?.detectionOrSolution}
                                    checked={!detectionSolution?.detectionOrSolution}
                                    onChange={(value) => {
                                        if (value.target.checked) {
                                            setDetectionSolution({
                                                ...(detectionSolution || {})
                                                , detectionOrSolution: false
                                            } as ICustomerOfferDetectionAndSolutionDto)
                                        }
                                    }}
                                    disabled={isReadOnly}
                                />
                                <label className="custom-control-label" htmlFor="typeRadio0">
                                    {t('Detection')}
                                </label>
                            </div>
                            <div className="custom-control custom-radio px-5">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="typeRadio1"
                                    name="type"
                                    value="1"
                                    checked={detectionSolution?.detectionOrSolution}
                                    onChange={(value) => {
                                        if (value.target.checked) {
                                            setDetectionSolution({
                                                ...(detectionSolution || {})
                                                , detectionOrSolution: true
                                            } as ICustomerOfferDetectionAndSolutionDto)
                                        }
                                    }}
                                    disabled={isReadOnly}
                                />
                                <label className="custom-control-label" htmlFor="typeRadio1">
                                    {t('Solution')}
                                </label>
                            </div>
                            <div>
                                <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <form className="needs-validation" noValidate>
                                    <div className="form-row py-3">
                                        <div className="form-group w-100" style={{ paddingTop: '0 !important', display: 'flex' }}>
                                            <div className="col-md-4" style={{ margin: 'auto' }}>
                                                {t('Explanation')}
                                            </div>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    className="form-control py-4"
                                                    value={detectionSolution?.explanation}
                                                    onChange={(event) => {
                                                        setDetectionSolution({
                                                            ...(detectionSolution || {})
                                                            , explanation: event.target.value
                                                        } as ICustomerOfferDetectionAndSolutionDto)
                                                    }}
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

                        <Button
                            className="btn btn-success float-right"
                            onClick={save}
                            icon={<i className={'far fa-save mr-2'}></i>}
                            text={t('Save')}
                            showConfirm={false}
                            disabled={isReadOnly}
                        />
                    </ModalFooter>
                </Modal>
            </>
        )
    })
)

export default DetectionSolutionPopup
