import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

interface IDefaultInputProps {
  id: any
  text: string
  control?: any
  defaultValue?: any
  onChange?: any
  errorMessage?: any
  errorKey?: any
  className?: any
  disabled?: boolean
  selectRef?: any
  errors?: any
  isRequired?: boolean,
  type?: string,
  textClassName?: string
}

const Input: FC<IDefaultInputProps> = (props: IDefaultInputProps) => {
  const { id, text, errors, selectRef, defaultValue, disabled, errorMessage, control, className, onChange, isRequired=true, type = 'text', textClassName = "" } = props
  const { t } = useTranslation()
  return (
    <div className={className}>
      <div className="form-group">
        <label htmlFor={id} className={textClassName}>
          {t(text)}
          { isRequired && <strong className="text-danger">*</strong>}
        </label>
        <input
          disabled={disabled}
          id={id}
          className={cx('form-control', {
            'is-invalid': errors[id],
          })}
          type={type}
          name={id}
          ref={selectRef}
          defaultValue={defaultValue}
          onChange={onChange}
        />
        {/* <Controller
          as={
            <input
              disabled={disabled}
              id={id}
              className={cx('form-control', {
                'is-invalid': errors[id],
              })}
              type="text"
              placeholder={t(text)}
            />
          }
          name={id}
          control={control}
          defaultValue={defaultValue}
          onChange={onChange}
        /> */}
        {errors && errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
      </div>
    </div>
  )
}

export default Input
