import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

interface IPageTitleProps {
  icon?: string
  heading: string
  subheading?: string
  changeFullScreen?: any
  fullScreenButton?: string
}

const PageTitle: FC<IPageTitleProps> = (props: IPageTitleProps) => {
  const { icon, heading, subheading, changeFullScreen, fullScreenButton } = props
  const { t } = useTranslation()

  return (
    <div className="app-inner-layout__header">
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div
              className={cx('page-title-icon', {
                'd-none': !icon,
              })}
            >
              <i className={cx(icon, 'icon-gradient', 'bg-sunny-morning')} />
            </div>
            <>
              {t(heading)}
              <div
                className={cx('page-title-subheading', {
                  'd-none': !subheading,
                })}
              >
                {subheading}
              </div>
            </>
          </div>

          <div className="page-title-actions"></div>
        </div>
      </div>
    </div>
  )
}
export default PageTitle
