import React, { useState, useEffect, FC, useRef, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import Store from './store'

import { Button, ConfirmModal, Loading, Table } from '@/components'
import '@progress/kendo-ui'
import $ from 'jquery'
import {
  ICircularFormulaDTO,
  ISpreadsheetDTO,
  IOfferDto,
  IOfferParameterDto,
  ISheetScrollPositionDto,
  NumberKeyNumberValueDto,
  StringKeyNumberValueDto,
} from './types'
import moment from 'moment'
import { Collapse } from 'antd'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ParameterPopup from './parameter-popup'
import 'utils/extensions'
import { ConventionalOfferVersionDto } from '../conventional-offer-admin/types'

interface IPage {
  ConventionalOfferStore?: Store
  t: any
}

const { Panel } = Collapse

const formatTL = new Intl.NumberFormat('tr-TR')

const excelLetterArr = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const getExcelColLetterFromIndex = (colIdx: number) => {
  // zero based index
  var res = ''

  var quotient = Math.trunc(colIdx / 26)

  if (quotient >= 1) {
    res += getExcelColLetterFromIndex(quotient - 1)
  }

  return res + excelLetterArr[Math.trunc(colIdx % 26)]
}

// hardcoded
const totalSheetCount = 5
const summarySheetName = 'Summary'
const summarySheetIdx = 0
const parametricDataSheetName = 'Parametric Data'
const parametricDataSheetIdx = 1
const personelSheetName = 'Personnel'
const personelSheetIdx = 2
const icmalSheetName = 'Synopsis'
const icmalSheetIdx = 3
const digerSheetName = 'Other'
const digerSheetIdx = 4

let parametricSheetErrorCheckRowIdxList = [] as number[]
let personelSheetToggleHiddenRowIdxList = [] as number[]

let asgariUcretBrut = 0
let minimumSigortaBedeli = 0
let minimumKiyafetBedeli = 0
let minimumTechizatBedeli = 0
let minimumIsgBedeli = 0
let fazlaMesaiSaatleri = [] as StringKeyNumberValueDto[]
let senelikIzinGunSayiVeKatsayiList = [] as NumberKeyNumberValueDto[]

let regionsMinimumGrossWageRowIdx = 0
let isThereARoadBlockerOrACopperCableInStationRowIdx = 0
let costOfClothingRowIdx = 0
let costOfEquipmentRowIdx = 0
let costOfWorkHealthAndSafetyRowIdx = 0

let pozisyonRowIdx = 0
let brutUcretRowIdx = 0
let brutSartliPerformansPrimiRowIdx = 0
let workingHoursRowIdx = 0
let yolAylikVeriGirisiRowIdx = 0
let yemekOdemeSekliGunlukAylikRowIdx = 0
let yemekAylikVeriGirisiRowIdx = 0
let fazlaMesaiSaatiRowIdx = 0
let senelikIzinGunSayisiRowIdx = 0
let ihbarTazminatiSureleriRowIdx = 0

let lastSheetName = ''
let sheetScrollPositionsArr = [] as ISheetScrollPositionDto[]

const sheetScrollingHelper = (sheetName: string) => {
  const lastSheetIdx = sheetScrollPositionsArr.findIndex((x) => x.sheetName == lastSheetName)
  const curSheetIdx = sheetScrollPositionsArr.findIndex((x) => x.sheetName == sheetName)
  if (lastSheetIdx == -1 || curSheetIdx == -1) return
  lastSheetName = sheetName
  let lastSheetScrollPositions = sheetScrollPositionsArr[lastSheetIdx]
  let curSheetScrollPositions = sheetScrollPositionsArr[curSheetIdx]
  const scrollableDiv = $('.k-spreadsheet-scroller')
  lastSheetScrollPositions.scrollLeft = scrollableDiv.scrollLeft() || 0
  lastSheetScrollPositions.scrollTop = scrollableDiv.scrollTop() || 0
  scrollableDiv.scrollLeft(curSheetScrollPositions.scrollLeft)
  scrollableDiv.scrollTop(curSheetScrollPositions.scrollTop)
}

const Page: FC<IPage> = inject('ConventionalOfferStore')(
  observer((props: IPage) => {
    const { ConventionalOfferStore: store, t } = props

    const { offerId: paramOfferId }: any = useParams()

    const { offers } = store!

    KendoLicensing.setScriptKey(
      '141j044b041h541j4i1d542e581e4i1i4g260i2k0f2e0b2f0d2j0d2k07300j1e603j09214c1g52254c2b5j30572j541c4g265c435i3b5k3f5j395f395i335h424i2h123d551g4c24511g572f562g614958315e355d33593358395748532753314e3i4i004e012b002a14613b5k1a28062c054g3a1j3c201a28014g3k5144534654414c424g375139245i033b0g1j03511j312e3123521e15412j0d5f2a4i0k3k1k4k444h3815'
    )

    const [collapseActiveKey, setCollapseActiveKey] = useState(['1'])

    const [selectedOfferRow, setSelectedOfferRow] = useState<IOfferDto>()

    const [deleteModal, setDeleteModal] = useState(false)
    const toggleDeleteModal = () => setDeleteModal(!deleteModal)

    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false)

    const [instance, setInstance] = useState<any>(null)
    const [data, setData] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [initalLoadFlag, setInitialLoadFlag] = useState(true)

    const usePrevious = (value: any) => {
      const ref = useRef()
      useEffect(() => {
        ref.current = value
      })
      return ref.current
    }

    const [savedOfferId, setSavedOfferId] = useState<number>()
    const [templateOffer, setTemplateOffer] = useState<IOfferDto>()
    const [offerParamDto, setOfferParamDto] = useState<IOfferParameterDto>()
    const previousOfferParamDto = usePrevious(offerParamDto)

    const [showParameterPopup, setShowParameterPopup] = useState(false)
    const [isParameterPopupConfirmed, setIsParameterPopupConfirmed] = useState(false)
    const [version, setVersion] = useState<ConventionalOfferVersionDto>()
    const [isItForExistingCustomer, setIsItForExistingCustomer] = useState(false)

    useEffect(() => {
      if (isParameterPopupConfirmed) {
        setIsParameterPopupConfirmed(false)
        store?.unloadSpreadsheet()
        setCollapseActiveKey([])
        setOfferParamDto({
          filterOffer: selectedOfferRow,
          versionDto: { ...version },
          templateOffer: templateOffer,
          isItForExistingCustomer: isItForExistingCustomer,
        } as IOfferParameterDto)
      }
    }, [isParameterPopupConfirmed])

    useEffect(() => {
      if (!store?.spreadsheet.isLoaded) return

      setData({ ...store?.spreadsheet.spreadsheet })
      setInstance($('#spreadsheet'))
    }, [store?.spreadsheet.isLoaded])

    useEffect(() => {
      if (!instance) return

      // kendo.culture("tr-TR")

      if (initalLoadFlag) {
        instance.kendoSpreadsheet({
          toolbar: false,
          sheets: [
            { columns: [{ width: 400 }], rows: [{ height: 20, cells: [{ value: t('Please create a new offer or select an existing offer') }] }] },
          ],
        })

        if (paramOfferId) {
          store?.getSingleOffer(Number(paramOfferId)).then((paramOffer: IOfferDto) => {
            setSelectedOfferRow(paramOffer)
            if (paramOffer.isReadOnly === true) {
              store?.unloadSpreadsheet()
              setCollapseActiveKey([])
              setOfferParamDto({
                filterOffer: paramOffer,
                versionDto: paramOffer.versionDto,
                isItForExistingCustomer: paramOffer.isItForExistingCustomer,
              } as IOfferParameterDto)
            } else {
              setShowParameterPopup(true)
            }
          })
        }
      } else {
        const spreadsheetDto = store?.spreadsheet.spreadsheet

        if (spreadsheetDto && spreadsheetDto.sheets.length > 0) {
          lastSheetName = spreadsheetDto.sheets[0].name
          sheetScrollPositionsArr = spreadsheetDto.sheets.map(
            (x) =>
              ({
                sheetName: x.name,
                scrollTop: 0,
                scrollLeft: 0,
              } as ISheetScrollPositionDto)
          )
          personelSheetToggleHiddenRowIdxList = spreadsheetDto.personelSheetToggleHiddenRowIdxList || []
          parametricSheetErrorCheckRowIdxList = spreadsheetDto.parametricSheetErrorCheckRowIdxList || []
          asgariUcretBrut = spreadsheetDto.asgariUcretBrut ?? 0
          minimumSigortaBedeli = spreadsheetDto.minimumSigortaBedeli ?? 0
          minimumKiyafetBedeli = spreadsheetDto.minimumKiyafetBedeli ?? 0
          minimumTechizatBedeli = spreadsheetDto.minimumTechizatBedeli ?? 0
          minimumIsgBedeli = spreadsheetDto.minimumIsgBedeli ?? 0
          regionsMinimumGrossWageRowIdx = spreadsheetDto.regionsMinimumGrossWageRowIdx ?? 0
          isThereARoadBlockerOrACopperCableInStationRowIdx = spreadsheetDto.isThereARoadBlockerOrACopperCableInStationRowIdx ?? 0
          costOfClothingRowIdx = spreadsheetDto.costOfClothingRowIdx ?? 0
          costOfEquipmentRowIdx = spreadsheetDto.costOfEquipmentRowIdx ?? 0
          costOfWorkHealthAndSafetyRowIdx = spreadsheetDto.costOfWorkHealthAndSafetyRowIdx ?? 0
          pozisyonRowIdx = spreadsheetDto.pozisyonRowIdx ?? 0
          brutUcretRowIdx = spreadsheetDto.brutUcretRowIdx ?? 0
          brutSartliPerformansPrimiRowIdx = spreadsheetDto.brutSartliPerformansPrimiRowIdx ?? 0
          workingHoursRowIdx = spreadsheetDto.workingHoursRowIdx ?? 0
          yolAylikVeriGirisiRowIdx = spreadsheetDto.yolAylikVeriGirisiRowIdx ?? 0
          yemekOdemeSekliGunlukAylikRowIdx = spreadsheetDto.yemekOdemeSekliGunlukAylikRowIdx ?? 0
          yemekAylikVeriGirisiRowIdx = spreadsheetDto.yemekAylikVeriGirisiRowIdx ?? 0
          fazlaMesaiSaatiRowIdx = spreadsheetDto.fazlaMesaiSaatiRowIdx ?? 0
          senelikIzinGunSayisiRowIdx = spreadsheetDto.senelikIzinGunSayisiRowIdx ?? 0
          ihbarTazminatiSureleriRowIdx = spreadsheetDto.ihbarTazminatiSureleriRowIdx ?? 0
          fazlaMesaiSaatleri = spreadsheetDto.fazlaMesaiSaatleri || []
          senelikIzinGunSayiVeKatsayiList = spreadsheetDto.senelikIzinGunSayiVeKatsayiList || []
        }

        const toolbar = {
          home: [
            'exportAs',
            {
              type: 'button',
              text: ' ' + t('Toggle Hidden Rows'),
              icon: 'rows',
              attributes: { class: 'btn btn-link btn-primary toggleHiddenRows', style: 'display: none' },
              click: toggleHiddenRows,
            },
            spreadsheetDto?.isItReadonly === true
              ? undefined
              : {
                  type: 'button',
                  text: ' ' + t('Calculate Circular References'),
                  icon: 'calculator',
                  attributes: { class: 'btn btn-link btn-primary calculateCircular' },
                  click: calculateCircularReferences,
                },
            spreadsheetDto?.isItReadonly === true
              ? undefined
              : {
                  type: 'button',
                  text: ' ' + t('Save'),
                  icon: 'save',
                  attributes: { class: 'btn btn-link btn-success saveSpreadsheet' },
                  click: confirmSave,
                },
            // spreadsheetDto?.isItReadonly === true
            //   ? undefined
            //   : {
            //       type: 'button',
            //       text: ' ' + t('Go To Technology Offer'),
            //       icon: 'hyperlink-open',
            //       attributes: { class: 'btn btn-link btn-danger goTechnologyOffer' },
            //       click: goToTechnologyOffer,
            //     },
          ],
          insert: false,
          data: false,
        } as kendo.ui.SpreadsheetToolbar

        const spreadsheetData = instance
          .kendoSpreadsheet({
            toolbar: toolbar,
            sheets: data.sheets,
            // useCultureDecimals: true,
            excelExport: function (e: any) {
              e.preventDefault()

              var spreadsheetJson = $('#spreadsheet').data('kendoSpreadsheet')?.toJSON()

              if (
                !spreadsheetJson.sheets ||
                spreadsheetJson.sheets.length != totalSheetCount ||
                spreadsheetJson.sheets[summarySheetIdx].name != t(summarySheetName) ||
                spreadsheetJson.sheets[parametricDataSheetIdx].name != t(parametricDataSheetName) ||
                spreadsheetJson.sheets[personelSheetIdx].name != t(personelSheetName) ||
                spreadsheetJson.sheets[icmalSheetIdx].name != t(icmalSheetName) ||
                spreadsheetJson.sheets[digerSheetIdx].name != t(digerSheetName)
              ) {
                toast.error(t('Excel sheets are changed'))
                return
              }

              const areThereAnyCircularFormulas = spreadsheetDto?.circularFormulas && spreadsheetDto?.circularFormulas.length > 0

              if (areThereAnyCircularFormulas) {
                spreadsheetDto?.circularFormulas?.forEach((curFormulaDto) => {
                  const tmpSheet = e.workbook.sheets[curFormulaDto.sheetIdx]

                  if (tmpSheet.name == curFormulaDto.sheetName) {
                    tmpSheet.rows[curFormulaDto.rowIdx || 0].cells[curFormulaDto.cellIdx || 0].formula = curFormulaDto.formula
                  }
                })
              }

              new kendo.ooxml.Workbook(e.workbook).toDataURLAsync().then((dataURL) => {
                const offerName = (spreadsheetJson.sheets[summarySheetIdx].rows[0].cells[1].value || '').trim()

                kendo.saveAs({
                  dataURI: dataURL,
                  fileName: (offerName == '' ? t('unnamed_offer') : offerName) + '.xlsx',
                })

                if (areThereAnyCircularFormulas) {
                  spreadsheetDto?.circularFormulas?.forEach((curFormulaDto) => {
                    const tmpSheet = e.workbook.sheets[curFormulaDto.sheetIdx]

                    if (tmpSheet.name == curFormulaDto.sheetName) {
                      tmpSheet.rows[curFormulaDto.rowIdx || 0].cells[curFormulaDto.cellIdx || 0].formula = undefined
                    }
                  })
                }
              })
            },
            selectSheet: function (e: any) {
              if (e.sheet._sheetName == t(personelSheetName)) {
                $('.toggleHiddenRows').show()
              } else {
                $('.toggleHiddenRows').hide()
              }

              sheetScrollingHelper(e.sheet._sheetName)
            },
            change: function (e: any) {
              let didEnterLoop = false
              e.range.forEachCell(function (row: any, column: any, cellProperties: any) {
                if (didEnterLoop) return
                didEnterLoop = true

                const activeSheet = spreadsheetData._workbook.activeSheet()
                const curSheetIdx = spreadsheetData._workbook.sheetIndex(activeSheet)

                if (curSheetIdx == parametricDataSheetIdx) {
                  if (column == 1) {
                    // hardcoded: B col
                    if (row == regionsMinimumGrossWageRowIdx - 1) {
                      if (cellProperties.value == '' || cellProperties.value == null || cellProperties.value < asgariUcretBrut) {
                        const curToastMsg =
                          t('Minimum gross wage is {0} ₺').replace('{0}', formatTL.format(asgariUcretBrut)) +
                          '. ' +
                          t('You cannot enter below the minimum gross wage') +
                          '.'
                        toast.error(curToastMsg)
                      }
                    } else if (row == costOfClothingRowIdx - 1) {
                      if (cellProperties.value == '' || cellProperties.value == null || cellProperties.value < minimumKiyafetBedeli) {
                        toast.warning(t('The minimum clothing cost is {0} ₺').replace('{0}', formatTL.format(minimumKiyafetBedeli)))
                      }
                    } else if (row == costOfEquipmentRowIdx - 1) {
                      if (cellProperties.value == '' || cellProperties.value == null || cellProperties.value < minimumTechizatBedeli) {
                        toast.warning(t('The minimum equipment cost is {0} ₺').replace('{0}', formatTL.format(minimumTechizatBedeli)))
                      }
                    }
                  } else if (column == 2) {
                    // hardcoded: C col
                    if (row == costOfWorkHealthAndSafetyRowIdx - 1) {
                      if (cellProperties.value == '' || cellProperties.value == null || cellProperties.value < minimumIsgBedeli) {
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]
                        const isgMaliyetiSorumlulukCellRange = curSheetObj.range('B' + costOfWorkHealthAndSafetyRowIdx) // hardcoded
                        if (isgMaliyetiSorumlulukCellRange.value() == t('Included In Cost-Separate Row')) {
                          // hardcoded
                          toast.warning(t('The minimum OHS cost is {0} ₺').replace('{0}', formatTL.format(minimumIsgBedeli)))
                        }
                      }
                    }
                  } else if (column == 3) {
                    // hardcoded: D col
                    if (row == isThereARoadBlockerOrACopperCableInStationRowIdx - 1) {
                      if (cellProperties.value == '' || cellProperties.value == null || cellProperties.value < minimumSigortaBedeli) {
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]
                        const roadBlockerVarMiCellRange = curSheetObj.range('B' + isThereARoadBlockerOrACopperCableInStationRowIdx) // hardcoded
                        if (roadBlockerVarMiCellRange.value() != t('Yes')) {
                          // hardcoded
                          toast.warning(t('The minimum insurance cost is {0} ₺').replace('{0}', formatTL.format(minimumSigortaBedeli)))
                        }
                      }
                    }
                  }
                } else if (curSheetIdx == personelSheetIdx) {
                  if (column > 0) {
                    // hardcoded: sutunlar 0 dan buyuk oldugunda
                    if (column % 2 == 1) {
                      // hardcoded: odd indexli sutunlar, pozisyonlarin 1. sutunlari
                      if (row == pozisyonRowIdx - 1) {
                        const curColLetter = getExcelColLetterFromIndex(column)
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]

                        const brutUcretCellRange = curSheetObj.range(curColLetter + brutUcretRowIdx)
                        brutUcretCellRange.value(asgariUcretBrut)

                        const yolAylikVeriGirisiCellRange = curSheetObj.range(curColLetter + yolAylikVeriGirisiRowIdx)
                        yolAylikVeriGirisiCellRange.value(0)

                        const yemekOdemeSekliGunlukAylikCellRange = curSheetObj.range(curColLetter + yemekOdemeSekliGunlukAylikRowIdx)
                        yemekOdemeSekliGunlukAylikCellRange.value(t('MONTHLY'))

                        const yemekAylikVeriGirisiCellRange = curSheetObj.range(curColLetter + yemekAylikVeriGirisiRowIdx)
                        yemekAylikVeriGirisiCellRange.value(0)

                        const senelikIzinGunSayisiCellRange = curSheetObj.range(curColLetter + senelikIzinGunSayisiRowIdx)
                        senelikIzinGunSayisiCellRange.value(14)

                        const ihbarTazminatiSureleriCellRange = curSheetObj.range(curColLetter + ihbarTazminatiSureleriRowIdx)
                        ihbarTazminatiSureleriCellRange.value(t('2 Weeks'))
                      } else if (row == workingHoursRowIdx - 1) {
                        const curColLetter = getExcelColLetterFromIndex(column)
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]
                        const fazlaMesaiSaatiCellRange = curSheetObj.range(curColLetter + fazlaMesaiSaatiRowIdx)
                        const calismaSaatiCellVal = curSheetObj.range(curColLetter + workingHoursRowIdx).value()
                        const fazlaMesaiValueIdx = fazlaMesaiSaatleri.findIndex((x) => x.key == calismaSaatiCellVal)
                        let newFazlaMesaiSaatiCellVal = 0
                        if (fazlaMesaiValueIdx != -1) {
                          newFazlaMesaiSaatiCellVal = fazlaMesaiSaatleri[fazlaMesaiValueIdx].value
                        }
                        fazlaMesaiSaatiCellRange.value(newFazlaMesaiSaatiCellVal).format(null)
                      } else if (row == brutUcretRowIdx - 1) {
                        if (cellProperties.value == null || cellProperties.value == '' || cellProperties.value < asgariUcretBrut) {
                          const curToastMsg =
                            t('Minimum gross wage is {0} ₺').replace('{0}', formatTL.format(asgariUcretBrut)) +
                            '. ' +
                            t('You cannot enter below the minimum gross wage') +
                            '.'
                          toast.error(curToastMsg)
                        }
                      } else if (row == brutSartliPerformansPrimiRowIdx - 1) {
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]
                        const curColLetter = getExcelColLetterFromIndex(column)
                        const brutUcretCellVal = curSheetObj.range(curColLetter + brutUcretRowIdx).value()
                        if (
                          cellProperties.value != null &&
                          cellProperties.value != '' &&
                          (brutUcretCellVal == null || brutUcretCellVal == '' || cellProperties.value >= brutUcretCellVal)
                        ) {
                          toast.error(t('The gross conditional performance bonus must be lower than the gross salary'))
                        }
                      } else if (row == senelikIzinGunSayisiRowIdx - 1) {
                        const curColLetter = getExcelColLetterFromIndex(column)
                        const nextColLetter = getExcelColLetterFromIndex(column + 1)
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]
                        const senelikIzinGunSayisiCellRange = curSheetObj.range(curColLetter + senelikIzinGunSayisiRowIdx)
                        const senelikIzinGunKatsayisiCellRange = curSheetObj.range(nextColLetter + senelikIzinGunSayisiRowIdx)
                        const senelikIzinGunSayisiCellVal = senelikIzinGunSayisiCellRange.value()
                        if (senelikIzinGunSayisiCellVal == undefined || senelikIzinGunSayisiCellVal == '') {
                          senelikIzinGunKatsayisiCellRange.value(0)
                        } else {
                          const senelikIzinGunKatsayisiIdx = senelikIzinGunSayiVeKatsayiList.findIndex((x) => x.key == senelikIzinGunSayisiCellVal)
                          if (senelikIzinGunKatsayisiIdx == -1) {
                            senelikIzinGunKatsayisiCellRange.value(0)
                          } else {
                            senelikIzinGunKatsayisiCellRange.value(senelikIzinGunSayiVeKatsayiList[senelikIzinGunKatsayisiIdx].value)
                          }
                        }
                      }
                    } else {
                      // hardcoded: even indexli sutunlar, pozisyonlarin 2. sutunlari
                    }
                  }
                } else if (curSheetIdx == digerSheetIdx) {
                  const digerSheetAylikStr = 'AYLIK'
                  const digerSheetBeherPersonelStr = 'Beher Personel'

                  if (column == 0) {
                    // hardcoded: A col
                    if (row > 0) {
                      // hardcoded: check after the first row - title row
                      const curItemVal = ('' + (cellProperties.value || '')).turkishToUpper()
                      if (curItemVal != '') {
                        const txtSmart = 'SMART'
                        const txtPda = 'PDA'
                        const txtDamga = 'DAMGA'
                        const txtTeminat = 'TEMİNAT'
                        if (curItemVal.includes(txtSmart) || curItemVal.includes(txtPda)) {
                          toast.warning(
                            t('You must include technology product expenses in the technology offer. Please do not include them in other expenses.')
                          )
                        } else if (curItemVal.includes(txtDamga)) {
                          toast.warning(t('The contract stamp tax expenses are managed in the offers. Please do not include them in other expenses.'))
                        } else if (curItemVal.includes(txtTeminat)) {
                          toast.warning(t('The cost of the guarantee letter is managed in the offers. Please do not include it in other expenses.'))
                        }
                      }
                    }
                  } else if (column == 1) {
                    // hardcoded: B col
                    if (row > 0) {
                      // hardcoded: check after the first row - title row
                      if (cellProperties.value == t(digerSheetAylikStr)) {
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]
                        const karOraniCellRange = curSheetObj.range('F' + (row + 1)) // hardcoded
                        karOraniCellRange.enable(true)
                        karOraniCellRange.background(null)
                        const gorevNoktasiCellRange = curSheetObj.range('G' + (row + 1)) // hardcoded
                        gorevNoktasiCellRange.enable(false)
                        gorevNoktasiCellRange.value(null)
                        gorevNoktasiCellRange.background('#dcdcdc')
                      } else if (cellProperties.value == t(digerSheetBeherPersonelStr)) {
                        const curSheetObj = spreadsheetData._workbook._sheets[curSheetIdx]
                        const karOraniCellRange = curSheetObj.range('F' + (row + 1)) // hardcoded
                        karOraniCellRange.enable(false)
                        karOraniCellRange.value(null)
                        karOraniCellRange.background('#dcdcdc')
                        const gorevNoktasiCellRange = curSheetObj.range('G' + (row + 1)) // hardcoded
                        gorevNoktasiCellRange.enable(true)
                        gorevNoktasiCellRange.background(null)
                      }
                    }
                  }
                }
              })
            },
          })
          .data('kendoSpreadsheet')

        $('.k-spreadsheet-toolbar:eq(0)')
          .data('kendoSpreadsheetToolBar')
          .bind('click', function (ev: any) {
            // if ($(ev.target).index()) { // bu calismadi
            if ($(ev.target).index() != null && $(ev.target).index() != -1) {
              var ddl = $('.k-popup-edit-form select.k-file-format:eq(0)').data('kendoDropDownList')
              if (ddl) {
                var oldData = ddl.dataSource.data()
                ddl.dataSource.remove(oldData[oldData.length - 1])
              }
            }
          })

        for (let i = 0; i < data.sheets.length; i++) {
          const s = data.sheets[i]

          if (s) {
            if (s.hiddenRows && s.hiddenRows.length > 0) {
              s.hiddenRows.forEach((x: number) => {
                spreadsheetData._workbook._sheets[i]._rows.hide(x)
              })
            }
            if (s.hiddenColumns && s.hiddenColumns.length > 0) {
              s.hiddenColumns.forEach((x: number) => {
                spreadsheetData._workbook._sheets[i]._columns.hide(x)
              })
            }

            if (spreadsheetDto?.isItReadonly === true) {
              spreadsheetData._workbook._sheets[i].range('A1:AX200').enable(false)
            }
          }
        }

        instance.kendoTooltip({
          filter: '.calculateCircular, .saveSpreadsheet, .toggleHiddenRows, .goTechnologyOffer',
          position: 'top',
          content: (e: any) => {
            if (!!e && !!e.target && !!e.target[0]) {
              if (e.target[0].classList.contains('calculateCircular')) {
                return t('Calculate Circular References Tooltip')
              } else if (e.target[0].classList.contains('saveSpreadsheet')) {
                return t('Save Changes')
              } else if (e.target[0].classList.contains('toggleHiddenRows')) {
                return t('Toggle Hidden Rows Button Tooltip')
              } else if (e.target[0].classList.contains('goTechnologyOffer')) {
                return t('If you want to create a technology offer click this button')
              }
            }
          },
        })
      }
    }, [data])

    useEffect(() => {
      const spreadsheet = $('#spreadsheet').data('kendoSpreadsheet')

      if (spreadsheet) {
        kendo.ui.progress(spreadsheet.element, isLoading)
      }
    }, [isLoading])

    useEffect(() => {
      if (!!offerParamDto && offerParamDto != previousOfferParamDto) {
        setIsLoading(true)
        setInitialLoadFlag(false)

        store?.getSpreadsheet(offerParamDto).then((result) => {
          setVersion(undefined)
          setTemplateOffer(undefined)
          setIsItForExistingCustomer(false)
          setIsLoading(false)
        })
      }
    }, [offerParamDto])

    const confirmSave = () => {
      setShowConfirmSaveModal(true)
    }

    const uploadData = async () => {
      if (!offerParamDto || !offerParamDto.versionDto) {
        toast.error(t('Unknown Error'))
        return
      }

      setIsLoading(true)

      var spreadsheetJson = $('#spreadsheet').data('kendoSpreadsheet')?.toJSON()

      if (
        !spreadsheetJson.sheets ||
        spreadsheetJson.sheets.length != totalSheetCount ||
        spreadsheetJson.sheets[summarySheetIdx].name != t(summarySheetName) ||
        spreadsheetJson.sheets[parametricDataSheetIdx].name != t(parametricDataSheetName) ||
        spreadsheetJson.sheets[personelSheetIdx].name != t(personelSheetName) ||
        spreadsheetJson.sheets[icmalSheetIdx].name != t(icmalSheetName) ||
        spreadsheetJson.sheets[digerSheetIdx].name != t(digerSheetName)
      ) {
        toast.error(t("Can't be saved. Excel sheets are changed."))
        setIsLoading(false)
        return
      }

      const offerName = (spreadsheetJson.sheets[summarySheetIdx].rows[0].cells[1].value || '').trim()
      if (offerName == '') {
        toast.error(t('You must enter an offer name'))
        setIsLoading(false)
        return
      }

      const errorColumnIdx = 5

      const parametricDataSheetRows = spreadsheetJson.sheets[parametricDataSheetIdx].rows
      let isThereAnErrorInParametricDataSheet = false
      parametricSheetErrorCheckRowIdxList.forEach((errorRowIdx) => {
        if ((parametricDataSheetRows[errorRowIdx].cells[errorColumnIdx].value || '').length > 0) {
          isThereAnErrorInParametricDataSheet = true
        }
      })
      if (isThereAnErrorInParametricDataSheet) {
        toast.error(t('Please fix the errors in the parametric data sheet'))
        setIsLoading(false)
        return
      }

      const personelSheetRows = spreadsheetJson.sheets[personelSheetIdx].rows
      const pozisyonRow = personelSheetRows[pozisyonRowIdx - 1]
      const pozisyonSecilenSutunIdxList = []
      for (let i = 1; i < pozisyonRow.cells.length; i++) {
        const curPozisyonCellVal = pozisyonRow.cells[i].value || ''
        if (curPozisyonCellVal != '' && curPozisyonCellVal != `${t('Choose')}...`) {
          // hardcoded
          pozisyonSecilenSutunIdxList.push(i)
        }
      }
      if (pozisyonSecilenSutunIdxList.length == 0) {
        toast.error(t('Please select a position in personnel sheet'))
        setIsLoading(false)
        return
      }
      let personelSheetdeHataVarMi = false
      const brutUcretRow = personelSheetRows[brutUcretRowIdx - 1]
      const brutSartliPerformansPrimiRow = personelSheetRows[brutSartliPerformansPrimiRowIdx - 1]
      pozisyonSecilenSutunIdxList.forEach((curColIdx) => {
        if (personelSheetdeHataVarMi) return
        const curBrutUcretCellVal = brutUcretRow.cells[curColIdx].value
        const curBrutSartliPerformansPrimiCellVal = brutSartliPerformansPrimiRow.cells[curColIdx].value
        if (curBrutUcretCellVal == null || curBrutUcretCellVal == '' || curBrutUcretCellVal < asgariUcretBrut) {
          personelSheetdeHataVarMi = true
          const curToastMsg =
            t('Minimum gross wage is {0} ₺').replace('{0}', formatTL.format(asgariUcretBrut)) +
            '. ' +
            t('You cannot enter below the minimum gross wage') +
            '.'
          toast.error(curToastMsg)
        } else if (
          curBrutSartliPerformansPrimiCellVal != null &&
          curBrutSartliPerformansPrimiCellVal != '' &&
          (curBrutUcretCellVal == null || curBrutUcretCellVal == '' || curBrutSartliPerformansPrimiCellVal >= curBrutUcretCellVal)
        ) {
          personelSheetdeHataVarMi = true
          toast.error(t('The gross conditional performance bonus must be lower than the gross salary'))
        }
      })
      if (personelSheetdeHataVarMi) {
        setIsLoading(false)
        return
      }

      if (offerParamDto.filterOffer?.id == undefined) {
        if (savedOfferId == undefined) {
          const tmpSavedOfferId = (
            await store!.postSpreadsheet(
              { sheets: spreadsheetJson.sheets } as ISpreadsheetDTO,
              offerParamDto.versionDto.id,
              offerParamDto.isItForExistingCustomer === true
            )
          ).data
          setSavedOfferId(tmpSavedOfferId)
        } else {
          await store!.postSpreadsheet(
            { sheets: spreadsheetJson.sheets } as ISpreadsheetDTO,
            offerParamDto.versionDto.id,
            offerParamDto.isItForExistingCustomer === true,
            savedOfferId
          )
        }
      } else {
        await store!.postSpreadsheet(
          { sheets: spreadsheetJson.sheets } as ISpreadsheetDTO,
          offerParamDto.versionDto.id,
          offerParamDto.isItForExistingCustomer === true,
          offerParamDto?.filterOffer?.id
        )
      }

      await store!.getOffers({ skipCount: 0, maxResultCount: 5 })

      setIsLoading(false)
      toast.success(t('Successfully saved'))
    }

    const toggleHiddenRows = () => {
      if ((personelSheetToggleHiddenRowIdxList || []).length == 0) return

      const spreadsheetInstance = instance?.getKendoSpreadsheet()

      const personelSheet = spreadsheetInstance._workbook._sheets[personelSheetIdx]

      // we only need 1 row to check if all the rows are hidden or not
      if (personelSheet.isHiddenRow(personelSheetToggleHiddenRowIdxList[0])) {
        personelSheetToggleHiddenRowIdxList.forEach((x) => personelSheet.unhideRow(x))
      } else {
        personelSheetToggleHiddenRowIdxList.forEach((x) => personelSheet.hideRow(x))
      }
    }

    const calculateCircularReferences = () => {
      if (!offerParamDto || !offerParamDto.versionDto) {
        toast.error(t('Unknown Error'))
        return
      }

      setIsLoading(true)

      var t = $('#spreadsheet').data('kendoSpreadsheet')?.toJSON()

      store?.calculateCircularReferences({ sheets: t.sheets } as ISpreadsheetDTO, offerParamDto).then((resultDto) => {
        const areThereAnyCircularFormulas = resultDto?.data && resultDto?.data.length > 0

        if (areThereAnyCircularFormulas) {
          const spreadsheetInstance = instance?.getKendoSpreadsheet()

          resultDto?.data?.forEach((curFormulaDto: ICircularFormulaDTO) => {
            const tmpSheet = spreadsheetInstance._workbook._sheets[curFormulaDto.sheetIdx]

            if (tmpSheet._sheetName == curFormulaDto.sheetName) {
              tmpSheet.range(curFormulaDto.range).value(curFormulaDto.value)
            }
          })
        }

        setIsLoading(false)
      })
    }

    const goToTechnologyOffer = () => {
      var win = window.open(window.location.origin + `/digital-offer/technology-offer`, '_blank')
      if (win != null) {
        win.focus()
      }
    }

    const createNewOffer = () => {
      setSelectedOfferRow(undefined)
      setShowParameterPopup(true)
    }

    const detailChooseDeletionMessage = () => {
      if (!selectedOfferRow) {
        return <p>{t('Unknown Error')}</p>
      }

      return (
        <>
          <p>
            {t('Name')}: {selectedOfferRow.name}
          </p>
          <p>
            {t('Creator Name')}: {selectedOfferRow.creatorUserName}
          </p>
          <p>
            {t('Creation Time')}: {moment(selectedOfferRow.createDateTime).format('DD.MM.YYYY - HH:mm')}
          </p>
        </>
      )
    }

    const offerColumns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                {row.isReadOnly === true && (
                  <Button
                    icon={<i className="fas fa-eye"></i>}
                    tooltipText={t('Details')}
                    onClick={async () => {
                      setSelectedOfferRow(row)
                      store?.unloadSpreadsheet()
                      setCollapseActiveKey([])
                      setOfferParamDto({
                        filterOffer: row,
                        versionDto: row.versionDto,
                        isItForExistingCustomer: row.isItForExistingCustomer,
                      } as IOfferParameterDto)
                    }}
                    className="btn btn-link"
                  />
                )}

                {row.isReadOnly !== true && (
                  <Button
                    icon={<i className="fas fa-pen"></i>}
                    tooltipText={t('Edit')}
                    onClick={async () => {
                      setSelectedOfferRow(row)
                      setShowParameterPopup(true)
                    }}
                    className="btn btn-link text-warning"
                  />
                )}

                {row.isReadOnly !== true && (
                  <Button
                    icon={<i className="fas fa-trash"></i>}
                    tooltipText={t('Delete')}
                    onClick={async () => {
                      setSelectedOfferRow(row)
                      setDeleteModal(true)
                    }}
                    className="btn btn-link text-danger"
                  />
                )}
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Pricing'),
          id: 'versionDto',
          accessor: 'versionDto',
          Cell: ({ row }: any) => {
            const { versionDto } = row.values

            return <span dangerouslySetInnerHTML={{ __html: versionDto.name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Creator Name'),
          id: 'creatorUserName',
          accessor: 'creatorUserName',
          Cell: ({ row }: any) => {
            const { creatorUserName } = row.values

            return <span dangerouslySetInnerHTML={{ __html: creatorUserName }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Creation Time'),
          id: 'createDateTime',
          accessor: 'createDateTime',
          Cell: ({ row }: any) => {
            const { createDateTime } = row.values

            return <span dangerouslySetInnerHTML={{ __html: moment(createDateTime).format('DD.MM.YYYY - HH:mm') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    const css = `
      .k-spreadsheet-sheets-remove {
        display: none !important;
      }
      a.k-spreadsheet-sheets-bar-add.k-button.k-icon-button {
        display: none !important;
      }
      .k-disabled {
        opacity: 1;
      }
      .k-spreadsheet-sheets-bar-active {
        background: #f5e3e3;
      }
      .k-spreadsheet-disabled-mask {
        display: none;
      }
      #k-license-banner {
        display: none !important;
      }
      .k-spreadsheet-window.k-action-window.k-popup-edit-form.k-window-content > .k-edit-label,
      .k-spreadsheet-window.k-action-window.k-popup-edit-form.k-window-content > .k-edit-field {
        display: none;
      }
      .app-inner-layout__header {
        display: none;
      }
      .goTechnologyOffer {
        margin-left: auto;
      }`

    return (
      <>
        <style>{css}</style>

        <Collapse
          activeKey={collapseActiveKey}
          onChange={() =>
            setCollapseActiveKey((prev) => {
              if (prev.length === 1) {
                return []
              }
              return ['1']
            })
          }
        >
          <Panel
            key="1"
            header={
              t('Offers') +
              (!offerParamDto?.filterOffer?.id ? '' : ' - ' + t('Offer') + ': ' + offerParamDto?.filterOffer?.name) +
              (!offerParamDto?.versionDto?.name ? '' : ' - ' + t('Pricing') + ': ' + offerParamDto?.versionDto?.name)
            }
          >
            <div className="card-body">
              <div className="card-header">
                <div className="row">
                  {t('Offers')}{' '}
                  <div className="btn-actions-pane-right actions-icon-btn">
                    <button
                      className="btn btn-primary btn-shadow flex-direction float-right"
                      style={{ width: '70px', marginLeft: '10px', marginTop: '-5px' }}
                      onClick={createNewOffer}
                    >
                      <i className="fas fa-plus mr-2"></i>
                      {t('Add')}
                    </button>
                  </div>
                </div>
              </div>

              <br />

              <div className="row" style={{ marginBottom: '-30px', marginLeft: '0px' }}>
                {t('Offers added to a customer offer solution cannot be changed')}
              </div>

              <Table
                smallSize={true}
                columns={offerColumns}
                totalCount={offers.totalCount}
                data={offers.items}
                fetchData={(x) => store?.getOffers(x)}
                isLoading={!offers.isLoaded}
              />
            </div>
          </Panel>
        </Collapse>

        {!store?.spreadsheet.isLoaded ? <Loading /> : <div id="spreadsheet" style={{ width: '100%', height: '84vh' }}></div>}

        {deleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={deleteModal}
            toggleModal={toggleDeleteModal}
            onConfirm={async () => {
              await store!.deleteOffer(selectedOfferRow!.id)
              toggleDeleteModal()
            }}
          />
        )}

        {showParameterPopup && (
          <ParameterPopup
            isShow={showParameterPopup}
            toggleModal={() => setShowParameterPopup(!showParameterPopup)}
            selectedRow={selectedOfferRow}
            setVersion={setVersion}
            setTemplateOffer={setTemplateOffer}
            setIsParameterPopupConfirmed={setIsParameterPopupConfirmed}
            setIsItForExistingCustomer={setIsItForExistingCustomer}
          />
        )}

        {showConfirmSaveModal && (
          <ConfirmModal
            message={t('The data will be saved, do you confirm?')}
            detailMessage={t('Conventional Offer Save Confirm Detail Message')}
            title={t('Save')}
            isShow={showConfirmSaveModal}
            toggleModal={() => setShowConfirmSaveModal(!showConfirmSaveModal)}
            onConfirm={() => {
              setShowConfirmSaveModal(false)
              uploadData()
            }}
          />
        )}
      </>
    )
  })
)

export default Page
