import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'
import { AxiosInstance } from 'axios'
import qs from 'qs'
import env from '../../../../config/env'
import http from '../../../../services/http'
import { ServiceResult } from '../../../../types/general-types'
import { CheckLoginDto, AuthDto, LoginResponseDto } from './types'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'LoginStore'

  get api(): AxiosInstance {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)
  }

  @action.bound async checkLogin(params: CheckLoginDto) {
    if (params.username && params.password) {
      return await (
        await this.api.post(`/login`, params)
      ).data
    }
  }

  @action.bound async authentication(params: AuthDto) {
    return await (
      await this.api.put(`/login/two-factor-authentication`, params)
    ).data
  }
}

export default Store
