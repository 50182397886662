import React, { Suspense, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withResizeDetector } from 'react-resize-detector'
import { ToastContainer } from 'react-toastify'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import i18n from '@/utils/i18n'
import { Sidebar, Header, PageTitle } from './_partials'
import dateFns from 'date-fns'
import { registerLocale } from 'react-datepicker'
import tr from 'date-fns/locale/tr'
import enUs from 'date-fns/esm/locale/en-US'
import 'moment/locale/tr'
import 'moment/locale/en-gb'
registerLocale('en-US' , enUs)
registerLocale('tr', tr)

interface ILayout {
  exact: boolean
  computedMatch: any
  component: any
  icon?: string
  title: string
  UIStore?: any
  width: number
  height: number
  targetRef?: any
  routes?: any
}

const Layout = inject('UIStore')(
  observer(
    withResizeDetector((props: ILayout) => {
      const { exact, computedMatch, component: Component, icon, title, UIStore, width, height, targetRef, routes, ...rest } = props
      const {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
      } = UIStore
      const [fullScreen, setFullScreen] = useState(enableClosedSidebar)
      const { t } = useTranslation()
      const MemoizedComponent = useMemo(() => <Component {...rest} />, [])
      //todo register
      if(i18n.language==="tr"){
        registerLocale('tr', tr)
      }
      if(i18n.language === 'en'){
        registerLocale('en-US' , enUs)
      }
      return useMemo(() => {
        return (
          <div
            ref={targetRef}
            className={cx(
              'app-container app-theme-' + colorScheme,
              { 'fixed-header': enableFixedHeader },
              { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
              { 'fixed-footer': enableFixedFooter },
              { 'closed-sidebar': fullScreen || enableClosedSidebar || width < 1250 },
              {
                'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
              },
              { 'sidebar-mobile-open': enableMobileMenu },
              { 'body-tabs-shadow-btn': enablePageTabsAlt }
            )}
          >
            <Header />
            <div className="app-main">
              <Sidebar routes={routes} />
              <div className="app-main__outer">
                <div className="app-main__inner p-0">
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="loader-container-inner">
                          <div className="text-center"></div>
                          <h6 className="mt-3">
                            Please wait while we load the page...
                            <small>Because we load at once all the contents.</small>
                          </h6>
                        </div>
                      </div>
                    }
                  >
                    <div className="app-inner-layout">
                      <PageTitle icon={icon} heading={title} />

                      <div className="app-inner-layout__container p-4">
                        {!(closedSmallerSidebar || width < 1250)}

                        <div className="card main-card mb-3">{MemoizedComponent}</div>
                      </div>
                    </div>
                  </Suspense>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        )
      }, [
        MemoizedComponent,
        closedSmallerSidebar,
        colorScheme,
        enableClosedSidebar,
        enableFixedFooter,
        enableFixedHeader,
        enableFixedSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
        icon,
        targetRef,
        routes,
        title,
        width,
        fullScreen,
      ])
    })
  )
)

const InframeLayout = (props: ILayout) => {
  const { exact, computedMatch, component: Component, icon, title, targetRef, ...rest } = props

  const MemoizedComponent = useMemo(() => <Component {...rest} />, [])

  return useMemo(
    () => (
      <div ref={targetRef} className="app-container app-theme-white">
        <div className="app-main">
          <div className="app-main__outer">
            <div className="app-main__inner p-0">
              <Suspense
                fallback={
                  <div className="loader-container">
                    <div className="loader-container-inner">
                      <div className="text-center"></div>
                      <h6 className="mt-3">
                        Please wait while we load the page...
                        <small>Because we load at once all the contents.</small>
                      </h6>
                    </div>
                  </div>
                }
              >
                <div className="app-inner-layout">
                  <PageTitle icon={icon} heading={title} />
                  <div className="app-inner-layout__container p-3 p-lg-5">
                    <div className="card main-card mb-3">{MemoizedComponent}</div>
                  </div>
                </div>
              </Suspense>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    ),
    [MemoizedComponent, icon, targetRef, title]
  )
}

export { InframeLayout as Iframe }

export default Layout
