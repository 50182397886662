import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Table } from '@/components'
import * as _ from 'lodash'
import { useForm } from 'react-hook-form'
import Store from './store'
import { Combobox } from 'react-widgets/cjs'
import { IOfferDto } from './types'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ConventionalOfferVersionDto } from '../conventional-offer-admin/types'

interface IView {
  ConventionalOfferStore?: Store
  isShow: boolean
  toggleModal: any
  selectedRow?: IOfferDto
  setVersion: any
  setTemplateOffer: any
  setIsParameterPopupConfirmed: any
  setIsItForExistingCustomer: any
}

const View: FC<IView> = inject('ConventionalOfferStore')(
  observer((props: IView) => {
    const {
      ConventionalOfferStore: store,
      isShow,
      toggleModal,
      selectedRow,
      setVersion: setVersionInIndex,
      setTemplateOffer: setTemplateOfferInIndex,
      setIsParameterPopupConfirmed,
      setIsItForExistingCustomer: setIsItForExistingCustomerInIndex,
    } = props
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const [versionList, setVersionList] = useState<ConventionalOfferVersionDto[]>()
    const [version, setVersion] = useState<ConventionalOfferVersionDto>()
    const [templateOffer, setTemplateOffer] = useState<IOfferDto>()
    const [offerList, setOfferList] = useState<IOfferDto[]>()
    const [isLoading, setIsLoading] = useState(true)
    const [isTheEditedOffersVersionObsolete, setIsTheEditedOffersVersionObsolete] = useState(false)
    const [isItForExistingCustomer, setIsItForExistingCustomer] = useState(false)

    useEffect(() => {
      store!.getVersionsAsList().then((tmpVersionList) => {
        if (!selectedRow) {
          store!.getOffersAsList().then((tmpOfferList) => {
            setOfferList(tmpOfferList)
            setVersionList(tmpVersionList)
            setIsLoading(false)
          })
        } else {
          setIsItForExistingCustomer(selectedRow.isItForExistingCustomer === true)
          setVersionList(tmpVersionList)
          if (selectedRow.versionDto?.madeObsoleteTime == undefined) {
            setVersion(selectedRow.versionDto)
          } else {
            setIsTheEditedOffersVersionObsolete(true)
          }
          setIsLoading(false)
        }
      })
    }, [])

    useEffect(() => {
      if (versionList) {
        const tmpVersionList = [...versionList]
        tmpVersionList.forEach((tmpVersion) => {
          if (tmpVersion.id == version?.id) {
            tmpVersion.isSelected = true
          } else {
            tmpVersion.isSelected = false
          }
        })
        setVersionList(tmpVersionList)
      }
    }, [version])

    const submit = async () => {
      if (!version) {
        toast.error(t('Please select pricing'))
        return
      }

      setVersionInIndex(version)

      setTemplateOfferInIndex(templateOffer)

      setIsItForExistingCustomerInIndex(isItForExistingCustomer)

      setIsParameterPopupConfirmed(true)

      toggleModal()
    }

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            const { isSelected } = row
            return (
              <>
                <Button
                  icon={<i className={isSelected === true ? 'fas fa-check-square' : 'fas fa-square'}></i>}
                  tooltipText={t('Select')}
                  onClick={async () => {
                    setVersion(row)
                  }}
                  className="btn btn-link"
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Explanation'),
          id: 'explanation',
          accessor: 'explanation',
          Cell: ({ row }: any) => {
            const { explanation } = row.values

            return <span dangerouslySetInnerHTML={{ __html: explanation }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Last Editor Name'),
          id: 'creatorName',
          accessor: 'creatorName',
          Cell: ({ row }: any) => {
            const { creatorName } = row.values

            return <span dangerouslySetInnerHTML={{ __html: creatorName }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Last Edit Time'),
          id: 'createTime',
          accessor: 'createTime',
          Cell: ({ row }: any) => {
            const { createTime } = row.values

            return <span dangerouslySetInnerHTML={{ __html: moment(createTime).format('DD.MM.YYYY - HH:mm') }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    const modalHeaderText = !selectedRow ? t('New Offer') : t('Edit Offer')

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t(modalHeaderText)}</ModalHeader>
        <ModalBody>
          <div className="d-flex" style={{ marginLeft: '20px' }}>
            <div className="form-row py-3">{t('Please select the customer type')}</div>
          </div>

          <div className="d-flex" style={{ marginBottom: '10px', marginLeft: '0px!important' }}>
            <div className="custom-control custom-radio px-5">
              <input
                type="radio"
                className="custom-control-input"
                id="typeRadio1"
                name="type"
                value="1"
                defaultChecked={isItForExistingCustomer}
                checked={isItForExistingCustomer}
                onChange={(value) => {
                  if (value.target.checked) {
                    setIsItForExistingCustomer(true)
                  }
                }}
              />
              <label className="custom-control-label" htmlFor="typeRadio1">
                {t('Existing Customer')}
              </label>
            </div>
            <div className="custom-control custom-radio px-5">
              <input
                type="radio"
                className="custom-control-input"
                id="typeRadio0"
                name="type"
                value="0"
                checked={!isItForExistingCustomer}
                onChange={(value) => {
                  if (value.target.checked) {
                    setIsItForExistingCustomer(false)
                  }
                }}
              />
              <label className="custom-control-label" htmlFor="typeRadio0">
                {t('Candidate Customer')}
              </label>
            </div>
          </div>

          {isTheEditedOffersVersionObsolete && (
            <div className="d-flex" style={{ marginLeft: '20px' }}>
              <div className="form-row py-3" style={{ color: 'red' }}>
                {t('The pricing version selected in this offer is obsolete. Please choose a new pricing.')}
              </div>
            </div>
          )}

          <div className="d-flex" style={{ marginLeft: '20px' }}>
            <div className="form-row py-3">{t('Only pricing versions that are locked and in use are shown')}</div>
          </div>

          <div className="card-header">
            <div className="row">
              {t('Pricing') + ' -\u00A0'}
              <span style={{ color: 'red', textTransform: 'none' }}>{!version ? t('Not Selected') : version.name}</span>
            </div>
          </div>

          <div className="row" style={{ marginTop: '10px' }}>
            <div style={{ display: 'flex' }}>
              <Table
                smallSize={true}
                showHeaderSearchInput={false}
                columns={columns}
                totalCount={(versionList || []).length}
                data={versionList || []}
                fetchData={() => {}}
                isLoading={isLoading}
                showNavigation={false}
              />
            </div>
          </div>
          {!selectedRow && (
            <>
              <div className="d-flex" style={{ marginLeft: '20px' }}>
                <div className="form-row py-3">{t('New Manned Services Offer Description')}</div>
              </div>
              <div className="d-flex" style={{ marginLeft: '20px' }}>
                <div className="form-row py-3">
                  <div className="form-group" style={{ width: '300px' }}>
                    <label htmlFor="offer" className="">
                      {t('Offer')}
                    </label>

                    <Combobox
                      data={offerList || []}
                      textField="listName"
                      dataKey="listName"
                      name="offer"
                      id="id"
                      onChange={(selected: any) => {
                        setTemplateOffer(selected)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-secondary mr-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="fas fa-arrow-right mr-2"></i>}
            text={t('Continue')}
            disabled={isLoading}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
