import React, { FC, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Slider } from 'react-burgers'
import cx from 'classnames'

import SearchBox from './_parts/searchBox'
import UserMenu from './_parts/userMenu'
import LanguageSelection from './_parts/languageSelection'
import Notifications from './_parts/notifications'

interface IHeaderProps {
  UIStore?: any
}

const Header: FC<IHeaderProps> = inject('UIStore')(
  observer((props: IHeaderProps) => {
    const { UIStore } = props
    const { headerBackgroundColor, enableHeaderShadow } = UIStore

    const [activeBurger, setActiveBurger] = useState(false)
    const [enableSmallMenu, setEnableSmallMenu] = useState(false)

    return (
      <TransitionGroup
        component="div"
        className={cx('app-header', headerBackgroundColor, {
          'header-shadow': enableHeaderShadow,
        })}
      >
        <CSSTransition timeout={1500} onEntered={() => {}} onExited={() => {}}>
          <>
            <div className="app-header__logo">
              <div className="logo-src"></div>
              <div className="header__pane ml-auto">
                <div>
                  <button
                    onClick={() => {
                      setActiveBurger(!activeBurger)
                      const container = document.querySelector('.app-container')
                      container?.classList.toggle('closed-sidebar')
                    }}
                    type="button"
                    className="hamburger close-sidebar-btn hamburger--elastic"
                    data-class="closed-sidebar"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="app-header__mobile-menu">
              <Slider
                width={26}
                lineHeight={2}
                lineSpacing={5}
                color="#6c757d"
                active={activeBurger}
                onClick={() => {
                  setActiveBurger(!activeBurger)
                  const container = document.querySelector('.app-container')
                  container?.classList.toggle('sidebar-mobile-open')
                }}
              />
            </div>
            <div className="app-header__menu">
              <button className={cx('btn', 'btn-sm', 'btn-primary', 'btn-icon btn-icon-only')} onClick={() => setEnableSmallMenu(!enableSmallMenu)}>
                <div className="btn-icon-wrapper">
                  <i className="fas fa-ellipsis-v"></i>
                </div>
              </button>
            </div>
            <div
              className={cx('app-header__content', {
                'header-mobile-open': enableSmallMenu,
              })}
            >
              <div className="app-header-left">
                <SearchBox />
              </div>
              <div className="app-header-right">
                <div className="header-dots">
                  <div className="p-0 mr-2">
                    <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                      <div className="icon-wrapper-bg bg-focus" />
                      <i className="fas fa-info-circle text-white" style={{ fontSize: 23 }}></i>
                    </div>
                  </div>
                  <Notifications />
                  <LanguageSelection />
                </div>
                <UserMenu />
              </div>
            </div>
          </>
        </CSSTransition>
      </TransitionGroup>
    )
  })
)

export default Header
