import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import { Button, Loading } from '@/components'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ICustomerOfferDto } from './types'
import Store from './store'
import ReactDatePicker from 'react-datepicker'
import cx from 'classnames'
import DecideIntersectingOffersPopup from './decide-intersecting-offers-popup'

interface IChangeServiceDatesPopupProps {
  CustomerOfferGridStore?: Store
  toggleModal: any
  selectedCustomerOffer: ICustomerOfferDto
  setDoesGridNeedARefresh: any
}

const ChangeServiceDatesPopup: FC<IChangeServiceDatesPopupProps> = inject('CustomerOfferGridStore')(
  observer((props: IChangeServiceDatesPopupProps) => {
    const { CustomerOfferGridStore: store, toggleModal, selectedCustomerOffer, setDoesGridNeedARefresh } = props
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()

    const [showStartDateTooltip, setShowStartDateTooltip] = useState(false)
    const [showEndDateTooltip, setShowEndDateTooltip] = useState(false)

    const [showDecideIntersectingOffersPopup, setShowDecideIntersectingOffersPopup] = useState(false)
    const [acceptedOfferAsPostParam, setAcceptedOfferAsPostParam] = useState<ICustomerOfferDto>()
    const [intersectingOffersParam, setIntersectingOffersParam] = useState<ICustomerOfferDto[]>()

    useEffect(() => {
      if (selectedCustomerOffer && selectedCustomerOffer.id != null && selectedCustomerOffer.id > 0
        && !!selectedCustomerOffer.startDate && !!selectedCustomerOffer.endDate
      ) {
        setStartDate(selectedCustomerOffer.startDate)
        setEndDate(selectedCustomerOffer.endDate)
      }
    }, [])

    const save = async () => {
      if (!selectedCustomerOffer || selectedCustomerOffer.id == null || selectedCustomerOffer.id < 1) {
        toast.error(t('Unknown Error'))
        return
      }

      if (!startDate || !endDate) {
        toast.error(t('Please fill in all fields'))
        return
      }

      if (moment(startDate).toDate() > moment(endDate).toDate()) {
        toast.error(t('Start date must be before the end date'))
        return
      }

      setIsLoading(true)

      try {
        const tmpAcceptedOfferAsPostParam = {
          ...selectedCustomerOffer,
          startDateStr: moment(startDate).format('DD-MM-YYYY'),
          endDateStr: moment(endDate).format('DD-MM-YYYY')
        } as ICustomerOfferDto

        setAcceptedOfferAsPostParam(tmpAcceptedOfferAsPostParam)

        const checkRes = await store!.checkIntersectingOffers(tmpAcceptedOfferAsPostParam) as ICustomerOfferDto[]

        setIntersectingOffersParam(checkRes)

        if (checkRes == null || checkRes.length == 0) {
          const changeServiceDatesRes = await store!.changeServiceDates(tmpAcceptedOfferAsPostParam)

          setDoesGridNeedARefresh(true)

          toggleModal()
        }
        else {
          setShowDecideIntersectingOffersPopup(true)
          setIsLoading(false)
        }
      }
      catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }

    return (
      <>
        <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
          <ModalHeader toggle={toggleModal}>{t('Change The Dates Of Service')}</ModalHeader>
          <ModalBody>
            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('Start Date') + " "}
                  <i id="idStartDate" className="fas fa-circle-info mr-2"></i>
                  <Tooltip
                    placement={'top-start'}
                    isOpen={showStartDateTooltip}
                    target={'idStartDate'}
                    toggle={() => setShowStartDateTooltip(!showStartDateTooltip)}
                  >
                    {t('The date the security service starts')}
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <ReactDatePicker
                    dateFormat={moment().format('L')}
                    locale={moment.locale()}
                    className={cx('form-control')}
                    id="startDate"
                    name="startDate"
                    value={startDate ? moment(startDate).format('L') : ''}
                    selected={startDate && moment(startDate).toDate()}
                    onChange={(date: Date) => {
                      if (date) {
                        setStartDate(moment(date).toDate())
                      }
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col-md-12" style={{ display: 'flex' }}>
                <div className="col-md-4" style={{ margin: 'auto' }}>
                  {t('End Date') + " "}
                  <i id="idEndDate" className="fas fa-circle-info mr-2"></i>
                  <Tooltip
                    placement={'top-start'}
                    isOpen={showEndDateTooltip}
                    target={'idEndDate'}
                    toggle={() => setShowEndDateTooltip(!showEndDateTooltip)}
                  >
                    {t('The date the security service ends')}
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <ReactDatePicker
                    dateFormat={moment().format('L')}
                    locale={moment.locale()}
                    className={cx('form-control')}
                    id="endDate"
                    name="endDate"
                    value={endDate ? moment(endDate).format('L') : ''}
                    selected={endDate && moment(endDate).toDate()}
                    onChange={(date: Date) => {
                      if (date) {
                        setEndDate(moment(date).toDate())
                      }
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-light mr-auto"
              onClick={toggleModal}
              icon={<i className="fas fa-times mr-2"></i>}
              text={t('Close')}
            />

            <Button
              className="btn btn-success float-right"
              onClick={save}
              icon={<i className={'far fa-save mr-2'}></i>}
              text={t('Save')}
              showConfirm={false}
            />
          </ModalFooter>
        </Modal>

        {showDecideIntersectingOffersPopup && (
          <DecideIntersectingOffersPopup
            toggleModal={() => setShowDecideIntersectingOffersPopup(!showDecideIntersectingOffersPopup)}
            toggleAcceptOfferPopup={toggleModal}
            acceptedCustomerOffer={acceptedOfferAsPostParam!}
            intersectingOffersParam={intersectingOffersParam!}
            setDoesGridNeedARefresh={setDoesGridNeedARefresh}
            isThisForChangeServiceDates={true}
          />
        )}
      </>
    )

  })
)

export default ChangeServiceDatesPopup
