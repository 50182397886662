import React, { FC, useState, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Button, ConfirmModal, Table } from '@/components'
import '@progress/kendo-ui'
import NewRiskModal from './new-risk-modal'
import { ICustomerOfferRiskAnalysisDto } from '../../../customer-offer-grid/types'

interface IStepProps {
  CustomerOfferStore?: any
  prevStep: any
  nextStep: any
  returnToOfferGrid: any
}

const Step: FC<IStepProps> = inject('CustomerOfferStore')(
  observer((props: IStepProps) => {
    const { CustomerOfferStore: store, prevStep, nextStep, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { customerOffer } = store!

    const isReadOnly = !!customerOffer.isReadOnly

    const [edit, setEdit] = useState(false)

    const [selectedRiskRow, setSelectedRiskRow] = useState<ICustomerOfferRiskAnalysisDto>({} as ICustomerOfferRiskAnalysisDto)

    const [deleteModal, setDeleteModal] = useState(false)
    const toggleDeleteModal = () => setDeleteModal(!deleteModal)

    const [newRiskModal, setNewRiskModal] = useState(false)
    const toggleNewRiskModal = () => setNewRiskModal(!newRiskModal)

    const deleteRisk = (risk: any) => {
      const index = customerOffer.riskAnalyzes.findIndex((x: any) => x.id == risk.id)

      if (index != -1) {
        customerOffer?.riskAnalyzes?.splice(index, 1)
        customerOffer!.riskAnalyzes = [...customerOffer!.riskAnalyzes!]
      }
    }

    const detailChooseDeletionMessage = () => {
      const badgeType =
        selectedRiskRow.degree.id === 1
          ? 'danger'
          : selectedRiskRow.degree.id === 2
          ? 'warning'
          : selectedRiskRow.degree.id === 3
          ? 'info'
          : 'success'

      return (
        <>
          <p>
            {t('Stage')}: <span className={`badge badge-${badgeType}`}>{selectedRiskRow.degree.name}</span>
          </p>
          <p>
            {t('Explanation')}: {selectedRiskRow.explanation}
          </p>
          <p>
            {t('Is Main Focus')}: {selectedRiskRow.isMainFocus ? 'Evet' : 'Hayır'}
          </p>
        </>
      )
    }

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                {!isReadOnly && (
                  <Button
                    icon={<i className="fas fa-pen"></i>}
                    tooltipText={t('Edit')}
                    onClick={async () => {
                      const index = customerOffer.riskAnalyzes.findIndex((x: any) => x.id == row.id)

                      if (index != -1) {
                        customerOffer.riskAnalyzes[index].isSelected = true

                        setEdit(true)
                        setNewRiskModal(true)
                      }
                    }}
                    className="btn btn-link text-warning"
                  />
                )}
                {isReadOnly && (
                  <Button
                    icon={<i className="fas fa-eye"></i>}
                    tooltipText={t('Details')}
                    onClick={async () => {
                      const index = customerOffer.riskAnalyzes.findIndex((x: any) => x.id == row.id)

                      if (index != -1) {
                        customerOffer.riskAnalyzes[index].isSelected = true

                        setEdit(true)
                        setNewRiskModal(true)
                      }
                    }}
                    className="btn btn-link"
                  />
                )}
                <Button
                  icon={<i className="fas fa-trash"></i>}
                  tooltipText={t('Delete')}
                  onClick={async () => {
                    setSelectedRiskRow(row)
                    setDeleteModal(true)
                  }}
                  className="btn btn-link text-danger"
                  disabled={isReadOnly}
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Stage'),
          id: 'degree',
          accessor: 'degree',
          width: 200,
          Cell: ({ row }: any) => {
            const { degree } = row.values

            const badgeType = degree.id === 1 ? 'danger' : degree.id === 2 ? 'warning' : degree.id === 3 ? 'info' : 'success'

            return <span className={`badge badge-${badgeType}`} dangerouslySetInnerHTML={{ __html: degree.name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Category'),
          id: 'category',
          accessor: 'category',
          Cell: ({ row }: any) => {
            const { category } = row.values

            return <span dangerouslySetInnerHTML={{ __html: category.name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Explanation'),
          id: 'explanation',
          accessor: 'explanation',
          Cell: ({ row }: any) => {
            const { explanation } = row.values

            return <span dangerouslySetInnerHTML={{ __html: explanation }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Is Main Focus'),
          id: 'isMainFocus',
          accessor: 'isMainFocus',
          Cell: ({ row }: any) => {
            const { isMainFocus } = row.values

            return <span dangerouslySetInnerHTML={{ __html: isMainFocus ? 'Evet' : 'Hayır' }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    return (
      <>
        <div className="card-header">
          <div className="btn-actions-pane-right actions-icon-btn">
            <button
              className="btn btn-primary btn-shadow flex-direction float-right"
              style={{ width: '70px', marginLeft: '10px', marginTop: '-5px' }}
              onClick={async () => {
                setNewRiskModal(true)
              }}
              disabled={isReadOnly}
            >
              <i className="fas fa-plus mr-2"></i>
              {t('Add')}
            </button>
          </div>
        </div>
        <div className="card-body">
          <Table
            smallSize={true}
            showHeaderSearchInput={false}
            columns={columns}
            totalCount={customerOffer?.riskAnalyzes.totalCount}
            data={customerOffer?.riskAnalyzes}
            fetchData={() => {}}
            isLoading={false}
            showNavigation={false}
          />
        </div>

        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isReadOnly}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
            />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-light ml-auto mr-2" onClick={prevStep}>
              &laquo; {t('Prev')}
            </button>
            <button className="btn btn-primary ml-auto" onClick={nextStep}>
              {t('Next')} &raquo;
            </button>
          </div>
        </div>

        {deleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={deleteModal}
            toggleModal={toggleDeleteModal}
            onConfirm={async () => {
              deleteRisk(selectedRiskRow)
              toggleDeleteModal()
            }}
          />
        )}
        {newRiskModal && (
          <NewRiskModal
            isEdit={edit}
            toggleModal={() => {
              if (newRiskModal) {
                const selected = customerOffer?.riskAnalyzes?.find((x: any) => x.isSelected)

                if (selected) {
                  selected.isSelected = false
                }
              }

              toggleNewRiskModal()
            }}
            isReadOnly={isReadOnly}
          />
        )}
      </>
    )
  })
)

export default Step
