import React, { useState, useEffect, FC, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import Store from './store'
import * as _ from 'lodash'
import {
  TreeList,
  TreeListToolbar,
  mapTree,
  extendDataItem,
  removeItems,
  modifySubItems,
  TreeListColumnProps,
  TreeListTextEditor,
  TreeListBooleanEditor,
  TreeListItemChangeEvent,
  TreeListExpandChangeEvent,
  TreeListTextFilter,
  filterBy,
  TreeListFilterChangeEvent,
} from '@progress/kendo-react-treelist'
import MyCommandCell from './my-command-cell'
import { IConventionalOfferDocument } from './types'
import { Button, ConfirmModal, Loading } from '@/components'
import View from './view'
import FileNameEdit from './file-name-edit'
import PreviewCell from './preview-cell'

const subItemsField: string = 'documents'
const expandField: string = 'expanded'
const editField: string = 'inEdit'

interface IPage {
  ConventionalOfferDocumentStore?: Store
  t: any
}

interface AppState {
  documents: IConventionalOfferDocument[]
  expanded: string[]
  inEdit: Array<{ id: string; isNew: boolean }>
  filter: string[]
}

const ConventionalOfferDocument: FC<IPage> = inject('ConventionalOfferDocumentStore')(
  observer((props: IPage) => {
    const { ConventionalOfferDocumentStore: store, t } = props

    const [selectedItem, setSelectedItem] = useState<IConventionalOfferDocument>()

    const [modal, setModal] = useState(false)
    const toggleModal = () => setModal(!modal)

    const [fileDisplayNameModal, setFileDisplayNameModal] = useState(false)
    const toggleFileDisplayNameModal = () => setFileDisplayNameModal(!fileDisplayNameModal)

    const [didDocsGetUpdated, setDidDocsGetUpdated] = useState(false)

    const [state, setState] = React.useState<AppState>({
      documents: [],
      expanded: ['SegmentHead'],
      inEdit: [],
      filter: [],
    })

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const toggleShowDeleteModal = () => setShowDeleteModal(!showDeleteModal)

    useEffect(() => {
      store?.getDocuments().then((tmpDocuments) => {
        setState({
          ...state,
          documents: tmpDocuments,
        })
      })
    }, [])

    useEffect(() => {
      if (didDocsGetUpdated) {
        store?.getDocuments().then((tmpDocuments) => {
          setState({
            ...state,
            documents: tmpDocuments,
          })
          setDidDocsGetUpdated(false)
        })
      }
    }, [didDocsGetUpdated])

    const add = (dataItem: IConventionalOfferDocument) => {
      setSelectedItem(dataItem)
      setModal(true)
    }

    const edit = (dataItem: IConventionalOfferDocument) => {
      setSelectedItem(dataItem)
      setModal(true)
    }

    const fileDisplayNameEdit = (dataItem: IConventionalOfferDocument) => {
      setSelectedItem(dataItem)
      setFileDisplayNameModal(true)
    }

    const remove = (dataItem: IConventionalOfferDocument) => {
      setSelectedItem(dataItem)
      setShowDeleteModal(true)
    }

    const onExpandChange = (e: TreeListExpandChangeEvent) => {
      setState({
        ...state,
        expanded: e.value ? state.expanded.filter((id) => id !== e.dataItem.id) : [...state.expanded, e.dataItem.id],
      })
    }

    const onItemChange = (event: TreeListItemChangeEvent) => {
      const field: any = event.field
      setState({
        ...state,
        documents: mapTree(state.documents, subItemsField, (item) =>
          item.id === event.dataItem.id ? extendDataItem(item, subItemsField, { [field]: event.value }) : item
        ),
      })
    }

    const detailChooseDeletionMessage = () => {
      return (
        <>
          <p>
            {t('Document Name')}: {selectedItem?.documentName}
          </p>
        </>
      )
    }

    const CheckedCell = () => {
      return class extends React.Component<any> {
        render() {
          const { dataItem } = this.props

          return dataItem.documentSize > 0 && dataItem.type === 'Segment' ? (
            <td>
              <input
                style={{ marginLeft: '20px' }}
                className="form-check-input"
                type="checkbox"
                checked={dataItem.isReferenceDocument}
                id="checkbox"
                onChange={async (event) => {
                  await store?.update(dataItem.id, event.target.checked).then((result) => {
                    setDidDocsGetUpdated(true)
                  })
                }}
              />
            </td>
          ) : (
            <td></td>
          )
        }
      }
    }

    const FileNameEditCell = () => {
      return class extends React.Component<any> {
        render() {
          const { dataItem } = this.props

          return dataItem.documentSize > 0 ? (
            <td>
              {dataItem.fileDisplayName ?? dataItem.name} {'   '}
              <Button
                icon={<i className={'fas fa-pen'}></i>}
                onClick={async () => {
                  fileDisplayNameEdit(dataItem)
                }}
                className="btn btn-link text-warning"
              />
            </td>
          ) : (
            <td></td>
          )
        }
      }
    }

    const CommandCell = MyCommandCell(add, edit, remove)

    const columns: Array<TreeListColumnProps | {}> = [
      { field: 'name', title: t('Name'), editCell: TreeListTextEditor, filterCell: TreeListTextFilter, expandable: true, width: '25%' },
      {
        cell: FileNameEditCell(),
        title: t('File Display Name'),
        width: '15%',
      },
      { field: 'documentName', title: t('Document Name'), editCell: TreeListTextEditor, filterCell: TreeListTextFilter, width: '15%' },
      { field: 'documentUrl', title: t('URL'), editCell: TreeListTextEditor, width: '10%' },
      { cell: PreviewCell(), title: t('Preview'), width: '15%' },
      {
        cell: CheckedCell(),
        title: t('Is a reference document ?'),
        width: '10%',
      },
      { cell: CommandCell, width: '10%', resizable: false },
    ]

    const css = `
      .k-svg-icon {
          cursor: pointer;
      }
      table {
        table-layout: fixed;
        width: 100%;   
      }
      
    td {
      white-space: normal !important;
    }
    td.k-table-td.k-text-nowrap {
      white-space: normal !important;
    }
    
    .k-filter-row {
      line-height: 1.4285714286!important;
    }
    .k-grid tr {
      border-color: inherit!important;
    }
    
    .k-input {
      background-color: white;
      border: 1px solid #e0dddd;
    }
      `

    const handleFilterChange = (event: TreeListFilterChangeEvent) => {
      setState({
        ...state,
        filter: event.filter as [],
      })
    }

    const processData = () => {
      const { documents, expanded } = state
      const filteredData = filterBy(documents!, state.filter as [], subItemsField)
      const positions = new Set()
      const resultTree = mapTree(filteredData, subItemsField, (item) => {
        positions.add(item.position)
        return extendDataItem(item, subItemsField, {
          [expandField]: state.documents,
          [editField]: Boolean(state.inEdit.find((i) => i.id === item.id)),
        })
      })
      return resultTree
    }

    return (
      <>
        <style>{css}</style>
        {state.documents.length === 0 ? (
          <Loading />
        ) : (
          <TreeList
            data={processData()}
            filter={state.filter as []}
            editField={editField}
            expandField={expandField}
            subItemsField={subItemsField}
            onItemChange={onItemChange}
            onExpandChange={onExpandChange}
            onFilterChange={handleFilterChange}
            columns={columns}
          />
        )}

        {modal && !!selectedItem && (
          <View selectedDocument={selectedItem!} isShow={modal} toggleModal={toggleModal} setDidDocsGetUpdated={setDidDocsGetUpdated} />
        )}

        {fileDisplayNameModal && !!selectedItem && (
          <FileNameEdit
            selectedDocument={selectedItem!}
            isShow={fileDisplayNameModal}
            toggleModal={toggleFileDisplayNameModal}
            setDidDocsGetUpdated={setDidDocsGetUpdated}
          />
        )}

        {showDeleteModal && !!selectedItem && (
          <ConfirmModal
            message={t('The document will be deleted, do you confirm?')}
            detailMessage={detailChooseDeletionMessage()}
            title={t('Warning') + '!'}
            isShow={showDeleteModal}
            toggleModal={toggleShowDeleteModal}
            onConfirm={async () => {
              await store!.delete(selectedItem.id || '')
              setDidDocsGetUpdated(true)
              toggleShowDeleteModal()
            }}
          />
        )}
      </>
    )
  })
)
export default ConventionalOfferDocument
