import * as React from 'react'

const validImage = [/\.bmp$/, /\.gif$/, /\.jpe?g$/, /\.png$/]

export default function PreviewCell() {
  return class extends React.Component<any> {
    render() {
      const { dataItem } = this.props

      const isItAValidImage = !!dataItem.documentUrl && validImage.some(curRegExp => curRegExp.test(dataItem.extension))

      return (
        <td>
          {isItAValidImage && (
            <img
              src={dataItem.documentUrl}
              alt={dataItem.documentName}
              style={{ width: '100%' }}
            />
          )}
        </td>
      )
    }
  }
}
