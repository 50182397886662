import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'

import env from '../../../config/env'
import http from '../../../services/http'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'SupportStore'

  get api() {
    http.defaults.baseURL = API_BASE_URL

    return http
  }

  constructor() {
    makeAutoObservable(this)
  }

  @action.bound async send(text: string) {
    await this.api.post(`report-problem/post`, { comment: text })
  }
}

export default Store
