import '@progress/kendo-theme-default/dist/all.scss'
import 'react-toastify/dist/ReactToastify.css'
import './assets/base.scss'
import './polyfills'
import './utils/i18n'
import 'antd/dist/antd.css'
import './assets/custom/custom.scss'
import * as serviceWorker from './serviceWorker'

import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { I18nextProvider } from 'react-i18next'
import SimpleReactLightbox from 'simple-react-lightbox'

import i18n from './utils/i18n'
import { loadStores } from './utils/stores'
import UIStore from './components/Layout/store'
import Router from './router'

import { IntlProvider, LocalizationProvider, loadMessages, load } from '@progress/kendo-react-intl'

import trMessages from 'assets/utils/tr-TR.json'
import enMessages from 'assets/utils/en-US.json'

import trCaGregorian from 'assets/utils/ca-gregorian.json'
import trDateFields from 'assets/utils/dateFields.json'

import weekData from 'assets/utils/weekData.json'

loadMessages(trMessages, 'tr-TR')
loadMessages(enMessages, 'en-US')

load(trCaGregorian, trDateFields, weekData)

const stores = {
  UIStore: new UIStore(),
  ...loadStores(),
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider {...stores}>
      <LocalizationProvider language={i18n.language === 'tr' ? 'tr-TR' : i18n.language === 'de' ? 'de-DE' : 'en-US'}>
        <IntlProvider locale={i18n.language === 'tr' ? 'tr' : i18n.language === 'de' ? 'de' : 'en'}>
          <SimpleReactLightbox>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </SimpleReactLightbox>
        </IntlProvider>
      </LocalizationProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
