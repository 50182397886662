import axios from 'axios'
import qs from 'qs'
import i18n from 'i18next'
import { toast } from 'react-toastify'

import { getToken, purgeToken } from '../../utils/token'
import env from '../../config/env'
import { purgeUser } from '../../utils/user'
import { purgePermissions } from '../../utils/permission'
const { API_BASE_URL } = env

const languages: { [key: string]: string } = {
  tr: 'tr-TR',
  en: 'en-US',
}

const http = axios.create({
  baseURL: API_BASE_URL,
  timeout: 0,
  paramsSerializer: (params: any) => qs.stringify(params, { encode: false }),
})

const { request, response } = http.interceptors

request.use(
  (req) => {
    const { headers } = req
    const commons = headers.common

    if (!commons.hasOwnProperty('Content-Type')) headers.common['Content-Type'] = 'application/json'

    const token = getToken()
    if (token && !commons.hasOwnProperty('Authorization')) headers.common['Authorization'] = token

    const lang = i18n.language
    if (lang) headers.common['Accept-Language'] = languages[lang] || 'en-US'

    return req
  },
  (err) => Promise.reject(err)
)

response.use(
  (res) => {
    if (res?.data?.state == 0 && res?.data?.message) {
      toast.error(res?.data?.message, {
        position: 'top-right',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        style: { whiteSpace: 'pre-line' },
      })

      // return Promise.reject(res?.data?.result?.message)
    } else if (res?.data?.state == 1 && res?.data?.message) {
      toast.success(res?.data?.message, {
        position: 'top-right',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        style: { whiteSpace: 'pre-line' },
      })
    } else if (res?.data?.state == 2 && res?.data?.message) {
      toast.warning(res?.data?.message, {
        position: 'top-right',
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        style: { whiteSpace: 'pre-line' },
      })
    }

    return res
  },
  (err) => {
    const { response: res } = err

    if (res?.status === 401) {
      purgeUser()
      purgeToken()
      purgePermissions()

      window.location.reload()
    }

    toast.error(res.data.message ?? (res.data || 'Unknown Error'), {
      position: 'top-right',
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      style: { whiteSpace: 'pre-line' },
    })

    return Promise.reject(err)
  }
)

export default http
