import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'

import env from '../../../../config/env'
import http from '../../../../services/http'
import { UserDto } from './types'
import IPagedResult from '@/models/dto/fetch/IPagedResult'
import { tr } from 'date-fns/locale'
import { useParams } from 'react-router-dom'

import React, { useState } from 'react'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'UserStore'
  filter!: number
  records!: IPagedResult<UserDto>

  get api() {
    http.defaults.baseURL = API_BASE_URL

    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.records = {
      items: [],
      isLoaded: false,
      totalCount: 0,
    }

    this.getRecords = this.getRecords.bind(this)
  }

  @action.bound async getRecords() {
    this.records.isLoaded = false

    const result = await (await this.api.get(`/user/get-users`)).data

    this.records.items = result
    this.records.isLoaded = true
  }

  @action.bound async saveUser(dto: UserDto) {
    const result = await this.api.post(`/user/post`, dto)

    this.getRecords()
  }

  @action.bound async deleteUser(Id: number) {
    await this.api.delete(`/user/delete?Id=` + Id)

    this.getRecords()
  }

  @action.bound async sendLoginInfo(id: number) {
    let { totalCount, items } = this.records

    const response = await this.api.post(`/user/send-login-info?id=` + id)
    if (response.data) {
      items = items.filter((item: UserDto) => item?.id !== id)
      totalCount -= 1

      this.records = { totalCount: 0, items: [], isLoaded: false }
      this.records = { totalCount, items, isLoaded: true }
    }

    this.getRecords()

    return response
  }
}

export default Store
