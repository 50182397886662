import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '../../../../../components'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { ConventionalOfferWorkingRetiredEmployerShare } from '../types'
import { toast } from 'react-toastify'

interface IView {
  ConventionalOfferWorkingRetiredEmployerShareStore?: Store
  data?: ConventionalOfferWorkingRetiredEmployerShare
  isShow: boolean
  isEdit: boolean
  toggleModal: any
  versionId: number
}

const View: FC<IView> = inject('ConventionalOfferWorkingRetiredEmployerShareStore')(
  observer((props: IView) => {
    const { ConventionalOfferWorkingRetiredEmployerShareStore: store, data, isShow, isEdit, toggleModal, versionId } = props
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const [id, setId] = useState(-1)

    const [name, setName] = useState('')
    const [isWorking, setIsWorking] = useState(true)
    const [employerShare, setEmployerShare] = useState(0)
    const [promotionBonusMultiplier, setPromotionBonusMultiplier] = useState(0)

    useEffect(() => {
      if (isEdit) {
        if (!data) {
          toast.error(t('Unknown Error'))
          return
        }
        setId(data.id)
        setName(data.name)
        setIsWorking(data.isWorking)
        setEmployerShare(data.employerShare)
        setPromotionBonusMultiplier(data.promotionBonusMultiplier)
      }
    }, [])

    const submit = async () => {
      if (name == undefined || name === '' || isWorking == undefined || employerShare == undefined || promotionBonusMultiplier == undefined) {
        toast.error(t('Please fill in all the required fields'))
        return
      }

      const dto: any = {
        id: id,
        versionId: versionId,
        name: name,
        isWorking: isWorking,
        employerShare: employerShare,
        promotionBonusMultiplier: promotionBonusMultiplier,
      }
      await store!.save(dto, versionId)
      toggleModal()
    }

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('Working/Retired Employer Share')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form className="needs-validation" noValidate>
                <div className="form-row py-3">
                  <div className="form-group w-100">
                    <label htmlFor="name" className="">
                      {t('Name')} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={(event) => {
                        setName(event.target.value)
                      }}
                      value={name}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="workingRetired" className="">
                      {t('Working/Retired')} <strong className="text-danger">*</strong>
                    </label>

                    <div id="workingRetired" className="d-flex" style={{ marginBottom: '10px', marginLeft: '0px!important' }}>
                      <div className="custom-control custom-radio px-5">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="typeRadio1"
                          name="type"
                          value="1"
                          defaultChecked={isWorking}
                          checked={isWorking}
                          onChange={(value) => {
                            if (value.target.checked) {
                              setIsWorking(true)
                            }
                          }}
                        />
                        <label className="custom-control-label" htmlFor="typeRadio1">
                          {t('Employee')}
                        </label>
                      </div>
                      <div className="custom-control custom-radio px-5">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="typeRadio0"
                          name="type"
                          value="0"
                          checked={!isWorking}
                          onChange={(value) => {
                            if (value.target.checked) {
                              setIsWorking(false)
                            }
                          }}
                        />
                        <label className="custom-control-label" htmlFor="typeRadio0">
                          {t('Retired')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="employerShare" className="">
                      {t('Employer Share')} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="employerShare"
                      id="employerShare"
                      type="number"
                      defaultValue={employerShare}
                      onChange={(event) => {
                        const { value } = event.target
                        setEmployerShare(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="promotionBonusMultiplier" className="">
                      {t('Promotion Bonus Multiplier')} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="promotionBonusMultiplier"
                      id="promotionBonusMultiplier"
                      type="number"
                      defaultValue={promotionBonusMultiplier}
                      onChange={(event) => {
                        const { value } = event.target
                        setPromotionBonusMultiplier(Number(value))
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
