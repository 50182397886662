import React, { useState, useEffect, FC, ChangeEvent, useMemo, useRef } from 'react'
import { inject, observer } from 'mobx-react'

import Store from './store'
import { useForm } from 'react-hook-form'

import XLSX from 'xlsx'
import { toast } from 'react-toastify'

import { Button } from '../../../components'

interface IPage {
  SupportStore?: Store
  t: any
}

const Page: FC<IPage> = inject('SupportStore')(
  observer((props: IPage) => {
    const { SupportStore: store, t } = props
    const { handleSubmit } = useForm()

    const [text, setText] = useState<string>('')
    const [isSent, setIsSent] = useState<boolean>(false)

    const submit = async () => {
      if (text === '') {
        toast.error(t('Please fill out the form'))
        return
      }

      await store!.send(text)

      setIsSent(true)
      toast.success(t('Your message is sent successfully! We will contact you as soon as possible.'))
    }

    return (
      <div className="col-12">
        <form className="needs-validation" noValidate>
          <div className="form-row py-3">
            <div className="col-md-12 justify-content-md-center ">
              <div className="form-group">
                <div className="col-md-12 py-3">
                  <label className="font-weight-bold" htmlFor="reasonForRequest">
                    {t('Are you experiencing a problem?')} <strong className="text-danger">*</strong>
                  </label>
                  <div>
                    <textarea
                      className="form-control"
                      name="reasonForRequest"
                      id="reasonForRequest"
                      placeholder={t('Please describe your problem in detail')}
                      cols={30}
                      minLength={15}
                      rows={5}
                      disabled={isSent}
                      onChange={(event) => {
                        setText(event.target.value)
                      }}
                    ></textarea>
                  </div>
                  <strong className="text-danger">* {t('We will contact you as soon as possible')}</strong>
                  <div className="mt-3" style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                    <Button
                      className="btn btn-primary"
                      onClick={handleSubmit(async (data: any) => await submit())}
                      icon={<i className="far fa-envelope mr-2"></i>}
                      text={t('Send')}
                      disabled={isSent}
                      showConfirm={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  })
)

export default Page
