import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import { Button, Loading } from '@/components'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ICustomerOfferDto, ICustomerOfferSolutionDto } from './types'
import Store from './store'
import ReactDatePicker from 'react-datepicker'
import cx from 'classnames'
import DecideIntersectingOffersPopup from './decide-intersecting-offers-popup'

interface IAcceptOfferPopupProps {
  CustomerOfferGridStore?: Store
  toggleModal: any
  selectedCustomerOffer: ICustomerOfferDto
  setDoesGridNeedARefresh: any
}

const AcceptOfferPopup: FC<IAcceptOfferPopupProps> = inject('CustomerOfferGridStore')(
  observer((props: IAcceptOfferPopupProps) => {
    const { CustomerOfferGridStore: store, toggleModal, selectedCustomerOffer, setDoesGridNeedARefresh } = props
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [acceptedOffer, setAcceptedOffer] = useState<ICustomerOfferDto>()
    const [tmpSolutionList, setTmpSolutionList] = useState<ICustomerOfferSolutionDto[]>([])

    const [showStartDateTooltip, setShowStartDateTooltip] = useState(false)
    const [showEndDateTooltip, setShowEndDateTooltip] = useState(false)

    const [showDecideIntersectingOffersPopup, setShowDecideIntersectingOffersPopup] = useState(false)
    const [acceptedOfferAsPostParam, setAcceptedOfferAsPostParam] = useState<ICustomerOfferDto>()
    const [intersectingOffersParam, setIntersectingOffersParam] = useState<ICustomerOfferDto[]>()

    useEffect(() => {
      if (selectedCustomerOffer && selectedCustomerOffer.id != null && selectedCustomerOffer.id > 0) {
        setIsLoading(true)

        store!.getCustomerOffer(selectedCustomerOffer.id).then((customerOffer: ICustomerOfferDto) => {
          setAcceptedOffer({
            ...customerOffer,
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(new Date().getFullYear(), 11, 31),
          } as ICustomerOfferDto)

          if (customerOffer.solutionList) {
            setTmpSolutionList(customerOffer.solutionList.map((x) => ({ ...x } as ICustomerOfferSolutionDto)))
          }

          setIsLoading(false)
        })
      }
    }, [])

    const save = async () => {
      if (!acceptedOffer || acceptedOffer.id == null || acceptedOffer.id < 1) {
        toast.error(t('Unknown Error'))
        return
      }

      if (
        !acceptedOffer.startDate ||
        !acceptedOffer.endDate ||
        (tmpSolutionList.length > 1 && tmpSolutionList.findIndex((x) => x.isAccepted === true) == -1)
      ) {
        toast.error(t('Please fill in all fields'))
        return
      }

      if (moment(acceptedOffer.startDate).toDate() > moment(acceptedOffer.endDate).toDate()) {
        toast.error(t('Start date must be before the end date'))
        return
      }

      setIsLoading(true)

      try {
        const tmpAcceptedOfferAsPostParam = {
          ...acceptedOffer,
          solutionList: [...tmpSolutionList],
          startDateStr: moment(acceptedOffer.startDate).format('DD-MM-YYYY'),
          endDateStr: moment(acceptedOffer.endDate).format('DD-MM-YYYY'),
          startDate: moment(acceptedOffer.startDate).format('YYYY-MM-DD'),
          endDate: moment(acceptedOffer.endDate).format('YYYY-MM-DD'),
        } as ICustomerOfferDto

        setAcceptedOfferAsPostParam(tmpAcceptedOfferAsPostParam)

        const checkRes = (await store!.checkIntersectingOffers(tmpAcceptedOfferAsPostParam)) as ICustomerOfferDto[]

        setIntersectingOffersParam(checkRes)

        if (checkRes == null || checkRes.length == 0) {
          const acceptRes = await store!.accept(tmpAcceptedOfferAsPostParam)

          setDoesGridNeedARefresh(true)

          toggleModal()
        } else {
          setShowDecideIntersectingOffersPopup(true)
          setIsLoading(false)
        }
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }

    const SolutionRadioInputs = () => {
      if (tmpSolutionList.length < 2) {
        return null
      }

      return (
        <>
          <div className="row" style={{ marginBottom: '10px' }}>
            <div className="col-md-12" style={{ display: 'flex', fontWeight: 'bold' }}>
              {t('Please choose the accepted solution')}
            </div>
          </div>

          {tmpSolutionList.map((solution) => (
            <div key={'div_row_' + solution.id} className="row" style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingLeft: '5px' }}>
                  <div className="custom-control custom-radio">
                    <input
                      key={'input_' + solution.id}
                      type="radio"
                      id={'radio_' + solution.id}
                      name="acceptedSolution"
                      value={solution.id}
                      checked={solution.isAccepted === true}
                      onChange={(value) => {
                        if (value.target.checked) {
                          let tmpTmpSolutionList = [...tmpSolutionList]
                          tmpTmpSolutionList.forEach((x) => (x.isAccepted = false))
                          let tmpSolution = { ...solution, isAccepted: true } as ICustomerOfferSolutionDto
                          const idx = tmpTmpSolutionList.findIndex((x) => x.id == tmpSolution.id)

                          if (idx != -1) {
                            tmpTmpSolutionList.splice(idx, 1, tmpSolution)
                            setTmpSolutionList(tmpTmpSolutionList)
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ paddingLeft: '10px' }}>{solution.name}</div>
              </div>
            </div>
          ))}
        </>
      )
    }

    return (
      <>
        <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
          <ModalHeader toggle={toggleModal}>{t('Accept Offer')}</ModalHeader>
          {isLoading ? (
            <Loading />
          ) : (
            <ModalBody>
              <SolutionRadioInputs />

              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('Start Date') + ' '}
                    <i id="idStartDate" className="fas fa-circle-info mr-2"></i>
                    <Tooltip
                      placement={'top-start'}
                      isOpen={showStartDateTooltip}
                      target={'idStartDate'}
                      toggle={() => setShowStartDateTooltip(!showStartDateTooltip)}
                    >
                      {t('The date the security service starts')}
                    </Tooltip>
                  </div>
                  <div className="col-md-8">
                    <ReactDatePicker
                      dateFormat={moment().format('L')}
                      locale={moment.locale()}
                      className={cx('form-control')}
                      id="startDate"
                      name="startDate"
                      value={acceptedOffer?.startDate ? moment(acceptedOffer.startDate).format('L') : ''}
                      selected={acceptedOffer?.startDate && moment(acceptedOffer.startDate).toDate()}
                      onChange={(date: Date) => {
                        if (date) {
                          setAcceptedOffer({ ...acceptedOffer, startDate: moment(date).toDate() } as ICustomerOfferDto)
                        }
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ display: 'flex' }}>
                  <div className="col-md-4" style={{ margin: 'auto' }}>
                    {t('End Date') + ' '}
                    <i id="idEndDate" className="fas fa-circle-info mr-2"></i>
                    <Tooltip
                      placement={'top-start'}
                      isOpen={showEndDateTooltip}
                      target={'idEndDate'}
                      toggle={() => setShowEndDateTooltip(!showEndDateTooltip)}
                    >
                      {t('The date the security service ends')}
                    </Tooltip>
                  </div>
                  <div className="col-md-8">
                    <ReactDatePicker
                      dateFormat={moment().format('L')}
                      locale={moment.locale()}
                      className={cx('form-control')}
                      id="endDate"
                      name="endDate"
                      value={acceptedOffer?.endDate ? moment(acceptedOffer.endDate).format('L') : ''}
                      selected={acceptedOffer?.endDate && moment(acceptedOffer.endDate).toDate()}
                      onChange={(date: Date) => {
                        if (date) {
                          setAcceptedOffer({ ...acceptedOffer, endDate: moment(date).toDate() } as ICustomerOfferDto)
                        }
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
          )}
          <ModalFooter>
            <Button
              className="btn btn-light mr-auto"
              onClick={toggleModal}
              icon={<i className="fas fa-times mr-2"></i>}
              text={t('Close')}
              disabled={isLoading}
            />

            <Button
              className="btn btn-success float-right"
              onClick={save}
              icon={<i className={'far fa-save mr-2'}></i>}
              text={t('Save')}
              showConfirm={false}
              disabled={isLoading}
            />
          </ModalFooter>
        </Modal>

        {showDecideIntersectingOffersPopup && (
          <DecideIntersectingOffersPopup
            toggleModal={() => setShowDecideIntersectingOffersPopup(!showDecideIntersectingOffersPopup)}
            toggleAcceptOfferPopup={toggleModal}
            acceptedCustomerOffer={acceptedOfferAsPostParam!}
            intersectingOffersParam={intersectingOffersParam!}
            setDoesGridNeedARefresh={setDoesGridNeedARefresh}
            isThisForChangeServiceDates={false}
          />
        )}
      </>
    )
  })
)

export default AcceptOfferPopup
