import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  ChangeEventHandler,
  ForwardedRef,
  InputHTMLAttributes,
} from 'react'
import Cleave from 'cleave.js/react'

const keyRegex = /^(Backspace)|((Numpad|Digit)[0-9]{1})/i

interface ICreditCardInput extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: ChangeEventHandler<HTMLInputElement>
  ref?: any
}

const CreditCardInput = forwardRef((props: ICreditCardInput, ref: ForwardedRef<HTMLInputElement> | null) => {
  const { onChange, placeholder = '**** **** **** ****', ...rest } = props
  const [changeEvent, setChangeEvent] = useState<ChangeEvent<HTMLInputElement>>()

  let inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef?.current!)

  let clockTimeout: any = 0

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist()

    if (clockTimeout !== 0) clearTimeout(clockTimeout)
    setChangeEvent(event)

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {}, 1000)
  }

  const keyboardHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (clockTimeout !== 0) clearTimeout(clockTimeout)
    if (!keyRegex.test(event.code)) event.preventDefault()

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {
      onChange && onChange(changeEvent!)
    }, 1000)
  }

  const clipboardHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    if (clockTimeout !== 0) clearTimeout(clockTimeout)

    // set a new clock ( timeout )
    clockTimeout = setTimeout(() => {
      onChange && onChange(changeEvent!)
    }, 1000)
  }

  return (
    <Cleave
      {...rest}
      htmlRef={(r) => (inputRef.current = r)}
      placeholder={placeholder}
      options={{ blocks: [4, 4, 4, 4] }}
      onChange={changeHandler}
      onKeyUp={keyboardHandler}
      onKeyPress={keyboardHandler}
      onPaste={clipboardHandler}
    />
  )
})

export default CreditCardInput
