import React, { useState, useEffect, FC, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import * as _ from 'lodash'
import { Button, ConfirmModal, Table } from '../../../../../components'
import Store from './store'
import View from './view'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'

import { ConventionalOfferOutfit } from '../types'

interface IPage {
  ConventionalOfferOutfitStore?: Store
  versionId: number
  disabled: boolean
}

const Page: FC<IPage> = inject('ConventionalOfferOutfitStore')(
  observer((props: IPage) => {
    const { ConventionalOfferOutfitStore: store, versionId, disabled } = props
    const { records } = store!
    const { t } = useTranslation()
    const [selectedRow, selectRow] = useState<ConventionalOfferOutfit>({} as ConventionalOfferOutfit)

    const [edit, setEdit] = useState(false)

    const [modal, setModal] = useState(false)

    const toggleModal = () => setModal(!modal)

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                <Button
                  icon={<i className="fas fa-pen"></i>}
                  tooltipText={t('Edit')}
                  disabled={disabled}
                  onClick={async () => {
                    selectRow(row)
                    setEdit(true)
                    setModal(true)
                  }}
                  className="btn btn-link text-warning"
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'name',
          accessor: 'name',
          Cell: ({ row }: any) => {
            const { name } = row.values

            return <span dangerouslySetInnerHTML={{ __html: name }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Cost'),
          id: 'cost',
          accessor: 'cost',
          Cell: ({ row }: any) => {
            const { cost } = row.values

            return <span dangerouslySetInnerHTML={{ __html: `₺${numeral(cost).format('0.0[,]00')}` }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: 'Minimum',
          id: 'minimum',
          accessor: 'minimum',
          Cell: ({ row }: any) => {
            const { minimum } = row.values

            return <span dangerouslySetInnerHTML={{ __html: `₺${numeral(minimum).format('0.0[,]00')}` }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Recommended'),
          id: 'recommended',
          accessor: 'recommended',
          Cell: ({ row }: any) => {
            const { recommended } = row.values

            return <span dangerouslySetInnerHTML={{ __html: `₺${numeral(recommended).format('0.0[,]00')}` }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Explanation'),
          id: 'explanation',
          accessor: 'explanation',
          Cell: ({ row }: any) => {
            const { explanation } = row.values

            return <span dangerouslySetInnerHTML={{ __html: explanation }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    return (
      <>
        <div className="card-body">
          <div className="card-header">
            <div className="row">{t('Outfit')}</div>
          </div>

          <br></br>

          <Table
            smallSize={true}
            columns={columns}
            totalCount={records.totalCount}
            data={records.items}
            fetchData={(x) => store?.getRecords(x, versionId)}
            isLoading={!records.isLoaded}
          />
        </div>

        {modal && <View data={selectedRow!} isShow={modal} isEdit={edit} toggleModal={toggleModal} versionId={versionId} />}
      </>
    )
  })
)

export default Page
