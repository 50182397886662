import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'
import qs from 'qs'
import http from '@/services/http'

import IPagedResult from '@/models/dto/fetch/IPagedResult'
import IPagedRequest from '@/models/dto/fetch/IPagedRequest'

import env from '@/config/env'
import { ConventionalOfferVersionDto } from './types'
import { debug } from 'console'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'ConventionalOfferVersionStore'
  records!: IPagedResult<ConventionalOfferVersionDto>
  filter!: number

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.records = {
      totalCount: 0,
      items: [],
      isLoaded: false,
    }

    this.getRecords = this.getRecords.bind(this)
  }

  @action.bound async getRecords(params: IPagedRequest) {
    this.records.isLoaded = false
    const q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })

    const result = await (await this.api.get(`/api/app/conventional-offer-version/all${q}`)).data

    this.records = { ...result, isLoaded: true }
  }

  @action.bound async getVersionsAsList() {
    const result = await this.api.get(`/api/app/conventional-offer-version/versions-as-list`)

    return result.data
  }

  @action.bound async save(dto: ConventionalOfferVersionDto, templateVersionId?: number) {
    const param = templateVersionId == undefined ? `` : `?templateVersionId=${templateVersionId}`

    const result = await this.api.post(`/api/app/conventional-offer-version${param}`, dto)

    if (result.status == 200) {
      this.getRecords({
        skipCount: 0,
        maxResultCount: 5,
      })
    }

    return result.data
  }

  @action.bound async delete(id: number) {
    const result = await this.api.delete(`/api/app/conventional-offer-version/${id}`)

    if (result.status == 200) {
      this.getRecords({
        skipCount: 0,
        maxResultCount: 5,
      })
    }
  }

  @action.bound async lockVersion(id: number) {
    const result = await this.api.put(`/api/app/conventional-offer-version/lock/${id}`)

    if (result.status == 200) {
      this.getRecords({
        skipCount: 0,
        maxResultCount: 5,
      })
    }
  }

  @action.bound async getSingleVersion(id: number) {
    const result = await this.api.get(`/api/app/conventional-offer-version/single-version?id=${id}`)

    return result.data
  }

  @action.bound async toggleVersionObsolete(id: number) {
    const result = await this.api.put(`/api/app/conventional-offer-version/toggle-obsolete/${id}`)

    if (result.status == 200) {
      this.getRecords({
        skipCount: 0,
        maxResultCount: 5,
      })
    }
  }
}

export default Store
