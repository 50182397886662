import IRoute from '@/models/dto/route/IRoute'
import { lazy } from 'react'

const index = 4

export { index }

const routes: IRoute[] = [
  {
    path: '/support-request',
    title: 'Support Request',
    icon: 'pe-7s-headphones',
    index: 1,
    showInMenu: true,
    single: true,
    permission: 'SupportRequest',
    component: lazy(() => import('./index')),
  },
]

export default routes
