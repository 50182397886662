import React, { FC, useState, useRef, MutableRefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Button } from '@/components'
import { IConventionalOfferDocument } from './types'
import { IFileCreateDto } from '@/models/dto/file'
import { IUploadedFileDto } from '../conventional-offer-admin/types'

interface IView {
  ConventionalOfferDocumentStore?: Store
  selectedDocument: IConventionalOfferDocument
  isShow: boolean
  toggleModal: any
  setDidDocsGetUpdated: any
}

const View: FC<IView> = inject('ConventionalOfferDocumentStore')(
  observer((props: IView) => {
    const { ConventionalOfferDocumentStore: store, selectedDocument, isShow, toggleModal, setDidDocsGetUpdated } = props
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm()

    const [isLoading, setIsLoading] = useState(true)
    const [fileList, setFileList] = useState<IUploadedFileDto[]>([])
    const [editedDocument, setEditedDocument] = useState<IConventionalOfferDocument | undefined>(undefined)

    useEffect(() => {
      store?.getDocument(selectedDocument.unitId || selectedDocument.segmentId || 0, selectedDocument.type || '').then((tmpDocument) => {
        setEditedDocument(tmpDocument)

        setFileList(
          (tmpDocument.documents || []).map(
            (tmpSubDocument) =>
              ({
                fileName: tmpSubDocument.documentName,
                fileUrl: tmpSubDocument.documentUrl,
                fileSize: tmpSubDocument.documentSize,
                extension: tmpSubDocument.extension,
              } as IUploadedFileDto)
          )
        )

        setIsLoading(false)
      })
    }, [])

    const submit = async () => {
      const dto: IConventionalOfferDocument = {
        ...editedDocument,
        documents: (fileList || []).map(
          (tmpFile) =>
            ({
              documentName: tmpFile.fileName,
              documentUrl: tmpFile.fileUrl,
              documentSize: tmpFile.fileSize,
              extension: tmpFile.extension,
              isReferenceDocument: false,
            } as IConventionalOfferDocument)
        ),
      }

      await store!.save(dto)
      setDidDocsGetUpdated(true)
      toggleModal()
    }

    return (
      <Modal isOpen={isShow} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('Edit')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form className="needs-validation" noValidate>
                <div className="form-group col-sm-6">
                  <h6 className="w-100 font-weight-bold">
                    {t('Documents')}
                    <button
                      type="button"
                      className="btn btn-sm btn-primary shadow ml-3"
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault()

                        const input = document.createElement('input')
                        input.type = 'file'
                        input.accept = 'image/png, image/gif, image/jpeg'
                        input.multiple = true
                        input.onchange = async (event) => {
                          const { files } = event.target as any
                          const fileCreateDto: IFileCreateDto = {
                            files: files,
                            moduleName: 'ConventionalOfferDocument',
                          }
                          setIsLoading(true)
                          store
                            ?.uploadFile(fileCreateDto)
                            .then((data: any) => {
                              setIsLoading(false)
                              const tmpFileList = _.concat(fileList, data.data)
                              setFileList(tmpFileList)
                            })
                            .catch((error: any) => {
                              setIsLoading(false)
                            })
                        }
                        input.click()
                      }}
                    >
                      <i className={'fas fa-' + (isLoading ? 'spinner fa-spin' : 'arrow-up') + ' mr-2'}></i>
                      {isLoading ? '' : t('Upload')}
                    </button>
                  </h6>

                  <div className="file-area">
                    {fileList.length > 0 &&
                      _.map(fileList, (file, i: number) => (
                        <span key={i}>
                          {file && (
                            <div className="input-group d-inline-flex w-auto mb-3">
                              <button
                                id="back-button"
                                className={'btn btn-light btn-shadow ' + (i > 0 ? 'ml-3' : '')}
                                onClick={(e) => {
                                  e.preventDefault()
                                  window.open(file.fileUrl, '_blank')
                                }}
                              >
                                {file.fileName}
                              </button>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-light btn-shadow"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const tmpFileList = fileList.filter((file: any, index: number) => !_.isEqual(i, index))
                                    setFileList(tmpFileList)
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          )}
                        </span>
                      ))}
                    {fileList.length === 0 && (
                      <>
                        <p>{t('Please attach document')}.</p>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
