import React, { FC, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { Button } from '@/components'
import { toast } from 'react-toastify'
import { ICustomerOfferDto } from '../../../customer-offer-grid/types'
import PdfTypePopup from '../../../customer-offer-grid/pdf-type-popup'

interface IStepProps {
  CustomerOfferStore?: any
  prevStep: any
  returnToOfferGrid: any
}

const Step: FC<IStepProps> = inject('CustomerOfferStore')(
  observer((props: IStepProps) => {
    const { CustomerOfferStore: store, prevStep, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { customerOffer, offerServiceDocuments } = store!

    const isReadOnly = !!customerOffer.isReadOnly

    const [isLoading, setIsLoading] = useState(false)

    const [showPdfTypePopup, setShowPdfTypePopup] = useState(false)

    const save = async () => {
      if (
        customerOffer.isItCandidateCustomer
          ? !customerOffer.candidateCustomer
          : (customerOffer.selectedUnits || []).length == 0 || !customerOffer.selectedProject
      ) {
        toast.error(t('Please select a customer'))
        return -1
      }

      if (!customerOffer.solutionList || customerOffer.solutionList.length == 0) {
        toast.error(t('Please create a solution'))
        return -1
      }

      if (!customerOffer.emailAddressesTo || customerOffer.emailAddressesTo == '') {
        toast.error(t('Please enter an email address for TO align'))
        return -1
      }

      const dto: ICustomerOfferDto = {
        id: customerOffer?.id || -1,
        isItCandidateCustomer: customerOffer.isItCandidateCustomer === true,
        candidateCustomer: customerOffer.isItCandidateCustomer ? customerOffer.candidateCustomer : undefined,
        solutionList: customerOffer.solutionList,
        riskAnalyzes: customerOffer.riskAnalyzes,
        offerServiceDocuments: offerServiceDocuments,
        offerServiceDocumentsFlat: customerOffer.offerServiceDocumentsFlat,
        fileList: customerOffer.fileList,
        emailAddressesTo: customerOffer.emailAddressesTo,
        emailAddressesCc: customerOffer.emailAddressesCc,
        detectionsAndSolutions: customerOffer.detectionsAndSolutions,
        selectedUnits: customerOffer.isItCandidateCustomer ? undefined : customerOffer.selectedUnits,
        selectedProject: customerOffer.isItCandidateCustomer ? undefined : customerOffer.selectedProject,
      }

      const res = await store!.saveCustomerOffer(dto)

      return res
    }

    return (
      <>
        {!isReadOnly && (
          <div className="row">
            <div className="col-md-12">
              <p>{t('You need to save the offer to see changes')}</p>
            </div>
          </div>
        )}
        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isReadOnly}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
              disabled={isLoading}
            />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-light ml-auto mr-2" onClick={prevStep} disabled={isLoading}>
              &laquo; {t('Prev')}
            </button>
            <Button
              className="btn btn-primary mr-2"
              onClick={async () => {
                setShowPdfTypePopup(true)
              }}
              text={t('Preview')}
              showConfirm={!isReadOnly}
              confirmText={t('You need to save the offer to see changes. Do you want to continue without saving?')}
              disabled={isLoading || customerOffer?.id == undefined || customerOffer?.id == 0}
            />
            <Button
              className="btn btn-success"
              onClick={async () => {
                setIsLoading(true)

                const res = await save()

                if (res == -1) {
                  // do nothing
                } else if (res.status == 200) {
                  customerOffer.id = res.data

                  toast.success(t('Successfully saved'))
                } else {
                  toast.error(t('An error occurred while saving the customer offer'))
                }

                setIsLoading(false)
              }}
              text={t('Save')}
              showConfirm={true}
              disabled={isReadOnly || isLoading}
            />
          </div>
        </div>

        {showPdfTypePopup && (
          <PdfTypePopup
            toggleModal={() => setShowPdfTypePopup(!showPdfTypePopup)}
            selectedCustomerOffer={customerOffer}
            isEmailConfirmation={false}
            isPdfPreview={true}
          />
        )}
      </>
    )
  })
)

export default Step
