import React, { FC, useState, useMemo, ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import matchSorter from 'match-sorter'
import cx from 'classnames'
import * as _ from 'lodash'

import Button from '../Button'
import Input from '../Input'

interface IFilterProps {
  columns: any[]
  pageSize?: Number
  onChangeSearchInput?: ChangeEventHandler<HTMLInputElement>
  onExport?: any
}

const InputFilter: FC<{ column: any }> = ({ column }: any) => {
  const { filterValue, setFilter } = column
  const { t } = useTranslation()

  return (
    <input
      className="form-control form-control-sm"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`${t('Search')}...`}
    />
  )
}

const SelectColumnFilter: FC<{ column: any }> = ({ column }: any) => {
  const { filterValue, setFilter, preFilteredRows, id } = column
  const { t } = useTranslation()

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    let values: string[] = []

    preFilteredRows.forEach((row: any) => {
      values = [...values, row.values[id]]
    })

    return _.uniq(values)
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      className="custom-select custom-select-sm"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">{t('All')}</option>
      {_.map(options, (option: string, i: number) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
const NumberRangeColumnFilter: FC<{ column: any }> = ({ column }: { column: any }) => {
  const { filterValue = [], setFilter } = column
  const { t } = useTranslation()

  /*
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0

    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })

    return [min, max]
  }, [id, preFilteredRows])
  */

  return (
    <div className="d-flex">
      <input
        className="form-control form-control-sm"
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value
          setFilter((old: number[] = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={t('Min.')}
      />
      <input
        className="form-control form-control-sm ml-2"
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value
          setFilter((old: number[] = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={t('Max.')}
      />
    </div>
  )
}

const Filter: FC<IFilterProps> = (props: IFilterProps) => {
  const { onChangeSearchInput, columns, onExport } = props
  const { t } = useTranslation()

  const [showFilters, setShowFilters] = useState<boolean>(false)

  return (
    <div className="row mb-3">
      <div className="col-md-12">
        <div className="row">
          <div className="d-flex col mr-auto">
            {onExport && <Button className="btn btn-success" icon={<i className="fas fa-file-excel mr-2"></i>} text="Excel" onClick={onExport} />}
          </div>
          <div className="d-flex col ml-auto">
            <button className="btn btn-light d-none" onClick={() => setShowFilters(!showFilters)} disabled>
              <i className="fas fa-filter"></i>
            </button>
            {onChangeSearchInput && (
              <Input.Search className="form-control ml-2" placeholder={t('Search in records...')} onChange={onChangeSearchInput} />
            )}
          </div>
        </div>
        <div
          className={cx('row mt-4 mb-3', {
            'd-none': !showFilters,
          })}
        >
          <div className="col-md-6 ml-auto">
            <ul className="list-group">
              {_.map(
                columns,
                (column, i: number) =>
                  column.canFilterable && (
                    <li className="list-group-item" key={i}>
                      <div className="row d-flex align-items-center">
                        <div className="col-md-4">
                          <strong>{column.render('Header')}</strong>
                        </div>
                        <div className="col-md-8">
                          {column.filterType === 'select' ? (
                            <SelectColumnFilter column={column} />
                          ) : column.filterType === 'numberRange' ? (
                            <NumberRangeColumnFilter column={column} />
                          ) : (
                            <InputFilter column={column} />
                          )}
                        </div>
                      </div>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const fuzzyTextFilterFn = (rows: any[], id: string, filterValue: string) =>
  matchSorter(rows, filterValue, {
    keys: [
      (row: any) => {
        row = Object.assign({}, row)
        row = Object.assign({}, row.original)
        return row[id]
      },
    ],
  })

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val

const filterTypes = () => ({
  fuzzyText: fuzzyTextFilterFn,
})

export default Filter

export { filterTypes }
