import React, { FC, useState, useMemo, useCallback } from 'react'
import { Switch, Route, Redirect as RouterRedirect, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { registerLocale } from 'react-datepicker'
import moment from 'moment'
import numeral from 'numeral'
import * as _ from 'lodash'

import { ProtectedRoute, PublicRoute } from './components'
import SessionRefreshing from './pages/SessionRefreshing'
import Login from './modules/Account/pages/Login/index'
import env from './config/env'

import http from './services/http'
import { getToken } from './utils/token'
import { getPermissions } from './utils/permission'
import { loadRoutes } from './utils/routes'

const routes = loadRoutes()

interface IState {
  token: string | null
  isAuthorized: undefined | boolean
  permissions: string[]
}
const { API_BASE_URL } = env

const Routers: FC = (props: any) => {
  const { location, history } = props
  const { i18n } = useTranslation()
  http.defaults.baseURL = API_BASE_URL
  const [state, setState] = useState<IState>({
    token: getToken(),
    isAuthorized: getToken() ? true : false,
    // permissions: [],
    permissions: getPermissions(),
  })
  const isIframe = useMemo(() => window.frameElement || window.self !== window.top || window.location !== window.parent.location, [])

  const initLocalization = () => {
    if (i18n.language === 'en') return

    try {
      const langFile = require('date-fns/locale/' + i18n.language)
      registerLocale(i18n.language, langFile.default)

      require('moment/locale/' + i18n.language)
      moment.locale(i18n.language)

      numeral.register('locale', i18n.language, {
        delimiters: {
          thousands: '.',
          decimal: ',',
        },
        abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't',
        },
        ordinal: (number: number) => '',
        currency: {
          symbol: '₺',
        },
      })

      numeral.locale(i18n.language)
    } catch (error) {}
  }

  const mapRoutes: any = useCallback(
    (moduleRoutes: any[], routes: any[]) =>
      _.map(moduleRoutes, (route: any) => {
        if (!isIframe && !state.isAuthorized) return <></>

        if (route.children) return mapRoutes(route.children, routes)

        if (isIframe) return <PublicRoute exact path={route.path} location={location} history={history} routes={routes} {...route} key={route.path} />

        const isGranted = state.isAuthorized && route.permission && _.indexOf(state.permissions, route.permission) >= 0

        if (isGranted)
          return <ProtectedRoute exact path={route.path} location={location} history={history} routes={routes} {...route} key={route.path} />

        return <Route exact path="/login" component={Login} />
      }),
    [history, isIframe, location, state.isAuthorized, state.permissions]
  )

  if (location.pathname === '/login')
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
      </Switch>
    )

  if (state.isAuthorized === false) {
    return (
      <Switch>
        <Route exact path={location.pathname} component={Login} />
      </Switch>
    )
  }

  return (
    <Switch>
      {_.map(routes, (module: any) => mapRoutes(module.routes, routes))}
      {state.isAuthorized && (
        <ProtectedRoute
          path="/refresh-session"
          location={location}
          history={history}
          routes={routes}
          component={SessionRefreshing}
          useLayout={false}
        />
      )}
      <Route exact path={location.pathname} component={Login} />
    </Switch>
  )
}

export default withRouter(Routers)
