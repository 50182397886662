import React, { FC, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Combobox } from 'react-widgets/cjs'
import { ICustomerDto } from './types'
import { IIdNameDTO, IProjectDto, IProjectUnitDetailedDto } from '../../../customer-offer-grid/types'
import { toast } from 'react-toastify'
import { Button } from '@/components'

interface IStepProps {
  CustomerOfferStore?: any
  prevStep: any
  nextStep: any
  returnToOfferGrid: any
}

const Step: FC<IStepProps> = inject('CustomerOfferStore')(
  observer((props: IStepProps) => {
    const { CustomerOfferStore: store, prevStep, nextStep, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { regions, branches, candidateBranches, projects, units, candidateCustomers, customerOffer } = store!

    const isReadOnly = !!customerOffer.isReadOnly

    const [isLoading, setIsLoading] = useState(false)

    const [isCandidate, setIsCandidate] = useState<boolean>()

    const [existRegion, setExistRegion] = useState<IIdNameDTO>()
    const [existBranch, setExistBranch] = useState<IIdNameDTO>()
    const [existProject, setExistProject] = useState<IProjectDto>()
    const [existSelectedUnits, setExistSelectedUnits] = useState<IProjectUnitDetailedDto[]>()
    const [existSelectedProject, setExistSelectedProject] = useState<IProjectDto>()

    const [candidateRegion, setCandidateRegion] = useState<IIdNameDTO>()
    const [candidateBranch, setCandidateBranch] = useState<IIdNameDTO>()
    const [candidateCustomerList, setCandidateCustomerList] = useState<ICustomerDto[]>()
    const [selectedCandidateCustomer, setSelectedCandidateCustomer] = useState<ICustomerDto>()

    useEffect(() => {
      setIsCandidate(customerOffer.isItCandidateCustomer)

      if (customerOffer.isItCandidateCustomer === true) {
        setCandidateRegion(customerOffer?.candidateCustomer?.region)
        setCandidateBranch(customerOffer?.candidateCustomer?.branch)
        setSelectedCandidateCustomer(customerOffer?.candidateCustomer)
      } else {
        setExistSelectedProject(customerOffer?.selectedProject)
        setExistSelectedUnits(customerOffer?.selectedUnits)
      }
    }, [])

    useEffect(() => {
      customerOffer.isItCandidateCustomer = isCandidate
    }, [isCandidate])

    useEffect(() => {
      store!.getBranches(existRegion?.id)
      if (existBranch) {
        setExistBranch(undefined)
      } else {
        if (existProject) {
          store!.getUnits(existProject.id, existRegion?.id)
        }
      }
    }, [existRegion])

    useEffect(() => {
      if (existProject) {
        store!.getUnits(existProject.id, existRegion?.id, existBranch?.id)
      }
    }, [existBranch])

    useEffect(() => {
      if (existProject) {
        store!.getUnits(existProject.id, existRegion?.id, existBranch?.id)
      }
    }, [existProject])

    useEffect(() => {
      customerOffer.selectedProject = existSelectedProject
    }, [existSelectedProject])

    useEffect(() => {
      customerOffer.selectedUnits = existSelectedUnits
    }, [existSelectedUnits])

    useEffect(() => {
      store!.getCandidateBranches(candidateRegion?.id)
    }, [candidateRegion])

    useEffect(() => {
      if (candidateCustomers?.length && candidateCustomers?.length > 0) {
        setCandidateCustomerList(candidateCustomers)
      }
    }, [candidateCustomers])

    useEffect(() => {
      customerOffer.candidateCustomer = selectedCandidateCustomer
    }, [selectedCandidateCustomer])

    useEffect(() => {
      if (candidateCustomerList?.length && candidateCustomerList?.length > 0) {
        setCandidateCustomerList(
          candidateCustomers?.filter(
            (x: ICustomerDto) =>
              (candidateRegion ? x.region?.id.toString() === candidateRegion.id.toString() : true) &&
              (candidateBranch ? x.branch?.id.toString() === candidateBranch.id.toString() : true)
          )
        )
      }
    }, [candidateRegion, candidateBranch])

    const clearFilter = () => {
      setExistProject(undefined)
      if (existRegion == undefined) {
        setExistBranch(undefined)
      } else {
        setExistRegion(undefined) // bu useEffect ile zaten branchi undefined yapiyor
      }
    }

    const createUnitAxIdSetFromArray = (arr: any) => {
      return new Set((arr || []).map((x: any) => x.id))
    }

    const handleChangeSelectedUnits = (e: any) => {
      const curVal = e.target.value

      if (curVal && typeof curVal === 'string' && existSelectedUnits && existSelectedUnits.length > 0) {
        const index = existSelectedUnits.findIndex((x) => x.id == curVal)

        if (index != -1) {
          let tmp = [...existSelectedUnits]
          tmp.splice(index, 1)
          if (tmp.length == 0) {
            setExistSelectedProject(undefined)
          }
          setExistSelectedUnits(tmp)
        }
      }
    }

    const addAllFilteredUnits = () => {
      if ((units || []).length == 0) {
        toast.error(t('There are no units matching these filters'))
        return
      }
      if (existSelectedProject != undefined && existSelectedProject.id != existProject!.id) {
        toast.error(t('You cannot select units of different projects at the same time'))
        return
      }
      setIsLoading(true)
      if (existSelectedProject == undefined) {
        setExistSelectedProject({ ...existProject! })
      }
      let tmpSelectedUnits = [...(existSelectedUnits || [])]
      const tmpSet = createUnitAxIdSetFromArray(tmpSelectedUnits)
      units.forEach((tmpUnit: any) => {
        if (!tmpSet.has(tmpUnit.id)) {
          tmpSelectedUnits = [...tmpSelectedUnits, tmpUnit]
        }
      })
      setExistSelectedUnits(tmpSelectedUnits)
      setIsLoading(false)
      toast.success(t('Filtered units are added to the selected list'))
    }

    return (
      <>
        {!isReadOnly && (
          <div className="row">
            <div className="col-md-12">
              <p>{t('Please select a customer type and customer.')}</p>
            </div>
          </div>
        )}

        <div className="row">
          <div className="d-flex" style={{ marginBottom: '10px', marginLeft: '0px!important' }}>
            <div className="custom-control custom-radio px-5">
              <input
                type="radio"
                className="custom-control-input"
                id="typeRadio1"
                name="type"
                value="1"
                defaultChecked={!isCandidate}
                checked={!isCandidate}
                onChange={(value) => {
                  if (value.target.checked) {
                    setIsCandidate(false)
                  }
                }}
                disabled={isReadOnly || isLoading}
              />
              <label className="custom-control-label" htmlFor="typeRadio1">
                {t('Existing Customer')}
              </label>
            </div>
            <div className="custom-control custom-radio px-5">
              <input
                type="radio"
                className="custom-control-input"
                id="typeRadio0"
                name="type"
                value="0"
                checked={isCandidate}
                onChange={(value) => {
                  if (value.target.checked) {
                    setIsCandidate(true)
                  }
                }}
                disabled={isReadOnly || isLoading}
              />
              <label className="custom-control-label" htmlFor="typeRadio0">
                {t('Candidate Customer')}
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          {(!isReadOnly || isCandidate) && (
            <div className="col-md-6">
              {!isCandidate && (
                <div className="form-row">
                  <div className="form-group w-100">
                    <label htmlFor="region" className="form-label">
                      {t('Region')}
                    </label>

                    <Combobox
                      id="region"
                      filter="contains"
                      data={regions}
                      value={existRegion?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setExistRegion(val)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              )}

              {!isCandidate && (
                <div className="form-row">
                  <div className="form-group w-100">
                    <label htmlFor="branch" className="form-label">
                      {t('Branch')}
                    </label>
                    <Combobox
                      id="branch"
                      filter="contains"
                      data={branches}
                      value={existBranch?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setExistBranch(val)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              )}

              {!isCandidate && (
                <div className="form-row">
                  <div className="form-group w-100">
                    <label htmlFor="project" className="form-label">
                      {t('Project')}
                    </label>

                    <Combobox
                      id="project"
                      filter="contains"
                      data={projects}
                      value={existProject?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setExistProject(val)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              )}

              {!isCandidate && !!existProject && (
                <div className="form-row">
                  <div className="form-group w-100">
                    <label htmlFor="unit" className="form-label">
                      {t('Unit')}
                    </label>

                    <Combobox
                      id="unit"
                      filter="contains"
                      data={units}
                      textField="unitName"
                      onSelect={(val: any) => {
                        if (existSelectedProject != undefined && existSelectedProject.id != existProject!.id) {
                          toast.error(t('You cannot select units of different projects at the same time'))
                          return
                        }

                        if (existSelectedProject == undefined) {
                          setExistSelectedProject({ ...existProject! })
                        }
                        let tmpSelectedUnits = [...(existSelectedUnits || [])]
                        const axIdSet = createUnitAxIdSetFromArray(tmpSelectedUnits)
                        if (axIdSet.has(val.id)) {
                          toast.warning(t('This unit has already been added previously'))
                          return
                        }

                        tmpSelectedUnits = [...tmpSelectedUnits, val]
                        setExistSelectedUnits(tmpSelectedUnits)
                      }}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              )}

              {!isCandidate && (
                <div className="form-row text-right">
                  <button className="btn btn-warning ml-auto" onClick={clearFilter} disabled={isLoading}>
                    {t('Clear Filter')}
                  </button>
                  {!!existProject && (
                    <button className="btn btn-primary" style={{ marginLeft: '10px' }} onClick={addAllFilteredUnits} disabled={isLoading}>
                      {t('Add All Filtered Units')}
                    </button>
                  )}
                </div>
              )}

              {isCandidate && (
                <div className="form-row">
                  <div className="form-group w-100">
                    <label htmlFor="region" className="form-label">
                      {t('Region')}
                    </label>

                    <Combobox
                      id="region"
                      filter="contains"
                      data={regions}
                      value={candidateRegion?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setCandidateRegion(val)
                        setCandidateBranch(undefined)
                        setSelectedCandidateCustomer(undefined)
                      }}
                      disabled={isReadOnly || isLoading}
                    />
                  </div>
                </div>
              )}

              {isCandidate && (
                <div className="form-row">
                  <div className="form-group w-100">
                    <label htmlFor="branch" className="form-label">
                      {t('Branch')}
                    </label>
                    <Combobox
                      id="branch"
                      filter="contains"
                      data={candidateBranches}
                      value={candidateBranch?.name}
                      textField="name"
                      onSelect={(val: any) => {
                        setCandidateBranch(val)
                        setSelectedCandidateCustomer(undefined)
                      }}
                      disabled={isReadOnly || isLoading}
                    />
                  </div>
                </div>
              )}

              {isCandidate && (
                <div className="form-row">
                  <div className="form-group w-100">
                    <label htmlFor="customer" className="form-label">
                      {t('Customer')}
                    </label>
                    <Combobox
                      id="customer"
                      filter="contains"
                      data={candidateCustomerList ?? candidateCustomers}
                      value={selectedCandidateCustomer?.customerName}
                      textField="customerName"
                      onSelect={(val: any) => {
                        customerOffer.customer = val
                        setSelectedCandidateCustomer(val)
                      }}
                      disabled={isReadOnly || isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {!isCandidate && (
            <div className={`col-md-${isReadOnly ? 12 : 6}`}>
              <div className="form-row">
                <div className="form-group w-100">
                  <label htmlFor="existSelectedProject" className="form-label">
                    {t('Selected Project')}
                  </label>

                  <div>
                    <input type="text" className="form-control py-4" value={existSelectedProject?.name || ''} disabled={true} />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group w-100">
                  <label htmlFor="existSelectedUnits" className="form-label">
                    {t('Selected Units')}
                  </label>

                  <div>
                    <select
                      multiple
                      onChange={handleChangeSelectedUnits}
                      style={{ width: '100%', height: '193px' }}
                      disabled={isReadOnly || isLoading}
                    >
                      {(existSelectedUnits || []).map((tmpSelectedUnit) => {
                        return (
                          <option key={tmpSelectedUnit.id} value={tmpSelectedUnit.id}>
                            {'[X] ' + tmpSelectedUnit.unitName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {!isCandidate && (
                <div className="form-row text-right">
                  <button
                    className="btn btn-warning ml-auto"
                    disabled={isReadOnly || isLoading}
                    onClick={() => {
                      setExistSelectedProject(undefined)
                      setExistSelectedUnits(undefined)
                    }}
                  >
                    {t('Clear Selected Units')}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isReadOnly}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
              disabled={isLoading}
            />
          </div>
          <div className="col-md-6 text-right">
            {isReadOnly && (
              <button className="btn btn-light ml-auto mr-2" onClick={prevStep}>
                &laquo; {t('Prev')}
              </button>
            )}
            <button className="btn btn-primary ml-auto" onClick={nextStep} disabled={isLoading}>
              {t('Next')} &raquo;
            </button>
          </div>
        </div>
      </>
    )
  })
)

export default Step
