import React, { FC, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import * as _ from 'lodash'

import Service from './_steps/service/service'
import Customer from './_steps/customer/customer'
import Solutions from './_steps/solutions/solutions'
import RiskAnalyzes from './_steps/risk-analyzes/risk-analyzes'
import OfferDocuments from './_steps/offer-document/offer-documents'
import DetectionsAndSolutions from './_steps/detections-solutions/detections-solutions'
import Document from './_steps/document/document'
import Email from './_steps/email/email'
import SavePreview from './_steps/save-preview/save-preview'

import Store from './store'
import {
  ICustomerOfferDetectionAndSolutionDto,
  ICustomerOfferRiskAnalysisDto,
  ICustomerOfferDto,
  ICustomerOfferSolutionDto,
} from '../customer-offer-grid/types'
import { IConventionalOfferDocument } from '../conventional-offer-document/types'
import { IUploadedFileDto } from '../conventional-offer-admin/types'

const MODULE_NAME = 'CustomerOffer'

interface IPageProps {
  CustomerOfferStore: Store
}

const Page: FC<IPageProps> = inject('CustomerOfferStore')(
  observer((props: IPageProps) => {
    const { CustomerOfferStore: store } = props
    const { t } = useTranslation()
    const { offerId: paramOfferId }: any = useParams()

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)

    const [stepIndex, setStepIndex] = useState<number>(0)

    const nextStep = () => {
      setStepIndex(stepIndex + 1)
    }

    const prevStep = () => {
      setStepIndex(stepIndex === 0 ? 0 : stepIndex - 1)
    }

    const returnToOfferGrid = () => {
      history.push(`/digital-offer/customer-offer-grid`)
    }

    useEffect(() => {
      Promise.all([
        store!.getDegrees(),
        store!.getCandidateCustomers(),
        store!.getRegions(),
        store!.getBranches(),
        store!.getCandidateBranches(),
        store!.getProjects(),
        store!.getCategories(),
        store!.getServiceDocuments(-1),
        store!.getConventionalOffersAsList(),
        store!.getEgcOffersAsList(),
      ]).then(() => {
        if (paramOfferId) {
          store!.getCustomerOffer(paramOfferId).then((customerOffer: ICustomerOfferDto) => {
            store.customerOffer = customerOffer
            setIsLoading(false)
          })
        } else {
          store.customerOffer = {
            solutionList: [] as ICustomerOfferSolutionDto[],
            fileList: [] as IUploadedFileDto[],
            riskAnalyzes: [] as ICustomerOfferRiskAnalysisDto[],
            offerServiceDocuments: [] as IConventionalOfferDocument[],
            offerServiceDocumentsFlat: [] as string[],
            detectionsAndSolutions: [] as ICustomerOfferDetectionAndSolutionDto[],
          } as ICustomerOfferDto

          setIsLoading(false)
        }
      })
    }, [])

    const steps = [
      {
        title: t('Customer'),
        component: <Customer prevStep={prevStep} nextStep={nextStep} returnToOfferGrid={returnToOfferGrid} />,
      },
      {
        title: t('Solutions'),
        component: <Solutions prevStep={prevStep} nextStep={nextStep} returnToOfferGrid={returnToOfferGrid} />,
      },
      // {
      //   title: t('Offer Documents'),
      //   component: <OfferDocuments prevStep={prevStep} nextStep={nextStep} returnToOfferGrid={returnToOfferGrid} />,
      // },
      {
        title: t('Risk Analysis'),
        component: <RiskAnalyzes prevStep={prevStep} nextStep={nextStep} returnToOfferGrid={returnToOfferGrid} />,
      },
      {
        title: t('Detections') + '/' + t('Solutions'),
        component: <DetectionsAndSolutions prevStep={prevStep} nextStep={nextStep} returnToOfferGrid={returnToOfferGrid} />,
      },
      {
        title: t('Document'),
        component: <Document prevStep={prevStep} nextStep={nextStep} moduleName={MODULE_NAME} returnToOfferGrid={returnToOfferGrid} />,
      },
      {
        title: t('Email'),
        component: <Email prevStep={prevStep} nextStep={nextStep} returnToOfferGrid={returnToOfferGrid} />,
      },
      {
        title: t('Save/Preview'),
        component: <SavePreview prevStep={prevStep} returnToOfferGrid={returnToOfferGrid} />,
      },
    ]

    if (!!store.customerOffer?.startDate && !store.customerOffer?.userLacksKamAuthorization) {
      steps.unshift({
        title: t('Service'),
        component: <Service nextStep={nextStep} returnToOfferGrid={returnToOfferGrid} />,
      })
    }

    return isLoading ? (
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-center w-100 py-4">
          <div className="spinner-grow text-primary"></div>
          <div className="ml-2">{t('Loading')}...</div>
        </div>
      </div>
    ) : (
      <div className="card-body">
        <ol className="forms-wizard mb-5">
          {_.map(steps, (step, i: number) => (
            <li
              className={cx('d-none', 'd-lg-table-cell', {
                'd-table-cell': stepIndex === i || stepIndex === i - 1,
                'form-wizard-step-done': stepIndex > i,
                'd-block form-wizard-step-doing': stepIndex === i,
                'form-wizard-step-todo': stepIndex < i,
              })}
              value={i}
              key={i}
              onClick={() => setStepIndex(i)}
            >
              <em>{i + 1}</em>
              <span>{step.title}</span>
            </li>
          ))}
        </ol>
        <div className="form-wizard-content">{steps[stepIndex].component}</div>
      </div>
    )
  })
)

export default Page
