import { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Nav, UncontrolledDropdown, DropdownMenu } from 'reactstrap'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import PerfectScrollbar from 'react-perfect-scrollbar'
import * as _ from 'lodash'
import http from '../../../../../services/http'
import { serialize } from '@/utils/requestHelper'
import env from '../../../../../config/env'
import { MessageResultState, ServiceResult } from '@/types/general-types'
import { NotificationDtos, UpdateNotificationListDto } from './style'
import { AxiosInstance } from 'axios'
const { API_BASE_URL } = env

const Notifications: FC = (props: any) => {
  const [notifications, setNotifications] = useState<NotificationDtos[]>([])
  const { t } = useTranslation()

  const api = (): AxiosInstance => {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  const [notificationCount, setNotificationCount] = useState<number>(notifications.length)

  useEffect(() => {
    let notificationCount = notifications.filter((x) => x.readDate === null).length
    if (notificationCount > 0) setNotificationCount(notificationCount)
    else {
      setNotificationCount(0)
    }
  }, [notifications, notifications.length])

  async function markAsRead(input: UpdateNotificationListDto) {
    let newList: NotificationDtos[] = []
    input.UpdateNotificationDto.map((item) => {
      let newItem: NotificationDtos = item
      newItem.parameters = JSON.stringify(item.parameters)
      newList.push(newItem)
    })
    let newDto: UpdateNotificationListDto = { UpdateNotificationDto: newList }
    const options = {
      indices: true, //Array indisleri ile birlikte gönderiliyor => [0]. Bu alan false olursa [] şeklinde gönderiliyor.
      nullsAsUndefineds: false, //boş değerleri undefined şeklinde gösterir. Yok sayar.
      booleansAsIntegers: false, //true-false u 0-1 şekline çevirir.
      allowEmptyArrays: false, //Diziler boş olsalar bile saklanır.
      noFilesWithArrayNotation: true, //dizileri serialize ederken [] işareti ile birlikte serialize eder.
      dotsForObjectNotation: true, //Formdatanın anahtarlarında nesne gösterimi için parantez yerine nokta kullanmayı sağlar.
    }

    let formData = serialize(newDto, options)
    let response: ServiceResult = await api()
      .post(`/api/notification/update`, formData)
      .then((res) => res.data)
      .catch((err: any) => {})
    return response
  }

  return (
    <UncontrolledDropdown>
      {/* <DropdownToggle className="text-decoration-none p-0 mr-2" color="link">
        <div className="icon-wrapper icon-wrapper-alt rounded-circle">
          <div className="icon-wrapper-bg bg-danger" />
          <i className="fas fa-bell text-white" style={{ fontSize: 23 }}></i>
          {notifications.length > 0 && (
            <div className={cx(`badge badge-dot badge-dot-sm ${notificationCount > 0 ? 'badge-danger' : ''} `)}>{t('Notifications')}</div>
          )}
        </div>
      </DropdownToggle> */}
      <DropdownMenu right className="dropdown-menu-xl rm-pointers">
        <div className="dropdown-menu-header mb-0">
          <div className="dropdown-menu-header-inner bg-deep-blue">
            <div className="menu-header-image opacity-1"></div>
            <div className="menu-header-content text-dark">
              <div className="container">
                <div className="row float-right">
                  {notificationCount > 0 ? (
                    <button
                      className="btn btn-warning mr-auto "
                      onClick={() => {
                        let valuess: UpdateNotificationListDto = { UpdateNotificationDto: notifications }
                        valuess.UpdateNotificationDto.map((x: any) => {
                          if (!x.readDate) {
                            x.readDate = new Date()
                          } else {
                            x.readDate = null
                          }
                        })
                        markAsRead(valuess).then((res) => {
                          if (res.state === MessageResultState.SUCCESS) {
                            window.location.reload()
                          }
                        })
                      }}
                    >
                      {t('Bildirimleri Temizle')}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <br></br>
              <br></br>
              <h5 className="menu-header-title">{t('Notifications')}</h5>
              <h6 className="menu-header-subtitle">
                <Trans
                  i18nKey="unreadMessage"
                  count={notificationCount}
                  values={{ count: notificationCount }}
                  components={[<strong>univers</strong>]}
                />
              </h6>
            </div>
          </div>
        </div>
        {notifications.length > 0 && (
          <Nav vertical>
            <div className="card-tabs-animated card-tabs-animated-inner">
              <div className="scroll-area-sm">
                <PerfectScrollbar>
                  <div className="p-3">
                    <VerticalTimeline className="vertical-time-simple vertical-without-time" layout="1-column">
                      {_.map(notifications, (item: any, i: number) => (
                        <VerticalTimelineElement className={item.readDate ? 'vertical-timeline-item' : 'vertical-timeline-item bg-deep-blue'} key={i}>
                          <p>
                            <Link
                              className={'text-dark text-decoration-none'}
                              to={item.link}
                              onClick={() => {
                                if (!item.readDate) {
                                  item.readDate = new Date()
                                } else {
                                  item.readDate = null
                                }
                                let array = []
                                array.push(item)
                                let valuess: UpdateNotificationListDto = { UpdateNotificationDto: array }
                                markAsRead(valuess).then((res) => {
                                  if (res.state === MessageResultState.SUCCESS) {
                                    window.location.href = item.link
                                  }
                                })
                              }}
                            >
                              <Trans
                                t={t}
                                defaults={item.message}
                                values={item.parameters}
                                components={_.map(item.parameters, () => (
                                  <strong>univers</strong>
                                ))}
                              />
                            </Link>
                          </p>
                        </VerticalTimelineElement>
                      ))}
                    </VerticalTimeline>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </Nav>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default Notifications
