import React, { FC, useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { Button, Table } from '@/components'

interface IStepProps {
  CustomerOfferStore?: any
  prevStep: any
  nextStep: any
  returnToOfferGrid: any
}

const Step: FC<IStepProps> = inject('CustomerOfferStore')(
  observer((props: IStepProps) => {
    const { CustomerOfferStore: store, prevStep, nextStep, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { customerOffer, customerAuthorizedPersonnels } = store!

    const isReadOnly = !!customerOffer.isReadOnly

    useEffect(() => {
      if (customerOffer.isItCandidateCustomer === true ? customerOffer.candidateCustomer?.id == undefined : !customerOffer.selectedProject) {
        return
      }

      store.getCustomerAuthorizedPersonnels(
        { skipCount: 0, maxResultCount: 5 },
        customerOffer.isItCandidateCustomer === true ? customerOffer.candidateCustomer?.id : undefined,
        customerOffer.isItCandidateCustomer === true ? undefined : customerOffer.selectedProject?.id
      )
    }, [])

    const columns = useMemo(
      () => [
        {
          Header: t('Name'),
          id: 'nameSurname',
          accessor: 'nameSurname',
          Cell: ({ row }: any) => {
            const { nameSurname } = row.values

            return <span dangerouslySetInnerHTML={{ __html: nameSurname }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Email'),
          id: 'emailAddress',
          accessor: 'emailAddress',
          Cell: ({ row }: any) => {
            const { emailAddress } = row.values

            return <span dangerouslySetInnerHTML={{ __html: emailAddress }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    return (
      <>
        <div className="card-body">
          <div className="form-row py-3" style={{ marginLeft: '0px' }}>
            <div className="col-sm-2">
              <label htmlFor="emailTO" className="">
                {'TO:'}
              </label>
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                name="emailTO"
                className="form-control py-4"
                placeholder={`${t('Email')}: (${t('Example')}: user1@securitas.com.tr,user2@securitas.com.tr)`}
                value={customerOffer.emailAddressesTo ?? ''}
                onChange={(event) => {
                  customerOffer.emailAddressesTo = event.target.value
                }}
                disabled={isReadOnly}
              />
            </div>
          </div>
          <div className="form-row py-3" style={{ marginLeft: '0px' }}>
            <div className="col-sm-2">
              <label htmlFor="emailCC" className="">
                {'CC:'}
              </label>
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                name="emailCC"
                className="form-control py-4"
                placeholder={`${t('Email')}: (${t('Example')}: user1@securitas.com.tr,user2@securitas.com.tr)`}
                value={customerOffer.emailAddressesCc ?? ''}
                onChange={(event) => {
                  customerOffer.emailAddressesCc = event.target.value
                }}
                disabled={isReadOnly}
              />
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="card-header">
            <div className="row">
              <label htmlFor="authorizedEmail" className="">
                {t('Authorized Email Addresses')}
              </label>
            </div>
          </div>
          <br></br>
          <Table
            smallSize={true}
            showHeaderSearchInput={false}
            columns={columns}
            totalCount={customerAuthorizedPersonnels.totalCount}
            data={customerAuthorizedPersonnels.items}
            fetchData={(x) =>
              store.getCustomerAuthorizedPersonnels(
                x,
                customerOffer.isItCandidateCustomer === true ? customerOffer.candidateCustomer?.id : undefined,
                customerOffer.isItCandidateCustomer === true ? undefined : customerOffer.selectedProject?.id
              )
            }
            isLoading={!customerAuthorizedPersonnels.isLoaded}
          />
        </div>
        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isReadOnly}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
            />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-light ml-auto mr-2" onClick={prevStep}>
              &laquo; {t('Prev')}
            </button>
            <button className="btn btn-primary ml-auto" onClick={nextStep}>
              {t('Next')} &raquo;
            </button>
          </div>
        </div>
      </>
    )
  })
)

export default Step
