import IRoute from '../models/dto/route/IRoute'

const loadRoutes = () => {
  let routes: { title: any; icon: any; index: any; path: any; routes: any }[] = []
  const req = require.context('../modules/', true, /route.(ts|js)$/i)

  for (const key of req.keys()) {
    const route = require('../modules/' + key.replace('./', ''))
    if (typeof route.basePath === 'undefined') route.basePath = ''
    const moduleName = '/' + key.replace('./', '').split('/')[0]!.toLowerCase()

    route.default = route.default.map((r: any) => {
      const { component, path, title, icon, permission, showInMenu, children, index, single } = r

      const _path = path === '/' ? `${moduleName}` : `${path}`

      let item: IRoute = {
        component,
        path: `${process.env.REACT_APP_SUB_PATH}${route.basePath}${_path}`,
        title,
        icon,
        permission,
        showInMenu,
        single,
        index: index ?? 999999,
      }

      if (children) {
        item.children = children.map((child: any) => {
          const _subpath = child.path === '/' ? _path : `${route.basePath}${child.path}`

          return { ...child, path: _subpath }
        })
      }

      return item
    })

    const module = {
      title: route.title ? route.title : '',
      icon: route.icon ? route.icon : undefined,
      index: route.index ? route.index : 0,
      path: `${process.env.REACT_APP_SUB_PATH}${route.basePath}${moduleName}`,
      routes: route.default,
    }

    routes.push(module)
  }

  return routes
}

export { loadRoutes }
