import React, {Component } from 'react'
import Form from '@rjsf/core'

class Card extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      schema: localStorage.getItem('newSchema') ? localStorage.getItem('newSchema') : '{}',
      uischema: localStorage.getItem('newUiSchema') ? localStorage.getItem('newUiSchema') : '{}',
      formData: {},
    }

    // console.log(this.state)
  }

  render() {
    return (
      <div>
        <Form
          className="formPreview"
          schema={JSON.parse(this.state.schema)}
          uiSchema={JSON.parse(this.state.uischema)}
          onChange={(newFormData) => {
            this.setState({ formData: newFormData.formData })
            localStorage.setItem('formData', JSON.stringify(newFormData.formData))
          }}
          formData={this.state.formData}
        />
      </div>
    )
  }
}
export default Card
