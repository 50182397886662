import React, { FC, useState, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Button, ConfirmModal, Table } from '@/components'
import DetectionSolutionPopup from './detection-solution-popup'

interface IStepProps {
  CustomerOfferStore?: any
  prevStep: any
  nextStep: any
  returnToOfferGrid: any
}

const Step: FC<IStepProps> = inject('CustomerOfferStore')(
  observer((props: IStepProps) => {
    const { CustomerOfferStore: store, prevStep, nextStep, returnToOfferGrid } = props
    const { t } = useTranslation()

    const { customerOffer } = store!

    const isReadOnly = !!customerOffer.isReadOnly

    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                {!isReadOnly && (
                  <Button
                    icon={<i className="fas fa-pen"></i>}
                    tooltipText={t('Edit')}
                    onClick={async () => {
                      const index = customerOffer.detectionsAndSolutions.findIndex((x: any) => x.id == row.id)

                      if (index != -1) {
                        customerOffer.detectionsAndSolutions[index].isSelected = true
                        setShowEditModal(true)
                      }
                    }}
                    className="btn btn-link text-warning"
                  />
                )}
                {isReadOnly && (
                  <Button
                    icon={<i className="fas fa-eye"></i>}
                    tooltipText={t('Details')}
                    onClick={async () => {
                      const index = customerOffer.detectionsAndSolutions.findIndex((x: any) => x.id == row.id)

                      if (index != -1) {
                        customerOffer.detectionsAndSolutions[index].isSelected = true
                        setShowEditModal(true)
                      }
                    }}
                    className="btn btn-link"
                  />
                )}
                <Button
                  icon={<i className="fas fa-trash"></i>}
                  tooltipText={t('Delete')}
                  onClick={async () => {
                    const index = customerOffer.detectionsAndSolutions.findIndex((x: any) => x.id == row.id)

                    if (index != -1) {
                      customerOffer.detectionsAndSolutions[index].isSelected = true
                      setShowDeleteModal(true)
                    }
                  }}
                  className="btn btn-link text-danger"
                  disabled={isReadOnly}
                />
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Detection') + '/' + t('Solution'),
          id: 'detectionOrSolution',
          accessor: 'detectionOrSolution',
          Cell: ({ row }: any) => {
            const { detectionOrSolution } = row.values

            const badgeType = detectionOrSolution === true ? 'success' : 'warning'
            const detectionSolutionText = detectionOrSolution === true ? t('Solution') : t('Detection')

            return <span className={`badge badge-${badgeType}`} dangerouslySetInnerHTML={{ __html: detectionSolutionText }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
        {
          Header: t('Explanation'),
          id: 'explanation',
          accessor: 'explanation',
          Cell: ({ row }: any) => {
            const { explanation } = row.values

            return <span dangerouslySetInnerHTML={{ __html: explanation }}></span>
          },
          className: 'd-none d-md-table-cell',
        },
      ],
      [t]
    )

    const deletionMessage = () => {
      const selected = customerOffer?.detectionsAndSolutions?.find((x: any) => x.isSelected)

      if (selected) {
        return (
          <>
            <p>
              {t('Type')}: {selected?.detectionOrSolution === true ? t('Solution') : t('Detection')}
            </p>
            <p style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
              {t('Explanation')}: {selected?.explanation}
            </p>
          </>
        )
      }

      return <p>{t('Error')}</p>
    }

    return (
      <>
        <div className="card-header">
          <div className="btn-actions-pane-right actions-icon-btn">
            <button
              className="btn btn-primary btn-shadow flex-direction float-right"
              style={{ marginTop: '-5px' }}
              onClick={async (e) => {
                e.preventDefault()
                setShowEditModal(true)
              }}
              disabled={isReadOnly}
            >
              <i className="fas fa-plus mr-2"></i>
              {t('New') + ' ' + t('Detection') + '/' + t('Solution')}
            </button>
          </div>
        </div>
        <div className="card-body">
          <Table
            smallSize={true}
            showHeaderSearchInput={false}
            columns={columns}
            totalCount={customerOffer?.detectionsAndSolutions?.length ?? 0}
            data={customerOffer?.detectionsAndSolutions ?? []}
            fetchData={() => {}}
            isLoading={false}
            showNavigation={false}
          />
        </div>

        <div className="row mt-5">
          <div className="col-md-6 text-left">
            <Button
              className="btn btn-danger ml-auto mr-2"
              onClick={returnToOfferGrid}
              text={'&laquo; ' + t('Return To Offers')}
              showConfirm={!isReadOnly}
              confirmText={t('If there are unsaved changes, they will be lost. Do you want to continue?')}
            />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-light ml-auto mr-2" onClick={prevStep}>
              &laquo; {t('Prev')}
            </button>
            <button className="btn btn-primary ml-auto" onClick={nextStep}>
              {t('Next')} &raquo;
            </button>
          </div>
        </div>

        {showEditModal && (
          <DetectionSolutionPopup
            toggleModal={() => {
              if (showEditModal) {
                const selected = customerOffer?.detectionsAndSolutions?.find((x: any) => x.isSelected)

                if (selected) {
                  selected.isSelected = false
                }
              }

              setShowEditModal(!showEditModal)
            }}
            isReadOnly={isReadOnly}
          />
        )}

        {showDeleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={deletionMessage()}
            title={t('Warning') + '!'}
            isShow={showDeleteModal}
            toggleModal={() => {
              if (showDeleteModal) {
                const selected = customerOffer?.detectionsAndSolutions?.find((x: any) => x.isSelected)

                if (selected) {
                  selected.isSelected = false
                }
              }

              setShowDeleteModal(!showDeleteModal)
            }}
            onConfirm={async () => {
              customerOffer!.detectionsAndSolutions?.splice(
                customerOffer!.detectionsAndSolutions?.findIndex((x: any) => x.isSelected),
                1
              )

              customerOffer!.detectionsAndSolutions = [...customerOffer!.detectionsAndSolutions!] // to refresh grid

              setShowDeleteModal(false)
            }}
          />
        )}
      </>
    )
  })
)

export default Step
