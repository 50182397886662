// export enum RequestTypeDto {
//   Form = 1,
//   Body = 2,
// }
// function serialize<T>(requestType: RequestTypeDto, content: T) {
//   let newContent = {}
//   switch (requestType) {
//     case RequestTypeDto.Body: {
//         newContent={}
//       break
//     }
//     case RequestTypeDto.Form: {

import i18n from './i18n'

//         let data = new FormData(formElement);

//         let obj = {};
//         for (let [key, value] of data) {
//             obj[key] = value;
//         }
//         return obj;

//         switch (form.elements[i].type) {
//             case 'text':
//             case 'tel':
//             case 'email':
//             case 'hidden':
//             case 'password':
//             case 'button':
//             case 'reset':
//             case 'submit':
//                 q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
//                 break;
//             case 'checkbox':
//             case 'radio':
//                 if (form.elements[i].checked) {
//                         q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
//                 }
//                 break;
//         }
//         break;
//         case 'file':
//         break;

//       break
//     }
//     default: {
//       break
//     }
//   }
//   return newContent
// }
// export { serialize }

export function isUndefined(value: any) {
  return value === undefined
}

export function isNull(value: any) {
  return value === null
}

export function isBoolean(value: any) {
  return typeof value === 'boolean'
}

export function isNumber(value: any) {
  return typeof value === 'number'
}

export function isObject(value: any) {
  return value === Object(value)
}

export function isArray(value: any) {
  return Array.isArray(value)
}

export function isDate(value: any) {
  return value instanceof Date
}

export function isBlob(value: any, isReactNative: boolean) {
  return isReactNative
    ? isObject(value) && !isUndefined(value.uri)
    : isObject(value) && typeof value.size === 'number' && typeof value.type === 'string' && typeof value.slice === 'function'
}

export function isFile(value: any, isReactNative: boolean) {
  return (
    isBlob(value, isReactNative) && typeof value.name === 'string' && (isObject(value.lastModifiedDate) || typeof value.lastModified === 'number')
  )
}

export function initCfg(value: any) {
  return isUndefined(value) ? false : value
}

export function serialize(obj: any, cfg: any, fd?: any, pre?: any) {
  cfg = cfg || {}
  fd = fd || new FormData()

  cfg.indices = initCfg(cfg.indices)
  cfg.nullsAsUndefineds = initCfg(cfg.nullsAsUndefineds)
  cfg.booleansAsIntegers = initCfg(cfg.booleansAsIntegers)
  cfg.allowEmptyArrays = initCfg(cfg.allowEmptyArrays)
  cfg.noFilesWithArrayNotation = initCfg(cfg.noFilesWithArrayNotation)
  cfg.dotsForObjectNotation = initCfg(cfg.dotsForObjectNotation)

  const isReactNative = typeof fd.getParts === 'function'

  if (isUndefined(obj)) {
    return fd
  } else if (isNull(obj)) {
    if (!cfg.nullsAsUndefineds) {
      fd.append(pre, '')
    }
  } else if (isBoolean(obj)) {
    if (cfg.booleansAsIntegers) {
      fd.append(pre, obj ? 1 : 0)
    } else {
      fd.append(pre, obj)
    }
  } else if (isNumber(obj)) {
    if (i18n.language === 'tr') {
      fd.append(pre, obj.toString().replace('.', ','))
    } else if (i18n.language === 'en') {
      fd.append(pre, obj.toString().replace(',', '.'))
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value: any, index: any) => {
        let key = pre + '[' + (cfg.indices ? index : '') + ']'

        if (cfg.noFilesWithArrayNotation && isFile(value, isReactNative)) {
          key = pre
        }

        serialize(value, cfg, fd, key)
      })
    } else if (cfg.allowEmptyArrays) {
      fd.append(pre + '[]', '')
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString())
  } else if (isObject(obj) && !isBlob(obj, isReactNative)) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop]

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2)
        }
      }

      const key = pre ? (cfg.dotsForObjectNotation ? pre + '.' + prop : pre + '[' + prop + ']') : prop

      serialize(value, cfg, fd, key)
    })
  } else {
    fd.append(pre, obj)
  }

  return fd
}
