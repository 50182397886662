import { action, makeAutoObservable } from 'mobx'
import { IFileCreateDto } from '@/models/dto/file'
import { IOfferDto } from '../conventional-offer/types'
import { ICustomerOfferDto, IIdNameDTO } from '../customer-offer-grid/types'
import http from '@/services/http'
import env from '@/config/env'
import IPagedResult from '@/models/dto/fetch/IPagedResult'
import IPagedRequest from '@/models/dto/fetch/IPagedRequest'
import qs from 'qs'
import { ICustomerAuthorizedPersonnelDto, ICustomerDto } from './_steps/customer/types'
import { IConventionalOfferDocument } from '../conventional-offer-document/types'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'CustomerOfferStore'
  customerAuthorizedPersonnels: IPagedResult<ICustomerAuthorizedPersonnelDto>
  conventionalOffers: IOfferDto[]
  egcOffers: IOfferDto[]
  degrees: IIdNameDTO[]
  categories: IIdNameDTO[]
  customerOffer?: ICustomerOfferDto | null
  candidateCustomers?: ICustomerDto[]
  serviceDocuments?: IConventionalOfferDocument[]
  regions: IIdNameDTO[]
  branches: IIdNameDTO[]
  candidateBranches: IIdNameDTO[]
  projects: []
  units: []
  shifts: []

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.customerAuthorizedPersonnels = {
      totalCount: 0,
      items: [],
      isLoaded: false,
    }
    this.conventionalOffers = []

    this.egcOffers = []

    this.customerOffer = null

    this.serviceDocuments = []

    this.degrees = []

    this.categories = []

    this.regions = []
    this.branches = []
    this.candidateBranches = []
    this.projects = []
    this.units = []

    this.candidateCustomers = []

    this.shifts = []

    this.getRegions = this.getRegions.bind(this)
    this.getBranches = this.getBranches.bind(this)
    this.getCandidateBranches = this.getCandidateBranches.bind(this)
    this.getProjects = this.getProjects.bind(this)
    this.getUnits = this.getUnits.bind(this)

    this.getCandidateCustomers = this.getCandidateCustomers.bind(this)

    this.getCustomerAuthorizedPersonnels = this.getCustomerAuthorizedPersonnels.bind(this)

    this.getConventionalOffersAsList = this.getConventionalOffersAsList.bind(this)

    this.getEgcOffersAsList = this.getEgcOffersAsList.bind(this)

    this.getDegrees = this.getDegrees.bind(this)

    this.getCategories = this.getCategories.bind(this)

    this.getServiceDocuments = this.getServiceDocuments.bind(this)

    this.getShifts = this.getShifts.bind(this)
  }

  @action.bound async getRegions() {
    this.regions = await (await this.api.get(`/api/app/region/all`)).data
  }

  @action.bound async getBranches(regionId?: number) {
    let params = '?'

    if (regionId != null && regionId > 0) {
      params += `regionId=${regionId}`
    }

    this.branches = await (await this.api.get(`/api/app/branch/all${params}`)).data
  }

  @action.bound async getCandidateBranches(regionId?: number) {
    let params = '?'

    if (regionId != null && regionId > 0) {
      params += `regionId=${regionId}`
    }

    this.candidateBranches = await (await this.api.get(`/api/app/branch/all${params}`)).data
  }

  @action.bound async getProjects() {
    this.projects = await (await this.api.get(`/api/app/project/all`)).data
  }

  @action.bound async getUnits(axProjectId?: string, regionId?: number, branchId?: number) {
    let params = ''
    let isItFirstParam = true

    if (axProjectId != null && axProjectId != '') {
      params += isItFirstParam ? '?' : '&'
      params += `axProjectId=${axProjectId}`
      isItFirstParam = false
    }
    if (regionId != null && regionId > 0) {
      params += isItFirstParam ? '?' : '&'
      params += `regionId=${regionId}`
      isItFirstParam = false
    }
    if (branchId != null && branchId > 0) {
      params += isItFirstParam ? '?' : '&'
      params += `branchId=${branchId}`
      isItFirstParam = false
    }
    params += isItFirstParam ? '?' : '&'
    params += `checkPersonelExistence=false`

    this.units = await (await this.api.get(`/api/app/unit/all${params}`)).data
  }

  @action.bound async getCandidateCustomers(bolgeId?: number, subeId?: number) {
    let params = '?isCandidateCustomer=true'

    if (bolgeId != null && bolgeId > 0) {
      params += `&bolgeId=${bolgeId}`
    }
    if (subeId != null && subeId > 0) {
      params += `&subeId=${subeId}`
    }

    this.candidateCustomers = await (await this.api.get(`/api/app/customer-offer/customers${params}`)).data
  }

  @action.bound async getCustomerAuthorizedPersonnels(params: IPagedRequest, candidateCustomerRecId?: number, axProjectId?: string) {
    this.customerAuthorizedPersonnels.isLoaded = false

    const q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })

    const otherFilters =
      candidateCustomerRecId == undefined
        ? axProjectId == undefined
          ? ''
          : `&axProjectId=${axProjectId}`
        : `&candidateCustomerRecId=${candidateCustomerRecId}`

    const result: IPagedResult<ICustomerAuthorizedPersonnelDto> = await (
      await this.api.get(`/api/app/customer-offer/customer-authorized-personnels${q}${otherFilters}`)
    ).data

    this.customerAuthorizedPersonnels = { ...result, isLoaded: true }
  }

  @action.bound async getConventionalOffersAsList() {
    this.conventionalOffers = await (await this.api.get(`/api/app/conventional-offer/offers-as-list`)).data
  }

  @action.bound async getEgcOffersAsList() {
    // this.egcOffers = await (await this.api.get(`/api/app/egc-offer/offers-as-list`)).data
  }

  @action.bound async getDegrees() {
    this.degrees = await (await this.api.get(`/api/app/customer-offer/degrees`)).data
  }

  @action.bound async getCategories() {
    this.categories = await (await this.api.get(`/api/app/customer-offer/categories`)).data
  }

  @action.bound async uploadFile(input: IFileCreateDto) {
    const formData = new FormData()

    Array.from(input.files).forEach(async (file: any) => {
      formData.append('files', file, file.name)
    })

    formData.append('moduleName', input.moduleName)

    const result = (await this.api.post(`/api/app/customer-offer/file-upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data

    return result
  }

  @action.bound async saveCustomerOffer(dto: ICustomerOfferDto) {
    const result = await this.api.post(`/api/app/customer-offer/save`, dto)

    return result
  }

  @action.bound async getCustomerOffer(offerId: number) {
    const result = await this.api.get(`/api/app/customer-offer/offer?offerId=${offerId}`)
    return result.data
  }

  @action.bound async getServiceDocuments(offerId?: number) {
    this.serviceDocuments = await (await this.api.get(`/api/app/customer-offer/service-documents?offerId=${offerId}`)).data
  }

  @action.bound async getShifts() {
    this.shifts = await (await this.api.get(`/api/app/duty-post/shifts`)).data
  }

  @action.bound async getConventionalOfferInitialTerms(conventionalOfferId: number) {
    const result = await this.api.get(`/api/app/customer-offer/conventional-offer-initial-terms?conventionalOfferId=${conventionalOfferId}`)
    return result.data
  }

  @action.bound async getTechnologyOfferInitialTerms(technologyOfferId: number) {
    const result = await this.api.get(`/api/app/customer-offer/technology-offer-initial-terms?technologyOfferId=${technologyOfferId}`)
    return result.data
  }

  @action.bound async getConventionalOfferProfitPercentage(conventionalOfferId: number) {
    const result = await this.api.get(`/api/app/customer-offer/conventional-offer-profit-percentage?conventionalOfferId=${conventionalOfferId}`)
    return result.data
  }

  @action.bound async getTechnologyOfferProfitPercentage(technologyOfferId: number) {
    const result = await this.api.get(`/api/app/customer-offer/technology-offer-profit-percentage?technologyOfferId=${technologyOfferId}`)
    return result.data
  }

  @action.bound async checkIntersectingOffers(dto: ICustomerOfferDto) {
    const result = await this.api.post(`/api/app/customer-offer/check-intersecting-offers`, dto)

    return result.data
  }

  @action.bound async changeServiceDates(dto: ICustomerOfferDto) {
    const result = await this.api.post(`/api/app/customer-offer/change-service-dates`, dto)

    return result
  }
}

export default Store
