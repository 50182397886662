import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from 'react-loaders'

import env from '../../config/env'
import http from '../../services/http'
import { purgeUser, setUser, User } from '../../utils/user'
import { purgePermissions, setPermissions } from '../../utils/permission'
import { MessageResultState, ServiceResult } from '../../types/general-types'
import { LoginResponseDto } from '../../modules/Account/pages/Login/types'
import { purgeToken, setToken } from '../../utils/token'

const { API_BASE_URL } = env

const SessionRefreshing: FC = (props: any) => {
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    http.defaults.baseURL = API_BASE_URL

    new Promise(async (resolve: any, reject: any) => {
      await http
        .get('account/refresh-session')
        .then((response) => response.data)
        .then((response: ServiceResult<LoginResponseDto>) => {
          if (response.state === MessageResultState.SUCCESS) {
            setUser(response.result.user)
            setToken(response.result.token)
            setPermissions(response.result.permissions)
          }
        })
      history.goBack()
      resolve()
    })
  }, [])

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-12">
          <h1 className="display-1">
            <i className="fas fa-fingerprint"></i>
          </h1>
          <div className="d-flex justify-content-start">
            <span className="display-4">{t('The Session is Refresing')}...</span>
            <div className="d-flex justify-content-center align-items-center ml-5">
              <Loader type="ball-triangle-path" active />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionRefreshing
