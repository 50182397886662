import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button, Loading } from '@/components'
import { ICustomerOfferDto } from './types'
import Store from './store'

interface IDecideIntersectingOffersPopupProps {
    CustomerOfferGridStore?: Store
    toggleModal: any
    toggleAcceptOfferPopup?: any
    acceptedCustomerOffer: ICustomerOfferDto
    intersectingOffersParam: ICustomerOfferDto[]
    setDoesGridNeedARefresh?: any
    isThisForChangeServiceDates: boolean
}

const DecideIntersectingOffersPopup: FC<IDecideIntersectingOffersPopupProps> = inject('CustomerOfferGridStore')(
    observer((props: IDecideIntersectingOffersPopupProps) => {
        const {
            CustomerOfferGridStore: store,
            toggleModal,
            toggleAcceptOfferPopup,
            acceptedCustomerOffer,
            intersectingOffersParam,
            setDoesGridNeedARefresh,
            isThisForChangeServiceDates
        } = props
        const { t } = useTranslation()

        const [intersectingOffers, setIntersectingOffers] = useState<ICustomerOfferDto[]>([...intersectingOffersParam])
        const [isLoading, setIsLoading] = useState(false)

        const save = async () => {
            setIsLoading(true)

            try {
                const paramDto = {
                    ...acceptedCustomerOffer,
                    intersectingOfferIds: intersectingOffers.filter(x => x.isSelected === true).map(x => x.id!)
                } as ICustomerOfferDto

                if (isThisForChangeServiceDates) {
                    const changeServiceDatesRes = await store!.changeServiceDates(paramDto)
                }
                else {
                    const acceptRes = await store!.accept(paramDto)
                }

                if (setDoesGridNeedARefresh != null) setDoesGridNeedARefresh(true)

                if (toggleAcceptOfferPopup != null) toggleAcceptOfferPopup()

                toggleModal()
            }
            catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }

        const handleSelect = (event: any, selected: ICustomerOfferDto) => {
            const tmpIntersectingOffers = [...intersectingOffers]

            const intersectingOfferIdx = tmpIntersectingOffers.findIndex(y => y.id == selected.id)
            if (intersectingOfferIdx != -1) {
                tmpIntersectingOffers.splice(intersectingOfferIdx, 1, { ...selected, isSelected: !selected.isSelected } as ICustomerOfferDto)
                setIntersectingOffers(tmpIntersectingOffers)
            }
        }

        return (
            <>
                <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
                    <ModalHeader toggle={toggleModal}>{t('Accept Offer')}</ModalHeader>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <ModalBody>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <div className="col-md-12" style={{ display: 'flex' }}>
                                    {t('There are offers that intersect with the selected units between the dates you choose. Please select the ones you want to terminate.')}
                                </div>
                            </div>

                            {intersectingOffers.map(intersectingOffer => (
                                <div key={"div_row_" + intersectingOffer.id} className="row" style={{ marginBottom: "10px" }}>
                                    <div className="col-md-12" style={{ display: 'flex' }}>
                                        <div className="col-md-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {<button title={t('Select')} className="btn btn-primary" onClick={(event) => handleSelect(event, intersectingOffer)} disabled={isLoading}>
                                                <i className={'fas fa' + (intersectingOffer.isSelected === true ? '-check' : '') + '-square'}></i>
                                            </button>}
                                        </div>
                                        <div className="col-md-11" style={{ margin: 'auto' }}>
                                            {intersectingOffer.startDateStr + ' - ' + intersectingOffer.endDateStr + ' - ' + intersectingOffer.creatorUserName}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ModalBody>
                    )}
                    <ModalFooter>
                        <Button
                            className="btn btn-light mr-auto"
                            onClick={toggleModal}
                            icon={<i className="fas fa-times mr-2"></i>}
                            text={t('Close')}
                            disabled={isLoading}
                        />

                        <Button
                            className="btn btn-success float-right"
                            onClick={save}
                            icon={<i className={'far fa-save mr-2'}></i>}
                            text={t('Save')}
                            showConfirm={false}
                            disabled={isLoading}
                        />
                    </ModalFooter>
                </Modal>
            </>
        )

    })
)

export default DecideIntersectingOffersPopup
