import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { confirmable, createConfirmation } from 'react-confirm'

interface IDialogProps {
  title?: string
  content?: string
  icon?: string
  backdrop?: boolean
  className?: string
  onClose: any
  show: boolean
  showOKButton: boolean
  proceed: any
  confirmation: string
  options: any
}

const Dialog = (props: IDialogProps) => {
  const { title, content, icon, backdrop, className, show, proceed, showOKButton = false } = props
  const { t } = useTranslation()
  const [modal, setModal] = useState(show)

  const toggle = () => {
    if (modal) proceed(false)
    setModal(!modal)
  }

  const confirm = () => {
    proceed(true)
    setModal(!modal)
  }

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop={backdrop} className={className} size="sm">
      <ModalBody>
        {title && <h3>{title}</h3>}
        {content && <p>{content}</p>}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {t('Close')}
        </Button>
        {showOKButton && (
          <Button color="success" onClick={confirm}>
            {t('Confirm')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default createConfirmation(confirmable(Dialog))
