import React, { useState, useEffect, FC, ChangeEvent, useMemo, useRef, MouseEventHandler } from 'react'
import { inject, observer } from 'mobx-react'

import Store from './store'
import View from './view'
import { Button, ConfirmModal, Table } from '@/components'
import { UserDto } from './types'
import { toast } from 'react-toastify'
import moment from 'moment'

interface IPage {
  UserStore?: Store
  t: any
}

const Page: FC<IPage> = inject('UserStore')(
  observer((props: IPage) => {
    const { UserStore: store, t } = props
    let { records } = store!

    const [selectedRow, setSelectedRow] = useState<UserDto>({} as UserDto)

    const [edit, setEdit] = useState(false)
    const [showSendLoginInfoModal, setShowSendLoginInfoModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showUserModal, setShowUserModal] = useState(false)

    useEffect(() => {
      store!.getRecords()
    }, [])

    const detailDeletionMessage = (selectedRow: any) => {
      return (
        <>
          <p>
            {t('Name')}: {selectedRow.nameAlias}
          </p>
          <p>
            {t('Username')}: {selectedRow.username}
          </p>
          <p>
            {t('Email')}: {selectedRow.email}
          </p>
        </>
      )
    }

    const columns = useMemo(
      () => [
        {
          Header: <i style={{ color: 'blue', margin: 'auto' }} className="fas fa-cog fa-2x"></i>,
          id: 'actions',
          Cell: ({ row }: any) => {
            row = Object.assign({}, row.original)
            return (
              <>
                {
                  <>
                    <Button
                      icon={<i className="fas fa-paper-plane"></i>}
                      tooltipText={t('Send Login Information')}
                      onClick={async () => {
                        setSelectedRow(row)
                        setShowSendLoginInfoModal(true)
                      }}
                      className="btn btn-link text-default"
                    />
                    <Button
                      icon={<i className="fas fa-pen"></i>}
                      tooltipText={t('Edit')}
                      onClick={async () => {
                        setSelectedRow(row)
                        setEdit(true)
                        setShowUserModal(true)
                      }}
                      className="btn btn-link text-warning"
                    />
                    <Button
                      icon={<i className="fas fa-trash"></i>}
                      tooltipText={t('Delete')}
                      onClick={async () => {
                        setSelectedRow(row)
                        setShowDeleteModal(true)
                      }}
                      className="btn btn-link text-danger"
                    />
                  </>
                }
              </>
            )
          },
          className: 'd-flex justify-content-center text-center border-bottom-0',
        },
        {
          Header: t('Name'),
          id: 'nameAlias',
          canFilterable: true,
          accessor: 'nameAlias',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            return <span dangerouslySetInnerHTML={{ __html: row.values.nameAlias }} />
          },
        },
        {
          Header: t('Username'),
          id: 'username',
          canFilterable: true,
          accessor: 'username',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            return <span dangerouslySetInnerHTML={{ __html: row.values.username }} />
          },
        },
        {
          Header: t('Email'),
          id: 'email',
          canFilterable: true,
          accessor: 'email',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            return <span dangerouslySetInnerHTML={{ __html: row.values.email }} />
          },
        },
        {
          Header: t('Login Information Sent Time'),
          id: 'loginInformationSentTime',
          canFilterable: true,
          accessor: 'loginInformationSentTime',
          className: 'd-none d-md-table-cell',
          Cell: ({ row }: any) => {
            const { loginInformationSentTime } = row.values
            return (
              <span
                dangerouslySetInnerHTML={{ __html: loginInformationSentTime ? moment(loginInformationSentTime).format('DD.MM.YYYY - HH:mm') : '' }}
              />
            )
          },
        },
      ],
      [t]
    )

    return (
      <>
        {' '}
        <div className="p-3">
          <div className="card-body">
            <div className="card-header">
              <div className="row">
                {t('User Management')}{' '}
                <div className="btn-actions-pane-right actions-icon-btn">
                  <button
                    className="btn btn-primary"
                    style={{ marginLeft: '10px', marginTop: '-5px' }}
                    onClick={async () => {
                      setEdit(false)
                      setShowUserModal(true)
                    }}
                  >
                    <i className="fas fa-plus mr-2"></i>
                    {t('Add')}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <Table
              smallSize={true}
              columns={columns}
              totalCount={records.totalCount}
              data={records.items}
              fetchData={store!.getRecords}
              isLoading={!records.isLoaded}
              filterable={true}
            />
          </div>
        </div>
        {showUserModal && <View data={selectedRow!} isEdit={edit} toggleModal={() => setShowUserModal(!showUserModal)} />}
        {showSendLoginInfoModal && (
          <ConfirmModal
            message={t('User information will be sent to the user via email, do you confirm?')}
            title={t('Warning') + '!'}
            isShow={showSendLoginInfoModal}
            toggleModal={() => setShowSendLoginInfoModal(!showSendLoginInfoModal)}
            onConfirm={async () => {
              if (selectedRow.id && selectedRow.id > 0) {
                await store?.sendLoginInfo(selectedRow.id)
                setShowSendLoginInfoModal(false)
                toast.success(t('Email sent'))
              }
            }}
          />
        )}
        {showDeleteModal && (
          <ConfirmModal
            message={t('The record will be deleted, do you confirm?')}
            detailMessage={detailDeletionMessage(selectedRow)}
            title={t('Warning') + '!'}
            isShow={showDeleteModal}
            toggleModal={() => setShowDeleteModal(!showDeleteModal)}
            onConfirm={async () => {
              if (selectedRow.id && selectedRow.id > 0) {
                await store?.deleteUser(selectedRow.id)
                setShowDeleteModal(false)
              }
            }}
          />
        )}
      </>
    )
  })
)

export default Page
