import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'
import qs from 'qs'
import http from '@/services/http'

import IPagedResult from '@/models/dto/fetch/IPagedResult'
import IPagedRequest from '@/models/dto/fetch/IPagedRequest'

import env from '@/config/env'

import { ConventionalOfferPaymentInLieuOfNoticeDuration } from '../types'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'ConventionalOfferPaymentInLieuOfNoticeDurationStore'
  records!: IPagedResult<ConventionalOfferPaymentInLieuOfNoticeDuration>
  filter!: number

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.records = {
      totalCount: 0,
      items: [],
      isLoaded: false,
    }

    this.getRecords = this.getRecords.bind(this)
  }

  @action.bound async getRecords(params: IPagedRequest, versionId: number) {
    this.records.isLoaded = false
    const q = qs.stringify(params, { encodeValuesOnly: true, addQueryPrefix: true })

    const versionIdParam = (q == null || q == '' ? '?' : '&') + `versionId=${versionId}`

    const result = await (await this.api.get(`/api/app/payment-in-lieu-of-notice-duration/all${q}${versionIdParam}`)).data

    this.records = { ...result, isLoaded: true }
  }

  @action.bound async save(dto: ConventionalOfferPaymentInLieuOfNoticeDuration, versionId: number) {
    const result = await this.api.post(`/api/app/payment-in-lieu-of-notice-duration`, dto)

    if (result.status == 200) {
      this.getRecords(
        {
          skipCount: 0,
          maxResultCount: 5,
        },
        versionId
      )
    }
  }

  @action.bound async delete(id: number, versionId: number) {
    const result = await this.api.delete(`/api/app/payment-in-lieu-of-notice-duration/${id}`)

    if (result.status == 200) {
      this.getRecords(
        {
          skipCount: 0,
          maxResultCount: 5,
        },
        versionId
      )
    }
  }
}

export default Store
