import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import PerfectScrollbar from 'react-perfect-scrollbar'
import MetisMenu from 'react-metismenu'
import cx from 'classnames'
import * as _ from 'lodash'

import { getPermissions } from '../../../../utils/permission'
import { result } from 'lodash'

interface ISidebarProps {
  UIStore?: any
  routes?: any
}

const CustomLink = (props: any) => {
  const onClick = (e: any) => {
    if (props.hasSubMenu) {
      props.toggleSubMenu(e)
    } else {
      props.activateMe({
        newLocation: props.to,
        selectedMenuLabel: props.label,
      })
    }
  }

  return (
    <Link
      to={props.to}
      className={cx(props.className, {
        active: props.active,
      })}
      onClick={onClick}
    >
      {props.children}
    </Link>
  )
}

const Sidebar: FC<ISidebarProps> = inject('UIStore')(
  observer((props: ISidebarProps) => {
    const { UIStore, routes } = props
    const { backgroundColor, enableSidebarShadow } = UIStore
    const { t } = useTranslation()

    let permissions = getPermissions()

    const mapRoutes = (routes: any) =>
      _.map(_.orderBy(routes, 'index'), (route: any) => {
        let item: any = {
          icon: route.icon ? route.icon : 'far fa-square',
          content: [],
        }

        if (route.routes && route.routes.length && route.title) {
          _.map(_.orderBy(route.routes, 'index'), (route: any) => {
            if (!route.showInMenu || (route.permission && _.indexOf(permissions, route.permission) === -1)) return

            let subitem: any = {
              label: t(route.title),
            }

            if (route.children) {
              const subitems = mapRoutes(route.children)
              if (subitems.length > 0) subitem.content = subitems
            } else subitem.to = route.path

            if ((subitem.content && subitem.content.length) || subitem.to) item.content.push(subitem)
          })

          if (!item.content.length) return

          item.label = t(route.title)
          if (route.icon) item.icon = route.icon
        }
        //  2'nd degree collapse menu items
        else if (route.children) {
          let subitem: any = {
            label: t(route.title),
          }
          const subitems = mapRoutes(route.children)
          if (subitems.length > 0) subitem.content = subitems
          if ((subitem.content && subitem.content.length) || subitem.to) item.content.push(subitem)
          return subitem
        } else {
          route = route.routes ? route.routes[0] : route
          if (!route?.showInMenu || _.indexOf(permissions, route.permission) === -1) return

          item = {
            label: t(route.title),
            to: route.path,
          }

          if (route.single) item.icon = route.icon
          else if (route.routes) item.icon = route.icon ? route.icon : 'far fa-square'
        }

        if (item.content && item.content.length === 0) return

        return item
      }).filter((item) => item !== undefined)

    const filterSidebarMenu = (value: string) => {
      value = _.trim(value)

      if (!value.length) {
        const _li = document.querySelectorAll('div.metismenu.vertical-nav-menu > ul.metismenu-container > li.metismenu-item')

        _.map(_li, (li) => {
          const chevron = li.querySelector('i.metismenu-state-icon')
          chevron?.classList.remove('rotate-minus-90')

          const subul = li.querySelector('ul.metismenu-container')
          subul?.classList.remove('visible')

          const _subli = subul?.querySelectorAll(':scope > li.metismenu-item') as NodeListOf<HTMLElement>

          _.map(_subli, (li) => {
            li.style.display = ''
          })
        })
      }

      const menus = document.querySelectorAll('div.metismenu.vertical-nav-menu')

      const filter = (ul: Element) => {
        let _result: boolean = false
        const list = ul.querySelectorAll(':scope > li.metismenu-item') as NodeListOf<HTMLElement>
        _.map(list, (li: HTMLElement) => {
          const textContent = li.textContent
          const childList = li.querySelectorAll('a.metismenu-link') as NodeListOf<HTMLElement>
          _.map(childList, (li: HTMLElement) => {
            if (value === '') {
              li!.style.display = ''
              return result
            }
            var childTextContent = li.innerText.toLowerCase() + textContent?.toLocaleLowerCase()
            const className = _.toLower(childTextContent!).indexOf(_.toLower(value)) > -1 ? '' : 'none'
            _result = false
            if (className !== 'none' && !_result) _result = true
            if (_result === true) {
              li!.style.display = 'inline'
              return result
            }
            li!.style.display = className
          })
        })
        return result
      }
      _.map(menus, (menu) => {
        const ul = menu.querySelector(':scope > ul.metismenu-container')
        if (!ul) return

        const subul = ul.querySelector(':scope > li.metismenu-item > ul.metismenu-container')

        if (filter(ul)) {
          const list = subul?.querySelectorAll(':scope > li.metismenu-item') as NodeListOf<HTMLElement>

          _.map(list, (li: HTMLElement) => {
            li.style.display = ''
          })
        } else {
          if (!subul) return

          if (filter(subul)) {
            subul.classList.add('visible')

            const parentli = subul.closest('li.metismenu-item') as HTMLElement

            const chevron = parentli.querySelector('i.metismenu-state-icon')
            chevron?.classList.add('rotate-minus-90')

            parentli!.style.display = ''
          }
        }
      })
    }

    const hideSidebar = () => {
      const container = document.querySelector('.app-container')
      if (container?.classList.contains('sidebar-mobile-open')) container?.classList.remove('sidebar-mobile-open')

      const burger = document.querySelector('.BurgerSlider')
      if (burger?.classList.contains('BurgerActive')) burger?.classList.remove('BurgerActive')
    }

    let navs: any[] = []
    navs[0] = mapRoutes(routes)

    return (
      <>
        <div className="sidebar-mobile-overlay" onClick={hideSidebar} />
        <TransitionGroup
          component="div"
          className={cx('app-sidebar', backgroundColor, {
            'sidebar-shadow': enableSidebarShadow,
          })}
        >
          <CSSTransition timeout={1500} onEntered={() => {}} onExited={() => {}}>
            <PerfectScrollbar>
              <div className="search-wrapper active w-100 p-3">
                <div className="input-holder w-100">
                  <input
                    type="text"
                    className="search-input"
                    placeholder={t('Type to search')}
                    onChange={({ target: { value } }) => filterSidebarMenu(value)}
                  />
                </div>
              </div>
              <div className="app-sidebar__inner" style={{ paddingTop: 10 }}>
                {_.map(navs, (nav: any, i: number) => (
                  <MetisMenu
                    key={i}
                    content={nav}
                    activeLinkFromLocation
                    className="vertical-nav-menu"
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    LinkComponent={CustomLink}
                  />
                ))}
              </div>
            </PerfectScrollbar>
          </CSSTransition>
        </TransitionGroup>
      </>
    )
  })
)

export default Sidebar
