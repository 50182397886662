import { lazy } from 'react'

import IRoute from '../../models/dto/route/IRoute'

const index = 1
const title = 'Digital Offer'
const icon = 'pe-7s-network'
export { title, icon, index }

const routes: IRoute[] = [
  {
    path: '/digital-offer/conventional-offer-admin',
    title: 'Manned Services Offer Admin',
    icon: 'fas fa-dollar-sign',
    index: 1,
    showInMenu: true,
    permission: 'DigitalOffer',
    single: false,
    exact: false,
    component: lazy(() => import('./pages/conventional-offer-admin')),
  },
  {
    path: '/digital-offer/conventional-offer-document',
    title: 'Offer Documents',
    icon: 'fas fa-file',
    index: 2,
    showInMenu: true,
    permission: 'DigitalOffer',
    single: false,
    component: lazy(() => import('./pages/conventional-offer-document')),
  },
  {
    path: '/digital-offer/conventional-offer-admin/parameters-of-version/:versionId',
    title: 'Manned Services Offer Admin',
    icon: 'fas fa-clipboard-list',
    showInMenu: false,
    single: false,
    permission: 'DigitalOffer',
    component: lazy(() => import('./pages/conventional-offer-admin/parameters-of-version')),
  },

  {
    path: '/digital-offer/customer-offer',
    title: 'Customer Offer',
    icon: 'fas fa-dollar-sign',
    showInMenu: false,
    single: false,
    permission: 'DigitalOffer',
    component: lazy(() => import('./pages/customer-offer')),
  },
  {
    path: '/digital-offer/customer-offer/:offerId',
    title: 'Customer Offer',
    icon: 'fas fa-dollar-sign',
    showInMenu: false,
    single: false,
    permission: 'DigitalOffer',
    component: lazy(() => import('./pages/customer-offer')),
  },
  {
    path: '/digital-offer/conventional-offer',
    title: 'Manned Services Offer',
    icon: 'fas fa-dollar-sign',
    index: 3,
    showInMenu: true,
    single: false,
    permission: 'DigitalOffer',
    component: lazy(() => import('./pages/conventional-offer')),
  },
  {
    path: '/digital-offer/conventional-offer/:offerId',
    title: 'Manned Services Offer',
    icon: 'fas fa-dollar-sign',
    index: 4,
    showInMenu: false,
    single: false,
    permission: 'DigitalOffer',
    component: lazy(() => import('./pages/conventional-offer')),
  },
  {
    path: '/digital-offer/customer-offer-grid',
    title: 'Customer Offer',
    icon: 'fas fa-dollar-sign',
    index: 5,
    showInMenu: true,
    single: false,
    permission: 'DigitalOffer',
    component: lazy(() => import('./pages/customer-offer-grid')),
  },
]

export default routes
