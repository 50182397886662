export enum MessageResultState {
  ERROR = 0,
  SUCCESS = 1,
  WARNING = 2,
}
export interface ServiceResult<T = any> {
  result: T
  isSuccess: boolean
  message: string
  state: MessageResultState
}

export type ComboBoxData = {
  id: number
  name: string
  parentId?: string | number | undefined
}
export type ComboBoxDataStr = {
  id: string
  name: string
  parentId?: string | number | undefined
}
export interface TokenResultDto {
  id: number
  username: string
  name: string
  email: string
  avatar: string
  job_name: string
  job_started_at: string | null
  roles: number[]
  personnelId: string
  isAdminBranch: boolean
}
export interface PageState {
  skip: number
  take: number
}
