import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

interface IDefaultInputProps {
    message: string
    messageClassName?: string
    title?: string
    titleClassName?: string
    onConfirm?: () => void
    className?: any
    isShow: boolean
    toggleModal: any
    detailMessage?: any
}

const Input: FC<IDefaultInputProps> = (props: IDefaultInputProps) => {
    const { message, messageClassName = "", title = null, titleClassName = "", className = "modal-md", isShow, toggleModal, onConfirm, detailMessage = null } = props
    const { t } = useTranslation()
    return (
        <Modal isOpen={isShow} toggle={toggleModal} className={className}>
            {title && <ModalHeader toggle={toggleModal} className={titleClassName}>{title}</ModalHeader>}
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <div className={messageClassName}>
                            <p>{message}</p>
                        </div>

                        {detailMessage && <div className="shadow-sm p-3 mb-5 bg-body rounded">{detailMessage}</div>}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-light mr-right" onClick={toggleModal}>
                    <i className="fas fa-times mr-2"></i>
                    {t('No')}
                </button>
                <button className="btn btn-success mr-right" onClick={onConfirm}>
                    <i className="fas fa-check mr-2"></i>
                    {t('Yes')}
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default Input