import { action, makeAutoObservable } from 'mobx'
import * as _ from 'lodash'

import env from '../../../../config/env'
import http from '../../../../services/http'
import { IConventionalOfferDocument } from './types'
import { IFileCreateDto } from '@/models/dto/file'

const { API_BASE_URL } = env

class Store {
  static readonly id: string = 'ConventionalOfferDocumentStore'

  get api() {
    http.defaults.baseURL = API_BASE_URL
    return http
  }

  constructor() {
    makeAutoObservable(this)

    this.getDocuments = this.getDocuments.bind(this)

    this.getDocument = this.getDocument.bind(this)
  }

  @action.bound async getDocuments() {
    const data = await (await this.api.get(`/api/app/conventional-offer-document/get-documents`)).data
    return data as IConventionalOfferDocument[]
  }

  @action.bound async getDocument(id: number, documentType: string) {
    const data = await (await this.api.get(`/api/app/conventional-offer-document/get-document?id=${id}&documentType=${documentType}`)).data
    return data as IConventionalOfferDocument
  }

  @action.bound async uploadFile(input: IFileCreateDto) {
    const formData = new FormData()

    Array.from(input.files).forEach(async (file: any) => {
      formData.append('files', file, file.name)
    })

    formData.append('moduleName', input.moduleName)

    const result = (
      await this.api.post(`/api/app/conventional-offer-document/file-upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    ).data

    return result
  }

  @action.bound async updateFileName(id?: string, fileDisplayName?: string) {
    const result = await this.api.post(`/api/app/conventional-offer-document/update-file-name?id=${id}&fileDisplayName=${fileDisplayName}`)

    return result
  }

  @action.bound async save(dto: IConventionalOfferDocument) {
    const result = await this.api.post(`/api/app/conventional-offer-document/save`, dto)

    return result
  }

  @action.bound async update(id: string, isChecked: boolean) {
    const result = await this.api.post(`/api/app/conventional-offer-document/update?id=${id}&isChecked=${isChecked}`)

    return result
  }

  @action.bound async delete(id: string) {
    await this.api.delete(`/api/app/conventional-offer-document/${id}`)
  }
}

export default Store
