import React, { FC, useState, useEffect, useCallback, Fragment, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import { Col, Row, Form, FormGroup, Input } from 'reactstrap'
import Store from './store'
import { useTranslation } from 'react-i18next'
import { purgeToken, getToken, setToken } from '../../../../utils/token'
import { MessageResultState } from '../../../../types/general-types'
import { Button } from '../../../../components'
import { purgePermissions, setPermissions } from '../../../../utils/permission'
import { getUser, purgeUser, setUser } from '../../../../utils/user'
import { CheckLoginDto, AuthDto } from './types'
import { loadRoutes } from '@/utils/routes'
import _ from 'lodash'
import bgImage from '../../../../assets/utils/images/background/bg1.jpg'
import secImage from '../../../../assets/utils/images/sec_logo_yazili.png'

import { CountdownCircleTimer } from 'react-countdown-circle-timer'

interface IPage {
  LoginStore?: Store
}
const Page: FC<IPage> = inject('LoginStore')(
  observer((props: IPage) => {
    const { LoginStore: store } = props
    const [inputs, setInputs] = useState<CheckLoginDto>({ password: '', username: '' })
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [animationShow, setAnimationShow] = useState<boolean>(false)
    const [muted, setMuted] = useState<boolean>(false)
    const [response, setResponse] = useState(null)
    const [showAuth, setShowauth] = useState<boolean>(false)
    const [code, setCode] = useState('')
    const { t } = useTranslation()

    const minuteSeconds = 300
    const getTimeSeconds = (time: any) => (minuteSeconds - time) | 0

    useEffect(() => {
      purgeUser()
      purgeToken()
      purgePermissions()
    }, [])

    const redirectUrl = window.location.href

    if (window.location.pathname.length <= 1) {
      window.location.href = redirectUrl + 'login'
    }

    const sortFromIndex: any = useCallback((arr: any) => {
      arr = arr.sort((a: any, b: any) => {
        if (a.routes) {
          a.routes = sortFromIndex(a.routes)
        }
        if (a.children) {
          a.children = sortFromIndex(a.children)
        }
        return b.index - a.index
      })

      return arr
    }, [])

    const mapRoutes: any = useCallback(
      (moduleRoutes: any[], routes: any[], permissions: string[]) =>
        _.map(moduleRoutes, (route: any) => {
          if (route.children) return mapRoutes(route.children, routes, permissions)

          const isGranted = route.permission && _.indexOf(permissions, route.permission) >= 0
          if (isGranted && route.path.indexOf(':') >= 0) debugger //bu debugger'i kaldirma, loginde redirecti bozuyor
          if (isGranted && route.path.indexOf(':') < 0) {
            window.location.href = window.location.origin + route.path
            return ''
          }
        }),
      []
    )

    const redirect = (response: any) => {
      const redirectUrl = window.location.href

      if (redirectUrl && !redirectUrl.includes('login')) {
        window.location.href = redirectUrl
      } else {
        let routes = loadRoutes()
        routes = sortFromIndex(routes)

        _.map(routes, (module: any) => {
          mapRoutes(module.routes, routes, response.permissions)
        })
      }
    }

    const onAuth = async (authCode = code) => {
      if (authCode) {
        const myCode = {
          code: authCode,
          token: getToken(),
        } as AuthDto
        await store
          ?.authentication(myCode)
          .then(() => {
            redirect(response)
          })
          .catch((err) => {
            setErrorMessage(err.response?.data)
          })
      } else {
        setErrorMessage('Please enter the verification code completely!')
        setAnimationShow(false)
      }
    }

    const onBack = () => {
      setShowauth(false)
      setCode('')
      setErrorMessage('')
      setInputs({ password: '', username: '' })
    }

    const onLogin = useCallback(
      async (username = inputs.username, password = inputs.password) => {
        setErrorMessage('')
        if (password && username) {
          await store
            ?.checkLogin({ username, password })
            .then((response) => {
              setAnimationShow(true)

              setUser(response.user)

              setToken(response.token)
              setPermissions(response.permissions)
              setResponse(response)
              setShowauth(true)
            })
            .catch((err) => {
              setErrorMessage(err.response?.data)
            })
        } else {
          setErrorMessage('Please enter the username and password fields completely!')
          setAnimationShow(false)
        }
      },
      [inputs, mapRoutes, sortFromIndex, store]
    )

    const renderTime = (time: any) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <div style={{ fontSize: '24px' }}>{time}</div>
        </div>
      )
    }

    useEffect(() => {
      const handleEnterPress = (event: any) => {
        if (event.key === 'Enter') {
          if (showAuth) {
            const inputCode = document.getElementById('code') as HTMLInputElement
            const code = inputCode?.value?.trim()
            onAuth(code)
          } else {
            const inputUsername = document.getElementById('username') as HTMLInputElement
            const username = inputUsername?.value?.trim()
            const inputPassword = document.getElementById('password') as HTMLInputElement
            const password = inputPassword?.value?.trim()
            onLogin(username, password)
          }
        }
      }

      document.addEventListener('keydown', handleEnterPress)

      return () => {
        document.removeEventListener('keydown', handleEnterPress)
      }
    }, [showAuth])

    const animation = useMemo(() => {
      const user = getUser()

      const maskedUserEmail =
        (user?.email || '') == '' ? '' : user.email.substring(0, 2) + '*'.repeat(user.email.length - 4) + user.email.substring(user.email.length - 2)

      return (
        <Fragment>
          <div
            className="h-100 bg-plum-plate bg-animation"
            style={{
              //backgroundImage: `url(${bgImage})`,
              backgroundColor: '#031F30',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="d-flex h-100 justify-content-center align-items-center">
              <Col md="8" className="mx-auto app-login-box">
                <div className=" mx-auto mb-3" />
                <div className="modal-dialog w-100 mx-auto">
                  <div className="modal-content">
                    <div className="modal-body">
                      <br />
                      <div className="h5 modal-title text-center">
                        <img src={secImage} style={{ width: '200px' }} alt="imgSrc" />
                      </div>
                      <br />
                      <br />
                      <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                          {showAuth ? (
                            <span>{t('Please enter the verification code send to "{0}"').replace('{0}', maskedUserEmail)}</span>
                          ) : (
                            <span>{t('Please sign in to your account below')}</span>
                          )}
                        </h4>
                      </div>
                      <Form>
                        {showAuth ? (
                          <Row form>
                            <Col md={12} className="d-flex align-items-center justify-content-center">
                              <FormGroup>
                                <CountdownCircleTimer
                                  isPlaying
                                  duration={minuteSeconds}
                                  colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                  colorsTime={[300, 200, 100, 0]}
                                  onComplete={onBack}
                                  size={130}
                                >
                                  {({ elapsedTime, color }) => <span style={{ color }}>{renderTime(getTimeSeconds(elapsedTime))}</span>}
                                </CountdownCircleTimer>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Input
                                  onChange={(event) => {
                                    const { value } = event.target
                                    setCode(value?.trim())
                                  }}
                                  value={code}
                                  name="code"
                                  id="code"
                                  placeholder={t('Authentication Code')}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault()
                                    }
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : (
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Input
                                  onChange={(event) => {
                                    const { value } = event.target

                                    setInputs({ ...inputs, username: value?.trim() })
                                  }}
                                  autoFocus
                                  value={inputs?.username}
                                  name="username"
                                  id="username"
                                  placeholder={t('Username')}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Input
                                  onChange={(event) => {
                                    const { value } = event.target
                                    setInputs({ ...inputs, password: value?.trim() })
                                  }}
                                  value={inputs?.password}
                                  type="password"
                                  name="password"
                                  id="password"
                                  placeholder={t('Password')}
                                  onKeyUp={(event: any) => {
                                    if (event.keyCode === 13) {
                                    }
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </Form>
                      {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                    </div>
                    <div className="modal-footer clearfix" style={{ display: 'flex' }}>
                      {showAuth ? (
                        <>
                          <Button
                            text={t('Back')}
                            className="btn btn-danger"
                            icon={<i className="fas fa-arrow-left mr-2"></i>}
                            onClick={onBack}
                            type="submit"
                          ></Button>

                          <div style={{ flexGrow: 1 }}></div>

                          <Button
                            text={t('Login')}
                            className="btn btn-primary"
                            icon={<i className="fas fa-sign-in-alt px-2"></i>}
                            onClick={async () => await onAuth()}
                            type="submit"
                          ></Button>
                        </>
                      ) : (
                        <>
                          <div style={{ flexGrow: 1 }}></div>

                          <Button
                            text={t('Login')}
                            className="btn btn-primary"
                            icon={<i className="fas fa-sign-in-alt px-2"></i>}
                            onClick={async () => await onLogin()}
                            type="submit"
                          ></Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center text-white opacity-8 mt-3">Copyright &copy; Securitas</div>
              </Col>
            </div>
          </div>
        </Fragment>
      )
    }, [animationShow, setInputs, onLogin, onAuth, errorMessage, muted])

    return <div>{animation}</div>
  })
)

export default Page
