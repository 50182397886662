declare global {
  interface String {
    turkishToLower(): string
    turkishToUpper(): string
  }
}

String.prototype.turkishToLower = function () {
  let string = this
  const letters = {
    İ: 'i',
    I: 'ı',
    ı: 'ı',
    Ş: 'ş',
    ş: 'ş',
    Ğ: 'ğ',
    ğ: 'ğ',
    Ü: 'ü',
    ü: 'ü',
    Ö: 'ö',
    ö: 'ö',
    Ç: 'ç',
    ç: 'ç',
  } as any
  string = string.replace(/(([İIıŞşĞğÜüÇçÖö]))/g, function (letter) {
    return letters[letter]
  })
  return string.toLowerCase()
}

String.prototype.turkishToUpper = function () {
  let string = this
  const letters = { i: 'İ', ş: 'Ş', ğ: 'G', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I' } as any
  string = string.replace(/(([iışğüçö]))/g, function (letter) {
    return letters[letter]
  })
  return string.toUpperCase()
}

export default String
